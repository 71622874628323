import React from 'react'
import { Chip } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import AceHighwayManifestApiUrls from "../../apiUrls/AceHighwayManifestApiUrls"
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

function TemplateSelectDialogWithOption(props) {
  const {
    manifestTemplateDialog,
    setManifestTemplateDialog,
    setConfirmDialog,
    showNotification,
    translatedTextsObject,
    setLicensePlateCount,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()
  
  function getCurrentDateTime() {
    let date = new Date();
    return date.toISOString().split('.')[0].replace(/[^\d]/gi, '').slice(5);
  }
  function handleApplyManifestTemplate(template) {
    if (template) {
      const {
        carrierScac,
        tripNo,
        arrivalDate,
        transitIndicator,
        firstExpectedPort,
        messageFunction,
        aceId,
        aceFlag,
        conveyanceId,
        conveyanceNo,
        conveyanceType,
        email,
        vin,
        dotNo,
        transponderId,
        licensePlateNo1,
        licensePlateCountry1,
        licensePlateState1,
        licensePlateNo2,
        licensePlateCountry2,
        licensePlateState2,
        licensePlateNo3,
        licensePlateCountry3,
        licensePlateState3,
        licensePlateNo4,
        licensePlateCountry4,
        licensePlateState4,
        iitCode1,
        iitCode2,
        iitCode3,
        iitCode4,
        sealNo1,
        sealNo2,
        sealNo3,
        sealNo4,
        sealNo5,
        sealNo6,
        sealNo7,
        sealNo8,
        sealNo9,
        equipmentInfo,
        insuranceInfo,
        partyInfo,
        shipmentInfo,
        paxLSTInfo,
        statusNotificationInfo
      } = template

      // var arrivalTimeSeparate = moment(new Date()).tz("Canada/Eastern").format('HH:mm');
      var arrivalDateSeparate = moment(new Date()).tz("Canada/Eastern").format('YYYY-MM-DD');

      equipmentInfo.forEach((datum, i) => {
        datum.id = ""
        datum.headerId = ""
      })

      insuranceInfo.forEach((datum, i) => {
        datum.id = ""
        datum.headerId = ""
      })

      partyInfo.forEach((datum, i) => {
        datum.id = ""
        datum.manifestHeaderId = ""
      })

      paxLSTInfo.forEach((datum, i) => {
        datum.id = ""
        datum.headerId = ""
      })

      shipmentInfo.forEach((datum, i) => {
        datum.id = ""
        datum.headerId = ""
        datum.scn = carrierScac + getCurrentDateTime() + i
        datum.partyInfo.forEach((party, i) => {
          party.id = ""
          party.shipmentHeaderId = ""
        })
        datum.goodsDetails.forEach((goods, i) => {
          goods.id = ""
          goods.headerId = ""
          goods.dangerousGoods.forEach((dang, i) => {
            dang.id = ""
            dang.headerId = ""
          })
        })
      })

      if(isTable){
        var templateJson = {
          "baseFilterDTO": null,
          "errorMessages": null,
          "customData": null,
          "id": null,
          "version": 0,
          "createdBy": null,
          "createdDate": null,
          "updatedBy": null,
          "updatedDate": null,
          "partyId": null,
          "carrierScac":carrierScac,
          "tripNo": "",
          "arrivalDate":arrivalDateSeparate,
          "arrivalTime": null,
          "transitIndicator":transitIndicator,
          "firstExpectedPort":firstExpectedPort,
          "messageFunction":messageFunction,
          "amendmentCode":"",
          "status":"",
          "templateFlag":false,
          "templateName":"",
          "aceId":aceId,
          "aceFlag":aceFlag,
          "conveyanceId":conveyanceId,
          "conveyanceNo":conveyanceNo,
          "conveyanceType":conveyanceType,
          "email":email,
          "vin":vin,
          "dotNo":dotNo,
          "transponderId":transponderId,
          "licensePlateNo1":licensePlateNo1,
          "licensePlateCountry1":licensePlateCountry1,
          "licensePlateState1":licensePlateState1,
          "licensePlateNo2":licensePlateNo2,
          "licensePlateCountry2":licensePlateCountry2,
          "licensePlateState2":licensePlateState2,
          "licensePlateNo3":licensePlateNo3,
          "licensePlateCountry3":licensePlateCountry3,
          "licensePlateState3":licensePlateState3,
          "licensePlateNo4":licensePlateNo4,
          "licensePlateCountry4":licensePlateCountry4,
          "licensePlateState4":licensePlateState4,
          "iitCode1":iitCode1,
          "iitCode2":iitCode2,
          "iitCode3":iitCode3,
          "iitCode4":iitCode4,
          "sealNo1":sealNo1,
          "sealNo2":sealNo2,
          "sealNo3":sealNo3,
          "sealNo4":sealNo4,
          "sealNo5":sealNo5,
          "sealNo6":sealNo6,
          "sealNo7":sealNo7,
          "sealNo8":sealNo8,
          "sealNo9":sealNo9,
          "integration":"NANG",
          "messageIndicator":"",
          "equipmentInfo":equipmentInfo,
          "insuranceInfo":insuranceInfo,
          "partyInfo":partyInfo,
          "shipmentInfo":shipmentInfo,
          "paxLSTInfo": paxLSTInfo,
          "statusNotificationInfo": statusNotificationInfo,
          "fromCloneOrTemplate": true
        }

        history.push(`${location.pathname}/add`, templateJson)

      }else{

        if(licensePlateNo1 != undefined && licensePlateNo1 != ''){
          setLicensePlateCount(1)
        }
  
        if(licensePlateNo2 != undefined && licensePlateNo2 != ''){
          setLicensePlateCount(2)
        }
  
        if(licensePlateNo3 != undefined && licensePlateNo3 != ''){
          setLicensePlateCount(3)
        }
  
        if(licensePlateNo4 != undefined && licensePlateNo4 != ''){
          setLicensePlateCount(4)
        }

        setValue('carrierScac', carrierScac || '', { shouldDirty: true })
        setValue('tripNo', '', { shouldDirty: true })
        setValue('arrivalDate', arrivalDateSeparate || '', { shouldDirty: true })
        setValue('arrivalTime', null || '', { shouldDirty: true })
        setValue('transitIndicator', transitIndicator || '', { shouldDirty: true })
        setValue('firstExpectedPort', firstExpectedPort || '', { shouldDirty: true })
        setValue('messageFunction', messageFunction || '', { shouldDirty: true })
        setValue('aceFlag', aceFlag || '', { shouldDirty: true }) //cant enable manually
        setValue('aceId', aceId || '', { shouldDirty: true })
        setValue('conveyanceId', conveyanceId || '', { shouldDirty: true })
        setValue('conveyanceNo', conveyanceNo || '', { shouldDirty: true })
        setValue('conveyanceType', conveyanceType || '', { shouldDirty: true })
        setValue('email', email || '', { shouldDirty: true })
        setValue('vin', vin || '', { shouldDirty: true })
        setValue('dotNo', dotNo || '', { shouldDirty: true })
        setValue('transponderId', transponderId || '', { shouldDirty: true })
        setValue('licensePlateNo1', licensePlateNo1 || '', { shouldDirty: true })
        setValue('licensePlateCountry1', licensePlateCountry1 || '', { shouldDirty: true })
        setValue('licensePlateState1', licensePlateState1 || '', { shouldDirty: true })
        setValue('licensePlateNo2', licensePlateNo2 || '', { shouldDirty: true })
        setValue('licensePlateCountry2', licensePlateCountry2 || '', { shouldDirty: true })
        setValue('licensePlateState2', licensePlateState2 || '', { shouldDirty: true })
        setValue('licensePlateNo3', licensePlateNo3 || '', { shouldDirty: true })
        setValue('licensePlateCountry3', licensePlateCountry3 || '', { shouldDirty: true })
        setValue('licensePlateState3', licensePlateState3 || '', { shouldDirty: true })
        setValue('licensePlateNo4', licensePlateNo4 || '', { shouldDirty: true })
        setValue('licensePlateCountry4', licensePlateCountry4 || '', { shouldDirty: true })
        setValue('licensePlateState4', licensePlateState4 || '', { shouldDirty: true })
        setValue('iitCode1', iitCode1?true:false || false, { shouldDirty: true })
        setValue('iitCode2', iitCode2?true:false || false, { shouldDirty: true })
        setValue('iitCode3', iitCode3?true:false || false, { shouldDirty: true })
        setValue('iitCode4', iitCode4?true:false || false, { shouldDirty: true })
        setValue('sealNo1', sealNo1 || '', { shouldDirty: true })
        setValue('sealNo2', sealNo2 || '', { shouldDirty: true })
        setValue('sealNo3', sealNo3 || '', { shouldDirty: true })
        setValue('sealNo4', sealNo4 || '', { shouldDirty: true })
        setValue('sealNo5', sealNo5 || '', { shouldDirty: true })
        setValue('sealNo6', sealNo6 || '', { shouldDirty: true })
        setValue('sealNo7', sealNo7 || '', { shouldDirty: true })
        setValue('sealNo8', sealNo8 || '', { shouldDirty: true })
        setValue('sealNo9', sealNo9 || '', { shouldDirty: true })
        setValue('equipmentInfo', equipmentInfo || [], { shouldDirty: true })
        setValue('insuranceInfo', insuranceInfo || [], { shouldDirty: true })
        setValue('partyInfo', partyInfo || [], { shouldDirty: true })
        setValue('shipmentInfo', shipmentInfo || [], { shouldDirty: true })
        setValue('fromCloneOrTemplate', true || [], { shouldDirty: true })

        showNotification('success', 'Template applied successfully.')
        trigger()
      }
      // iitCodes = watch(['iitCode1', 'iitCode2', 'iitCode3', 'iitCode4']) //change from const to vat and set again here, but the collapsible section cannot refresh

    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'carrierScac',
      title: translatedTextsObject.carrierScac
    },
    {
      field: 'firstExpectedPort',
      title: translatedTextsObject.firstExpectedPort
    },
    {
      field: 'transitIndicator',
      title: translatedTextsObject.transitIndicator
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      sortKey: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.templateName,
      value: 'templateName'
    },
    {
      label: translatedTextsObject.status,
      value: 'status'
    },
    {
      label: translatedTextsObject.createdDate,
      value: 'createdDate'
    },
    {
      label: translatedTextsObject.updatedDate,
      value: 'updatedDate'
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'ASC' }]}
      fetch={{ url: AceHighwayManifestApiUrls.GET }}
      fetchFilters={
        [
          { field: 'templateFlag', operator: 'equal', value: true },
          { field: 'status', operator: 'equal', value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyManifestTemplate}
      onClose={() => setManifestTemplateDialog(false)}
      open={manifestTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title='Manifest template'
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOption