import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import PropTypes from 'prop-types'
import naCodeMaintenanceAutocompleteField from './NaWareHouseCodeDescAutoCompleteField'

function NaSublocationAutocompleteField(props) {
  return naCodeMaintenanceAutocompleteField(
    props,
    CodeMaintenanceType.SUBLOCATION
  )
}

export default NaSublocationAutocompleteField