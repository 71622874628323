const AceHwyInbondArrivalKeys = {
  TITLE: "title",
  TRIP_NO: "tripNo",
  ETA_FIRST_PORT_US: "etaFirstPortUS",
  FIRST_PORT: "firstPort",
  INBOND7512_NO: "inbond7512No",
  INBOND_DATE: "inbondDate",
  INBOND_DESTINATION: "inbondDestination",
  FIRMS_CODE: "firmsCode",
  MSG_FUNCTION: "msgFunction",
  ACTION_CODE: "actionCode",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  SUBMIT_BUTTON: "submitButton",
  STATUS: "status",
  MESSAGE_CODE: "messageCode",
  ID: "id",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  CLONE_MULTIPLE_RECORDS:"cloneMultiple",
  RESPONSE_TITLE:"responseTitle",
  ACTIVITYLOG_TITLE: "activityLogs",
  LOGIN_USER_ID:"loginUserID",
  FUNCTION_CODE:"FunctionCode",
  AUDIT_DESC:"AuditDesc",
  RESPONSE_TITLE:"inbondResponse",
  BATCH_ID:"batchID",
  CBSA_PROCESSING_DATE:"cbsaProcDate",
  CBP_REF_NO:"cbpRefNo",
  TRIP_NO:"tripNo",
  RESPONSE_CODE:"responseCode",
  RESPONSE_TEXT:"responseText",
  RESPONSE_DATE:"responseDate",
  SUBMISSION_TYPE: "submissionType",
  ARRIVAL_STATUS: "arrivalStatus",
  EXPORT_STATUS: "exportStatus"
}

export default AceHwyInbondArrivalKeys
