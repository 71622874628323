const SbciManifestKeys = {
  TITLE: "title",
  ID: "id",
  MANIFEST_ID: "manifestId",
  RECEIVE_DATETIME: "receiveDatetime",
  ENTRY_NO: "entryNo",
  CLIENT_CODE: "clientCode",
  SUBMISSION_FILE_PATH: "submissionFilePath",
  RESPONSE_FILE_PATH: "responseFilePath",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version"
}

export default SbciManifestKeys
