const HomePageKeys = {
    FILE_FOR_USER: "fileForUser",
    TOGGLE_SIDEBAR: "toggleSidebar",
    ABOUT_US: "aboutUs",
    CONTACT_US: "contactUs",
    PRODUCT_INFO: "productInfo",
    TRADE_RESOURCES: "tradeResources",
    FAQ: "faq",
    PRIVACY_POLICY: "privacyPolicy",
    TERMS_OF_USE: "termsOfUse",
    TERMS_OF_SERVICES: "termsOfServices",
    COPYRIGHT: "copyright"
}
  
export default HomePageKeys