const SbciFileUploadHistoryKeys = {
  TITLE: "title",
  ID: "id",
  FILE_TYPE: "fileType",
  PROCESS_STATUS: "processStatus",
  RECEIVED_DATE: "receivedDate",
  RECEIVED_FILE_NAME: "receivedFileName",
  ACKNOWLEDGED_FILE_NAME: "acknowledgedFileName",
  ERROR_MESSAGE: "errorMessage",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version",
  PARTY_ID: "partyId"
}

export default SbciFileUploadHistoryKeys
