const EquipmentMasterKeys = {
  TITLE: "Equipment Master",
  ACCT_ID: "acctId",
  EQUIPMENT_ID: "equipmentId",
  CARRIER_CODE: "carrierCode",
  EQUIPMENT_NO: "equipmentNo",
  EQUIPMENT_TYPE: "equipmentType",
  ACE_ID: "aceId",
  STATUS: "status",
  SUBMISSION_DATE: "submissionDate",
  SUBMITTED_BY: "submittedBy",
  RESPONSE_DATE: "responseDate",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SUBMIT_SENT_RECORDS: "submitSentRecords",
  SUBMIT_DIALOG_TITLE: "submitDialogTitle",
  SUBMIT_DIALOG_MESSAGE: "submitDialogMessage",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_ERROR_MESSAGE: "submitErrorMessage",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORDS: "editSentRecords",
  DELETE_BUTTON: "deleteButton",
  DELETE_SENT_RECORDS: "deleteSentRecords",
  MSG_FUNC_TYPE: "msgFuncType",
  MSG_FUNC_ADD: "msgFuncAdd",
  MSG_FUNC_DEACTIVATE: "msgFuncDeactivate",
  MSG_FUNC_MODIFY: "msgFuncModify",
  VIEW_BUTTON: "viewButton",
  RESPONSE_TITLE: "responseTitle",
  LicensePlates: {
    TITLE: "licensePlates.title",
    LICENSE_PLATE_NO: "licensePlates.licensePlateNo",
    COUNTRYOF_REGISTRATION: "licensePlates.countryofRegistration",
    STATE: "licensePlates.state"
  },
  Validations:{
    MIN_LENGTH: "validations.minLength",
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumeric"
  }
}

export default EquipmentMasterKeys
