import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngButton },
  CngDialog
} = components

function AlertDialog(props) {
  const {
    cancelLabel,
    children,
    confirmLabel,
    loading,
    onCancel,
    onClose,
    onConfirm,
    open,
    title
  } = props

  return (
    <CngDialog
      dialogContent={children}
      dialogTitle={title}
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      open={open}
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onCancel}>
            {cancelLabel}
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={onConfirm}
            shouldShowProgress={loading}
          >
            {confirmLabel}
          </CngButton>
        </>
      }
      shouldShowCloseButton
    />
  )
}

export default AlertDialog

AlertDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  loading: false,
  open: false,
  title: 'Alert'
}
