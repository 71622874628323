import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naAceStateCodeAutoCompleteMasterField from './NaAceStateCodeAutoCompleteMasterField'

function NaAceStateCodeAutoCompleteField(props) {
  return naAceStateCodeAutoCompleteMasterField(
    props,
    CodeMaintenanceType.STATE_CODE,
  )
}

export default NaAceStateCodeAutoCompleteField