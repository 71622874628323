import  PartyMasterApiUrls  from '../../../../apiUrls/PartyMasterApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'


const {
  form: { field: { CngServerSideAutocompleteField } },
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaPartyMasterAutoCompleteMasterField(props, codeMaintenanceType) {

  const { lookupProps = {}, ...lookupAutocompleteProps } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={false}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['partyidn']}
      lookupPageableProps={{
        customData: { "filters": filters },
        url: PartyMasterApiUrls.GET,
        label: 'partyidn',
        value: 'partyidn',
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: true
          },
          ...filters
        ],
        pageSize: 100,
        ...otherLookupProps
      }}
    />
  )
}

NaPartyMasterAutoCompleteMasterField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaPartyMasterAutoCompleteMasterField
