const ProductMasterKeys = {
  TITLE: "title",
  PRODUCTIDN: "productidn",
  ACCTID: "acctId",
  PRODUCT_DESC1: "productDesc1",
  PRODUCT_DESC2: "productDesc2",
  PRODUCT_DESC3: "productDesc3",
  PRODUCT_DESC4: "productDesc4",
  PRODUCT_DESC5: "productDesc5",
  PRODUCT_DESC6: "productDesc6",
  PRODUCT_DESC7: "productDesc7",
  PRODUCT_DESC8: "productDesc8",
  PRODUCT_DESC9: "productDesc9",
  HS_CODE: "hsCode",
  HS_CODE_DESC: "hsCodeDesc",
  COUNTRY_OF_ORIGIN: "countryOfOrigin",
  ACTIVE: "active",
  MARKS_AND_NUMBERS: "marksAndNumbers",
  TITLE_IN_PRODUCT_MASTER: "titleInProductMaster",
  UNDG_ID: "undgId",
  CODE: "code",
  DESCRIPTION: "description",
  INTLCODE: "intlcode",
  LANGUAGE: "language",
  INDICATOR: "indicator",
  UNDG_DROP_DOWN: "undgDropDown"
}

export default ProductMasterKeys
