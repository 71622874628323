function getUIPreference(module, key) {
    if (sessionStorage.getItem('uiPreference')) {
        const uiPreference = JSON.parse(sessionStorage.getItem('uiPreference'));

        for (let uiPref of uiPreference) {
            if (module === uiPref.module) {
                const preference = uiPref.preference ? uiPref.preference : []

                for (let pref of preference) {
                    if (key === pref.key && pref.value) {
                        return pref.value.split(',')
                    }
                }
            }
        }
    }
    return []
}

function getUIPreferenceToServerObject(module, key, value) {
    return ({
        uiPreference: [{
            module: module,
            preference: [{ key: key, value: value.join() }]
        }],
        saveUiPreference: true
    })
}

export { getUIPreference, getUIPreferenceToServerObject }