const BillingConfigKeys = {
    TITLE: 'Billing Configuration',
    CHARGE_CODE: 'Charge Code',
    MSG_FUNCTION: 'Message Function',
    MODULE_NAME: 'Module Name',
    SUBMISSION_TYPE: 'Submission Type',
    DOC_TYPE: 'Document Type',
    STATUS: 'Status',
    PARTY_ID_LIST: 'Party IDs'
}

export default BillingConfigKeys