const InvoiceKeys = {
  TITLE: "title",
  ID: "id",
  CORPID: "corpid",
  INVOICE_NUMBER: "invoiceNumber",
  INVOICE_DATE: "invoiceDate",
  CUST_REF_NUM: "custRefNum",
  NO_OF_PKGS: "noOfPkgs",
  CARRIER_CODE: "carrierCode",
  NO_OF_CONTAINERS: "noOfContainers",
  CUBE: "cube",
  ETA: "eta",
  PORT_OF_LOADING: "portOfLoading",
  BILL_OF_LADING: "billOfLading",
  STATUS: "status",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  RESPONSE_DATETIME: "responseDatetime",
  RCVD_PO: "rcvdPo",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  SUBMITTED_DATE: "submittedDate",
  ACTIVITY_LOGS: "activity_logs",
  VIEW_INVOICE: "view_invoice",
  EDIT_INVOICE: "edit_invoice",
  DATE_TIME: "date_time",
  LOGIN_USER_ID: "login_user_id",
  ACTION_USER_ID: "action_user_id",
  FUNCTION_CODE: "function_code",
  AUDIT_DESC: "audit_desc",
  MODE: "mode",
  FILE_NAME: "file_name",
  SUBMIT_SENT_RECORD: "submit_sent_record",
  EDIT_SENT_RECORD: "edit_sent_record",
  SUBMIT_BUTTON:"submit_button",
  EDIT_BUTTON:"edit_button",
  EDIT_MULTIPLE_RECORD: "edit_multiple",
  SUBMIT_UPS_CONFIRMATION_MSG: "submitUPSConfirmationMsg",
  SUBMIT_SUCCESS_MSG: "submitRecordMsg",
  VALIDATION_ERROR_MSG: "validationErrorMsg",
  InvoiceContainer: {
    TITLE: "invoiceContainer.title",
    INV_ID: "invoiceContainer.invId",
    CORPID: "invoiceContainer.corpid",
    PO_NUMBER: "invoiceContainer.poNumber",
    WALL_MART_ITEM: "invoiceContainer.wallMartItem",
    CONTAINER_NO: "invoiceContainer.containerNo",
    SIZE_AND_TYPE: "invoiceContainer.sizeAndType",
    QUANTITY: "invoiceContainer.quantity",
    UOM: "invoiceContainer.uom",
    VOLUME: "invoiceContainer.volume",
    UNIT_PRICE: "invoiceContainer.unitPrice",
    ENT_VALUE: "invoiceContainer.entValue"
  },
  Transaction: {
    TITLE: "transaction.title",
    USERID: "transaction.userid",
    INVOICE_NUMBER: "transaction.invoiceNumber",
    INV_ID: "transaction.invId",
    PO_NUMBER: "transaction.poNumber",
    TRANS_TIME: "transaction.transTime",
    TRANS_TYPE: "transaction.transType",
    ADDITIONALINFO: "transaction.additionalinfo",
    ADDITIONALINFO1: "transaction.additionalinfo1",
    SUB_EDI_PATH: "transaction.subEdiPath"
  }
}

export default InvoiceKeys
