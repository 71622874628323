const FlightMatrixKeys = {
  TITLE: "title",
  FLIGHT_NO: "flightNo",
  ORIGIN: "origin",
  DEST: "dest",
  PORT_OF_REPORT: "portOfReport",
  WARE_HOUSE_NAME: "wareHouseName",
  PORT_OF_LOADING: "portOfLoading",
  DELIVERY_CITY: "deliveryCity",
  DELIVERY_TERM: "deliveryTerm",
  CUSTOMS_OFFICE: "customsOffice",
  CUSTOMS_OFFICE_TERM: "customsOfficeTerm",
  undefined: "undefined"
}

export default FlightMatrixKeys
