import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import PropTypes from 'prop-types'
import naCodeMaintenanceAutocompleteField from './NaCodeDescCodeMaintainceAutoCompleteField'

function NaAircraftTypeAutocompleteField(props) {
  return naCodeMaintenanceAutocompleteField(
    props,
    CodeMaintenanceType.AIRCRAFT_TYPE
  )
}

export default NaAircraftTypeAutocompleteField