const StatusMessageKeys = {
  TITLE: "title",
  ID: "id",
  CORPID: "corpid",
  SHIP_STATUS_CODE: "shipStatusCode",
  SHIP_DEP_DATE: "shipDepDate",
  SHIP_ETA_DATE: "shipEtaDate",
  EQUIP_PREFIX: "equipPrefix",
  EQUIP_NUMBER: "equipNumber",
  EQUIP_STATUS: "equipStatus",
  CARRIER_CODE: "carrierCode",
  LOCATION_QUALI: "locationQuali",
  LOCATION_NUMBER: "locationNumber",
  WALMART_ITEM_NUM: "walmartItemNum",
  BOL: "bol",
  PO_NUMBER: "poNumber",
  DEPT_NUMBER: "deptNumber",
  MERCHANDISE_TYPE: "merchandiseType",
  DISTRIBUTION_CENTER: "distributionCenter",
  MESSAGE_STATUS: "messageStatus",
  VOYAGE_ID: "voyageId",
  VESSEL_CODE: "vesselCode",
  VESSEL_CODE_QUALI: "vesselCodeQuali",
  VESSEL_NAME: "vesselName",
  DEPARTURE_DATE: "departureDate",
  STATUS_DATE: "statusDate",
  QUANTITY: "quantity",
  WEIGHT: "weight",
  WEIGHT_QUALI: "weightQuali",
  WEIGHT_UOM: "weightUom",
  VOLUME: "volume",
  VOLUME_QUALI: "volumeQuali",
  VOLUME_UOM: "volumeUom",
  PORT_OF_DISCHARGE: "portOfDischarge",
  POD_COUNTRY: "podCountry",
  PORT_OF_LOADING: "portOfLoading",
  POL_COUNTRY: "polCountry",
  STATUS: "status",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  RESPONSE_DATETIME: "responseDatetime",
  PORT_N: "portN",
  PN_COUNTRY: "pnCountry",
  SUB_FILE_NAME: "subFileName",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  TITLE_ROUTING_INFO:"titleRoutingInfo",
  TITLE_REF_ID:"titleRefId"
}

export default StatusMessageKeys
