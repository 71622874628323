import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naPortAutocompleteField from './NaPortAutocompleteField'

function NaHwyAcePOEPortAutocompleteField(props) {
  return naPortAutocompleteField(
    props,
    CodeMaintenanceType.DOC_PORT,
    "ACEHWY_POE"
  )
}

export default NaHwyAcePOEPortAutocompleteField