const AceHighwayAbiInbond7512Keys = {
  TITLE: "title",
  RESPONSE_TITLE: "responseTitle",
  ACTIVITYLOG_TITLE: "activityLogTitle",
  ID: "id",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  STATUS: "status",
  RESPONSE_DATE: "responseDate",

  INBOND_HEADER: "inbondHeader",
  FILER_CODE: "filerCode",
  PORT_CODE: "portCode",
  ACTION_CODE: "actionCode",
  BOND_NUMBER: "bondNumber",
  ENRTY_TYPE: "entryType",
  INBOND_CARRIER_CODE: "inbondCarrierCode",
  DESTINATION_PORT: "destinationPort",
  FOREIGN_PORT: "foreignPort",
  INBOND_VALUE: "inbondValue",
  INBOND_CARRIER_ID_TYPE: "inbondCarrierIdType",
  INBOND_CARRIER_ID: "inbondCarrierId",
  INBOND_CARRIER_ID_NOTE: "inbondCarrierIdNote",
  INBOND_CARRIER_ID_IRS: "inbondCarrierIdIRS",
  INBOND_CARRIER_ID_SSN: "inbondCarrierIdSSN",
  INBOND_CARRIER_ID_CBP: "inbondCarrierIdCBP",
  FTZ_INDICATOR: "ftzIndicator",
  BTA_FDA_INDICATOR: "btaFdaIndicator",

  CONVEYANCE_INFORMATION: "conveyanceInformation",
  CONVEYANCE_CARRIER_CODE: "conveyanceCarrierCode",
  MOT: "mot",
  CARRIER_COUNTRY: "carrierCountry",
  CONVEYANCE_NAME: "conveyanceName",
  TRIP_NUMBER: "tripNumber",
  ARRIVAL_PORT: "arrivalPort",
  ETA: "eta",
  FTZ_FIRMS_CODE: "ftzFirmsCode",
  
  ADDITIONAL_PARTY_DETAILS: "additionalPartyDetails",
  ENTERED_OR_IMPORTED_BY: "enteredOrImportedBy",
  ENTERED_OR_WITHDRAWN_BY: "enteredOrWithdrawnBy",
  COMPANY_NAME: "companyName",
  COMPANY_ADDRESS: "companyAddress",

  BOL_SUMMARY: "bolSummary",
  Bol: {
    TITLE: "bol.title",
    MASTER_BOL_ISSUER_CODE: "bol.masterBolIssuerCode",
    MASTER_BILL_NUMBER: "bol.masterBillNumber",
    HOUSE_BILL_NUMBER: "bol.houseBillNumber",
    PREVIOUS_ENTRY_TYPE: "bol.previousEntryType",
    PREVIOUS_INBOND_NUMBER: "bol.previousInbondNumber",
    INBOND_QUANTITY: "bol.inbondQuantity",
    SECONDARY_NOTIFY_PARTY_CODE_1: "bol.secondaryNotifyPartyCode1",
    SECONDARY_NOTIFY_PARTY_CODE_2: "bol.secondaryNotifyPartyCode2",
    SECONDARY_NOTIFY_PARTY_CODE_3: "bol.secondaryNotifyPartyCode3",
    SECONDARY_NOTIFY_PARTY_CODE_4: "bol.secondaryNotifyPartyCode4",
    REFERENCE_INFORMATION: "bol.referenceInformation",
    QUALIFIER: "bol.qualifier",
    REFERENCE_IDENTIFIER: "bol.referenceIdentifier",
    AdditionalBolDetails: {
      TITLE: "bol.additionalBolDetails.title",
      FOREIGN_PORT_OF_LADING: "bol.additionalBolDetails.foreignPortOfLading",
      MANIFEST_QUANTITY:"bol.additionalBolDetails.manifestQuantity",
      MANIFEST_UNIT: "bol.additionalBolDetails.manifestUnit",
      WEIGHT: "bol.additionalBolDetails.weight",
      WEIGHT_UNIT: "bol.additionalBolDetails.weightUnit",
      VOLUME: "bol.additionalBolDetails.volume",
      VOLUME_UNIT: "bol.additionalBolDetails.volumeUnit",
      PLACE_OF_PRERECEIPT: "bol.additionalBolDetails.placeOfPrereceipt"
    },
    Parties: {
      TITLE: "bol.parties.title",
      PARTY_ID: "bol.parties.partyId",
      PARTY_TYPE: "bol.parties.partyType",
      NAME: "bol.parties.name",
      ADDRESS1: "bol.parties.address1",
      ADDRESS2: "bol.parties.address2",
      ADDRESS3: "bol.parties.address3",
      TELNO: "bol.parties.telNo",
      FAXNO: "bol.parties.faxNo",
      SAVE_FLAG: "bol.parties.saveFlag",
      PARTY_DROP_DOWN: "bol.parties.dropDown"
    },
    ADDITIONAL_BOL_INDICATOR: "bol.additionalBOLIndicator",
    CONTAINERIZED_INDICATOR: "bol.containerizedIndicator",
    BolContainer: {
      TITLE: "bol.bolContainer.title",
      CONTAINER_NUMBER: "bol.bolContainer.containerNumber",
      SEAL_NUMBER1: "bol.bolContainer.sealNumber1",
      SEAL_NUMBER2: "bol.bolContainer.sealNumber2",
      CONTAINER_DESCRIPTION_CODE: "bol.bolContainer.containerDescriptionCode",
      BillCargoDesc: {
        TITLE: "bol.bolContainer.billCargoDesc.title",
        PIECE_COUNT: "bol.bolContainer.billCargoDesc.pieceCount",
        DESCRIPTION: "bol.bolContainer.billCargoDesc.description",
        MANIFEST_UNIT_CODE: "bol.bolContainer.billCargoDesc.manifestUnitCode"
      },
      HtsInformation: {
        TITLE: "bol.bolContainer.htsInformation.title",
        HARMONIZED_NUMBER: "bol.bolContainer.htsInformation.harmonizedNumber",
        VALUE: "bol.bolContainer.htsInformation.value",
        WEIGHT: "bol.bolContainer.htsInformation.weight",
        WEIGHT_UNIT: "bol.bolContainer.htsInformation.weightUnit"
      },
      MarksAndNumbers: {
        TITLE: "bol.bolContainer.marksAndNumbers.title",
        MARKS_AND_NUMBERS: "bol.bolContainer.marksAndNumbers.marksAndNumbers"
      },
      HazmatInformation: {
        TITLE: "bol.bolContainer.hazmatInformation.title",
        HAZARDOURS_MATERIAL_CODE: "bol.bolContainer.hazmatInformation.hazardousMaterialCode",
        HAZARDOURS_MATERIAL_CLASS: "bol.bolContainer.hazmatInformation.hazardousMaterialClass",
        HAZARDOURS_MATERIAL_CODE_QUAL: "bol.bolContainer.hazmatInformation.hazardousMaterialCodeQual",
        HAZARDOURS_MATERIAL_DESCRIPTION: "bol.bolContainer.hazmatInformation.hazardousMaterialDescription",
        HAZARDOURS_MATERIAL_CONTACT: "bol.bolContainer.hazmatInformation.hazardousMaterialContact",
        FLASHPOINT_TEMPERATURE: "bol.bolContainer.hazmatInformation.flashpointTemperature",
        UOM_CODE: "bol.bolContainer.hazmatInformation.uomCode",
        NEGATIVE_INDICATOR: "bol.bolContainer.hazmatInformation.negativeIndicator",
        HAZARDOURS_MATERIAL_DESCRIPTION1: "bol.bolContainer.hazmatInformation.hazardousMaterialDescription1",
        HAZARDOURS_MATERIAL_CLASSIFICATION1: "bol.bolContainer.hazmatInformation.hazardousMaterialClassification1",
        HAZARDOURS_MATERIAL_DESCRIPTION2: "bol.bolContainer.hazmatInformation.hazardousMaterialDescription2",
        HAZARDOURS_MATERIAL_CLASSIFICATION2: "bol.bolContainer.hazmatInformation.hazardousMaterialClassification2"
      }
    }
  },
  
  SAVE_BUTTON: "saveButton",
  UPDATE_BUTTON: "updateButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_SENT_RECORDS: "editSentRecords",
  EDIT_BUTTON: "editButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_DRAFT_RECORDS: "deleteDraftRecords",
  CANCEL_BUTTON: "cancelButton",
  CANCEL_MULTIPLE_RECORDS:"cancelMultipleRecords",
  CLONE_BUTTON: "cloneButton",
  PRINT_ABI_INBOND: "printAbiInbond",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ONEREC_ALERT: "submitOneRecordAlert",
  LOGIN_USER_ID: "loginUserId",
  FUNCTION_CODE: "functionCode",
  AUDIT_DESC: "auditDescription",
  PROCESSING_DATE: "processingDate",
  RESPONSE_CODE:"responseCode",
  RESPONSE_TEXT:"responseText",
  REMARKS:"remarks",
  Validations:{
    MIN_LENGTH: "validations.minLength",
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumericRegx",
    ALPHANUMERIC_SPACE: "validations.alphaNumericSpaceRegx",
    ALPHANUMERIC_SPECIAL_CHAR: "validations.alphaNumericSpecialChar",
    NUMERIC: "validations.numericErrorMessage",
    INVALID_ARRIVAL_DATE_TIME_FORMAT:"validations.arrivalDateTimeFormat"
  }
}

export default AceHighwayAbiInbond7512Keys
