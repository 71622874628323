import  NaCodeMaintenanceApiURLs  from '../../../../apiUrls/NaCodeMaintenanceApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'
const {
  card: {
  },
  form: {
    field: {
      CngLookupAutocompleteField
    },
  },
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaStateCodeAutoCompleteMasterField(props, codeMaintenanceType) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngLookupAutocompleteField
      {...lookupAutocompleteProps}
      lookupProps={{
        url: NaCodeMaintenanceApiURLs.GET,
        customData: { codeMType: codeMaintenanceType },
        label: (record)=> {
          return `${record.intlcode};${record.descriptionEn.trim()}`
        },
        value: 'intlcode',
        shouldEnableSessionCache: true,
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: true
          }
          ,{
            field: 'code',
            operator: 'equal',
            value: props.countryCode
          },
          ,{
            field: 'indicator',
            operator: 'equal',
            value: 'ACI'
          },
          ...filters
        ],
        ...otherLookupProps
      }}
    />
  )
}

NaStateCodeAutoCompleteMasterField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaStateCodeAutoCompleteMasterField
