import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import {
  FormControl,
  FormGroup,
  FormHelperText,
  makeStyles
} from '@material-ui/core'

const {
  form: {
    field: { CngCheckboxField }
  }
} = components

const useStyles = makeStyles((theme) => ({
  formGroup: {
    '& > .MuiFormHelperText-root.ng-form-control-helper-label': {
      display: 'none'
    }
  },
  root: {
    '& .MuiFormControlLabel-root': {
      borderColor: (props) =>
        props.error ? theme.palette.error.main : theme.palette.grey[200],
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      margin: 0,
      minHeight: 50,
      paddingLeft: 4,
      paddingRight: 4
    },
    '& .MuiFormControlLabel-label': {
      width: '100%'
    }
  }
}))

function Switch(props) {
  const { error, fullWidth = false, required, ...otherProps } = props

  const ref = useRef(null)
  const classes = useStyles({ error })

  return (
    <FormControl
      className={classes.root}
      fullWidth={fullWidth}
      required={required}
    >
      <FormGroup className={classes.formGroup}>
        <CngCheckboxField inputRef={ref} {...otherProps} />
      </FormGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Switch
