import  NaCodeMaintenanceApiURLs  from '../../../../apiUrls/NaCodeMaintenanceApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'

const {
  card: {
  },
  form: {
    field: {
      CngServerSideAutocompleteField
    },
  },
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaHSCodeCodeMaintenanceAutoCompleteField(props, codeMaintenanceType) {


  const {
    lookupPageableProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupPageableProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps} 
      minFilterChars={1}
      searchableFields={['descriptionEn','code']}
      lookupPageableProps={{
        url: NaCodeMaintenanceApiURLs.GET,
        customData: { codeMType: codeMaintenanceType },
        label: (record)=> {
          return `${record.code};${record.descriptionEn.trim()}`
        },
        value: 'code',
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: true
          },
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

NaHSCodeCodeMaintenanceAutoCompleteField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaHSCodeCodeMaintenanceAutoCompleteField


