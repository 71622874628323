const EhblResponseKeys = {
  TITLE: "title",
  HBL_ID: "hblId",
  MANIFEST_ID: "manifestId",
  CLOSEMSG_ID: "closemsgId",
  RECEIVE_DATETIME: "receiveDatetime",
  TRANS_SET_IDENTIFIER_CODE: "transSetIdentifierCode",
  TRANS_SET_CONTROL_NO: "transSetControlNo",
  ORIGINAL_TRANS_SET_STATUS: "originalTransSetStatus",
  ORIGINAL_TRANS_SET_CONTROL_NO: "originalTransSetControlNo",
  ORIGINAL_TRANS_SET_DATETIME: "originalTransSetDatetime",
  REPORT_TYPE: "reportType",
  REPORT_STATUS: "reportStatus",
  PRIMARY_CCN: "primaryCcn",
  HBL_CCN: "hblCcn",
  CBSA_MSG_TYPE: "cbsaMsgType",
  CBSA_REF_NO: "cbsaRefNo",
  CBSA_MSG_FUNC_CODE: "cbsaMsgFuncCode",
  DATE_TIME: "dateTime",
  SECONDARY_BUSINESS_ID: "secondaryBusinessId",
  TYPE_OF_RESP: "typeOfResp",
  ERP_MSG_SECTION_CODE: "erpMsgSectionCode",
  ERP_UNIQUE_REF_NO: "erpUniqueRefNo",
  ERP_SUBITEM_ID: "erpSubitemId",
  D4_RESPONSE_ID: "d4ResponseId",
  USER_ID_INFO: "userIdInfo",
  REMARKS:"remarks",
  STATUS:"status",
  RESPONSE_TEXT:"responseText",
  PREV_CCN:"prevCcn",
  ResponseError: {
    TITLE: "responseError.title",
    RESPONSE_ID: "responseError.responseId",
    ERROR_CODE: "responseError.errorCode",
    ERROR_DESC: "responseError.errorDesc",
    ERROR_SEGMENT: "responseError.errorSegment"
  }
}

export default EhblResponseKeys
