const DocGenPurchaseOrderKeys = {
  ID: "id",
  TITLE: "title",
  TITLE_DATE_TIME_REF: "titleDateTimeRef",
  TITLE_REF_ID:"titleRefId",
  CORPID: "corpid",
  PO_NUMBER: "poNumber",
  PO_PURPOSE: "poPurpose",
  PO_TYPE: "poType",
  PO_DATE: "poDate",
  DEPT_NUMBER: "deptNumber",
  ORDER_TYPE: "orderType",
  EVENT_CODE: "eventCode",
  ZB_NUMBER: "zbNumber",
  YD_NUMBER: "ydNumber",
  ACD_NUMBER: "acdNumber",
  FOB_NUMBER: "fobNumber",
  BUYER_NAME: "buyerName",
  REQUESTED_SHIP_DATE: "requestedShipDate",
  CANCEL_AFTER_DATE: "cancelAfterDate",
  SHIP_NOT_BEFORE_DATE: "shipNotBeforeDate",
  SHIP_NO_LATER_DATE: "shipNoLaterDate",
  DELIVERY_REQUESTED_DATE: "deliveryRequestedDate",
  ZZZ_DATE: "zzzDate",
  TOT_WEIGHT: "totWeight",
  TOT_WEIGHT_UOM: "totWeightUom",
  TOT_VOLUME: "totVolume",
  TOT_VOLUME_UOM: "totVolumeUom",
  MATCH_IND: "matchInd",
  INV_IND: "invInd",
  STATUS: "status",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  RESPONSE_DATETIME: "responseDatetime",
  SUB_FILE_NAME: "subFileName",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  CREATE_BUTTON: "createButton",
  CREATE_MULTIPLE_INVOICES: "createMultipleInvoices",
  RoutingInfo: {
    TITLE: "routingInfo.title",
    PO_ID: "routingInfo.poId",
    CORPID: "routingInfo.corpid",
    TRANSPORT_METHOD: "routingInfo.transportMethod",
    LOC_QUALIFIER: "routingInfo.locQualifier",
    LOC_IDENTIFIER: "routingInfo.locIdentifier",
    SERVICE_LEVEL: "routingInfo.serviceLevel",
    COUNTRY_CODE: "routingInfo.countryCode"
  },
  TradeParty: {
    TITLE: "tradeParty.title",
    PO_ID: "tradeParty.poId",
    CORPID: "tradeParty.corpid",
    PARTY_NAME: "tradeParty.partyName",
    PARTY_TYPE: "tradeParty.partyType",
    IDENTIFICATION_CODE: "tradeParty.identificationCode",
    IDENTIFIER: "tradeParty.identifier",
    ADDRESS: "tradeParty.address",
    ADDRESS1: "tradeParty.address1",
    ADDRESS2: "tradeParty.address2",
    ADDRESS3: "tradeParty.address3",
    CITY: "tradeParty.city",
    STATE: "tradeParty.state",
    COUNTRY: "tradeParty.country",
    POSTAL_CODE: "tradeParty.postalCode"
  },
  LineItem: {
    TITLE: "lineItem.title",
    PO_ID: "lineItem.poId",
    CORPID: "lineItem.corpid",
    PO_LINE_NUMBER: "lineItem.poLineNumber",
    QUOTE_SHEET_NUMBER: "lineItem.quoteSheetNumber",
    BUYER_ITEM_NUMBER: "lineItem.buyerItemNumber",
    UPC_CONSUMER_PACKAGE_CODE: "lineItem.upcConsumerPackageCode",
    EUROPEAN_ARTICLE_NUMBER: "lineItem.europeanArticleNumber",
    VENDOR_ITEM_NUMBER: "lineItem.vendorItemNumber",
    PRE_CLASSED: "lineItem.preClassed",
    CASE_ID: "lineItem.caseId",
    WALMART_INTERNAL_SHIP_CODE: "lineItem.walmartInternalShipCode",
    QUANTITY: "lineItem.quantity",
    UOM: "lineItem.uom",
    UNIT_PRICE: "lineItem.unitPrice",
    UNIT_PRICE1: "lineItem.unitPrice1",
    ITEM_DESCRIPTION: "lineItem.itemDescription",
    PACK_COUNT: "lineItem.packCount",
    GROSS_WEIGHT: "lineItem.grossWeight",
    GROSS_WEIGHT_UOM: "lineItem.grossWeightUom",
    VOLUME: "lineItem.volume",
    VOLUME_UOM: "lineItem.volumeUom",
    INNER_PACK_COUNT: "lineItem.innerPackCount"
  },
  Subline: {
    TITLE: "subline.title",
    LINE_ITEM: "subline._lineItemId",
    ITEM_ID: "subline.itemId",
    ITEM_CORPID: "subline.itemCorpid",
    TARIFF_NUMBER: "subline.tariffNumber",
    COUNTRY_ORIGIN: "subline.countryOrigin",
    COMMODITY_GROUPING_CODE: "subline.commodityGroupingCode",
    US_CUSTOMS_BINDING_RULE: "subline.usCustomsBindingRule",
    QUOTA_CATEGORY: "subline.quotaCategory",
    QUOTA_CATEGORY1: "subline.quotaCategory1",
    QUOTA_CATEGORY2: "subline.quotaCategory2",
    QUOTA_CATEGORY3: "subline.quotaCategory3",
    QUOTA_CATEGORY4: "subline.quotaCategory4",
    QUOTA_CATEGORY5: "subline.quotaCategory5",
    MESSAGE: "subline.message"
  },
  SublineAllowance: {
    TITLE: "sublineAllowance.title",
    SUBLINE: "sublineAllowance._sublineId",
    SUBLINE_ITEM_ID: "sublineAllowance.sublineItemId",
    SUBLINE_CORPID: "sublineAllowance.sublineCorpid",
    CHARGE_CODE: "sublineAllowance.chargeCode",
    CHARGE_PERCENT: "sublineAllowance.chargePercent",
    CHARGE_COST: "sublineAllowance.chargeCost",
    CHARGE_UOM: "sublineAllowance.chargeUom",
    CHARGE_QUANTITY: "sublineAllowance.chargeQuantity",
    CHARGE_METHOD_DESC: "sublineAllowance.chargeMethodDesc"
  },
  Schedule: {
    TITLE: "schedule.title",
    LINE_ITEM: "schedule._lineItemId",
    SCHEDULE_ITEM_ID: "schedule.scheduleItemId",
    SCHEDULE_CORPID: "schedule.scheduleCorpid",
    SCHEDULE_QUANTITY: "schedule.scheduleQuantity",
    SCHEDULE_QUANTITY_UOM: "schedule.scheduleQuantityUom",
    DISTRIBUTION_PO_NUMBER: "schedule.distributionPoNumber",
    DISTRIBUTION_CENTER: "schedule.distributionCenter",
    SCHEDULE_DATE: "schedule.scheduleDate"
  }
}

export default DocGenPurchaseOrderKeys