import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naUsbStateCodeAutoCompleteMasterField from './NaUsbStateCodeAutoCompleteMasterField'

function NaUsbStateCodeAutoCompleteField(props) {
  return naUsbStateCodeAutoCompleteMasterField(
    props,
    CodeMaintenanceType.STATE_CODE,
  )
}

export default NaUsbStateCodeAutoCompleteField