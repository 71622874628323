import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naHSCodeCodeMaintenanceAutoCompleteField from './NaHSCodeCodeMaintenanceAutoCompleteField'

function NaHSCodeAutocompleteField(props) {
  return naHSCodeCodeMaintenanceAutoCompleteField(
    props,
    CodeMaintenanceType.HSCODE
  )
}

export default NaHSCodeAutocompleteField