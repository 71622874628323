const ManifestKeys = {
  TITLE: "title",
  ACTIVITYLOG_TITLE: "activityLog",
  SCAC: "carrierCode",
  TRIP_NUM: "tripNum",
  DRIVER: "driver",
  EXP_ARRIVAL_DATE: "expArrivalDate",
  EXP_ARRIVAL_DATE_ONLY: "expArrivalDateOnly",
  EXP_ARRIVAL_TIME: "expArrivalTime",
  FIRST_EXPECTED_PORT: "firstExpectedPort",
  CARGO_COUNT: "cargoCount",
  TEMPLATE_FLAG: "templateFlag",
  TEMPLATE_NAME: "templateName",
  TIMEZONE: "timeZone",
  MESSAGE_FUNCTION: "messageFunction",
  AMENDMENT_CODE: "amendmentCode",
  TRUCKTITLE: "trucktitle",
  SEAL_TITLE: "sealTitle",
  EXCEPTION_CARGO: "exceptionCargo",
  TRUCK_ID: "truckId",
  CONVEYANCE_TYPE: "conveyanceType",
  CONVEYANCE_NUM: "conveyanceNum",
  LICENSE_NUM: "licenseNum",
  COUNTRY_CODE: "countryCode",
  STATUS: "status",
  REVIEW_STATUS: "reviewStatus",
  STATE: "state",
  EMAIL: "email",
  SAVE_TRUCKFLAG: "saveTruckFlag",
  FETCH_TRUCK: "fetchTruck",
  SAVE_TRUCKFLAG: "saveTruckFlag",
  FETCH_TRUCK: "fetchTruck",
  SAVE_TRUCKFLAG:"saveTruckFlag",
  FETCH_TRUCK:"fetchTruck",
  SEAL_EXCARGOFLAG:"sealExCargoFlag",
  SEAL_NO1: "sealNo1",
  SEAL_NO2: "sealNo2",
  SEAL_NO3: "sealNo3",
  SEAL_NO4: "sealNo4",
  SEAL_NO5: "sealNo5",
  SEAL_NO6: "sealNo6",
  SEAL_NO7: "sealNo7",
  SEAL_NO8: "sealNo8",
  SEAL_NO9: "sealNo9",
  SEAL_NO10: "sealNo10",
  SEAL_NO11: "sealNo11",
  SEAL_NO12: "sealNo12",
  SEAL_NO13: "sealNo13",
  SEAL_NO14: "sealNo14",
  SEAL_NO15: "sealNo15",
  SEAL_NO16: "sealNo16",
  SEAL_NO17: "sealNo17",
  SEAL_NO18: "sealNo18",
  SEAL_NO19: "sealNo19",
  SEAL_NO20: "sealNo20",
  SEAL_NO_HIDE_TEXT: "sealNoHideText",
  SEAL_NO_SHOW_TEXT: "sealNoShowText",
  SEAL_NO_FOOTER_TEXT: "sealNoFooterText",
  EXEMPTION_CARGO_HIDE_TEXT: "exemptionCargoHideText",
  EXEMPTION_CARGO_SHOW_TEXT: "exemptionCargoShowText",
  EXEMPTION_CARGO_FOOTER_TEXT: "exemptionCargoFooterText",
  CANTAINER_HIDE_TEXT: "containerHideText",
  CANTAINER_SHOW_TEXT: "containerShowText",
  CANTAINER_FOOTER_TEXT: "containerFooterText",
  CREATIONDATETIME: "creationDateTime",
  SUBMITTED_DATE: "submittedDate",
  IIT: "iit",
  LVS: "lvs",
  POSTAL: "postal",
  FLYING_TRUCKS: "flyingTrucks",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  UPDATE_BUTTON: "updateButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  CANCEL_MULTIPLE_RECORDS:"cancelMultipleRecords",
  PRINT_COVERSHEET: "printCoverSheet",
  D4:"d4",
  EMAIL_COVERSHEET:"emailCoverSheet",
  EMAIL:"emailTitle",
  EMAIL_SUBJECT:"emailSubject",
  EMAIL_MESSAGE:"emailMessage",
  FAX_COVERSHEET:"faxCoverSheet",
  FAX_HELP_TEXT:"faxHelpText",
  FAX_REMARK:"faxRemark",
  // FAX:"faxCoverSheet",
  // FAX:"faxTitle",
  FAX_SUBJECT:"faxSubject",
  FAX_MESSAGE:"faxMessage",
  FAX: "fax",
  PRINT_MULTIPLE_RECORDS: "printMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  RESPONSE_TITLE: "response",
  ACTIVITY_LOGS: "activityLogs",
  CREATED_DATE_TIME: "createdDateTime",
  LOGIN_USER_ID: "loginUserId",
  FUNCTION_CODE: "functionCode",
  AUDIT_DESC: "auditDescription",
  CARGO: "cargo",
  CANT_EDIT_ST_RECORD: "cantEditST",
  CANT_SUBMIT_FROM_VIEW_PAGE: "cantSubmitFromViewPage",
  SEND_TO_CUSTOMS: "sendToCustoms",
  SEND_MANIFEST_WITH_CARGO: "sendManifestWithCargos",
  ATTACH_BUTTON: "attachButton",
  UPLOAD_BUTTON: "uploadButton",
  SUBMIT_ONEREC_ALERT: "submitOneRecordAlert",
  ERROR_MSG: "errorMessage",
  MESSAGE_FUNCTION_ADD: "messageFunctionAdd",
  MESSAGE_FUNCTION_MODIFY: "messageFunctionModify",
  MESSAGE_FUNCTION_DELETE: "messageFunctionDelete",
  MESSAGE_FUNCTION_CORRECTED: "messageFunctionCorrected",
  PRINT_A8A:"printA8A",
  PRINT_A49:"printA49",
  PRINT_MULTIPLE_RECORDS:"printMultipleRecords",
  NOTE_FLAG:"noteFlag",
  NOTE_CONTENT:"noteContent",
  CCN: "ccn",
  TOTAL_NO_OF_CARGO: "totalNoOfCargo",
  QUICK_GLANCE_TITLE: "quickGlanceTitle",
  OKAY_BUTTON: "okayButton",
  PersonDTO: {
    TITLE: "personDTO.title",
    CREW_ID: "personDTO.crewId",
    ROLE: "personDTO.role",
    SAVE_CREW_FLAG: "personDTO.saveCrewFlag",
    FETCH_CREW: "personDTO.fetchCrew",
    FAST_CARD: "personDTO.fastCard",
    FAST_CARD_NUM: "personDTO.fastCardNum",
    FIRST_NAME: "personDTO.firstName",
    MIDDLE_NAME: "personDTO.middleName",
    LAST_NAME: "personDTO.lastName",
    CITIZEN_SHIP: "personDTO.citizenShip",
    DATE_OF_BIRTH: "personDTO.dateOfBirth",
    PERSON_EMAIL: "personDTO.personEmail",
    CELL_NUM: "personDTO.cellNum"
  },
  PersonDocumentDTO: {
    TITLE: "personDocumentDTO.title",
    PERSON_DTO: "personDocumentDTO._personDTOId",
    DOCUMENT_TYPE: "personDocumentDTO.documentType",
    ISSUING_COUNTRY: "personDocumentDTO.issuingCountry",
    DOCUMENT_NUMBER: "personDocumentDTO.documentNumber",
    EXPIRY_DATE: "personDocumentDTO.expiryDate"
  },
  TrailerDTO: {
    TITLE: "trailerDTO.title",
    CONATINER_TITLE: "trailerDTO.containerTitle",
    EQUIPMENT_ID: "trailerDTO.equipmentId",
    CARGO_LOADED_IN: "trailerDTO.cargoLoadedIn",
    EQUIPMENT_NUMBER: "trailerDTO.equipmentNumber",
    LICENSE_PLATE_NUM: "trailerDTO.licensePlateNum",
    SAVE_TRAILER_FLAG: "trailerDTO.saveTrailerFlag",
    FETCH_EQUIPMENT: "trailerDTO.fetchTrailer",
    COUNTRY: "trailerDTO.countryCode",
    CONT_SEAL_EXCARGOFLAG:"trailerDTO.contSealExCargoFlag",
    STATE_CODE: "trailerDTO.stateCode",
    CONTAINER1: "trailerDTO.container1",
    CONTAINER2: "trailerDTO.container2",
    CONTAINER3: "trailerDTO.container3",
    CONTAINER4: "trailerDTO.container4",
    CONTAINER5: "trailerDTO.container5",
    SEAL_NO1: "trailerDTO.sealNo1",
    SEAL_NO2: "trailerDTO.sealNo2",
    SEAL_NO3: "trailerDTO.sealNo3",
    SEAL_NO4: "trailerDTO.sealNo4",
    SEAL_NO5: "trailerDTO.sealNo5",
    SEAL_NO6: "trailerDTO.sealNo6",
    SEAL_NO7: "trailerDTO.sealNo7",
    SEAL_NO8: "trailerDTO.sealNo8",
    SEAL_NO9: "trailerDTO.sealNo9",
    SEAL_NO10: "trailerDTO.sealNo10",
    SEAL_NO11: "trailerDTO.sealNo11",
    SEAL_NO12: "trailerDTO.sealNo12",
    SEAL_NO13: "trailerDTO.sealNo13",
    SEAL_NO14: "trailerDTO.sealNo14",
    SEAL_NO15: "trailerDTO.sealNo15",
    SEAL_NO16: "trailerDTO.sealNo16",
    SEAL_NO17: "trailerDTO.sealNo17",
    SEAL_NO18: "trailerDTO.sealNo18",
    SEAL_NO19: "trailerDTO.sealNo19",
    SEAL_NO20: "trailerDTO.sealNo20",
    IIT: "trailerDTO.iit",
    LVS: "trailerDTO.lvs",
    POSTAL: "trailerDTO.postal",
    FLYING_TRUCKS: "trailerDTO.flyingTrucks"
  },
  StatusNotification: {
    TITLE: "statusNotification.title",
    NAME: "statusNotification.name",
    SN_TYPE: "statusNotification.snType",
    SHIPMENT_CONTROL_NUM: "statusNotification.shipmentControlNum",
    EMAIL: "statusNotification.email",
    MOBILE_NUM: "statusNotification.mobileNum",
    FAX_NUM: "statusNotification.faxNum"
  },
  Validations: {
    CARRIER_CODE_LENGTH: "validations.carrierCodeLength",
    CARRIER_CODE_REGX: "validations.carrierCodeRegx",
    TRIP_NUM_LENGTH: "validations.tripNumLength",
    TRIP_NUM_PREFIX: "validations.tripNumPrefix",
    EXP_ARRIVAL_DATE: "validations.expArrivalDateValidation",
    EXP_ARRIVAL_DATE_REGX: "validations.expArrivalDateTimeRegx",
    EXPIRY_DATE: "validations.expiryDateValidation",
    ALPHA_NUMERIC_REGX: "validations.alphaNumericRegx",
    ALPHA_NUMERIC_SPACE_REGX: "validations.alphaNumericSpaceRegx",
    NUMERIC_REGX: "validations.numericRegx",
    EMAIL_MAX_LENGTH: "validations.emailMaxLength",
    EMAIL_VALID: "validations.emailValid",
    MSGFUN_FOR_AC: "validations.msgfunForAC",
    MSGFUN_FOR_DR: "validations.msgfunForDR",
    MSGFUN_FOR_CL: "validations.msgfunForCL",
    MSGFUN_FOR_MR: "validations.msgfunForMR",
    MSGFUN_FOR_RJ: "validations.msgfunForRJ",
    MSGFUN_FOR_CR: "validations.msgfunForCR",
    MSGFUN_FOR_NEW_REC: "validations.msgfunForNewRec",
    ENTER_VALID_INPUT: "validations.enterValidInput",
    NON_FAST_CARD_USER: "validations.nonFastcardUser",
    ALPHA_NUMERIC_WITH_SPLCHARS: "validations.aplhaNumericWithSplChars",
    ALPHA_NUMERIC_WITH_MORE_SPLCHARS: "validations.alphaNumericWithMoreSplChars",
    MAX_LENGTH_255: "validations.maxLength255",
    MAX_LENGTH_15: "validations.maxLength15",
    MAX_LENGTH_25: "validations.maxLength25",
    MAX_LENGTH_254: "validations.maxLength254",
    FAX_VALID:"validations.faxValid",
    MAX_LENGTH:"validations.maxLength11",
  },
  TRIP_SECTION_HELPER: "tripSectionHelper",
  TRUCK_TRAILER_SECTION_HELPER: "truckTrailerSectionHelper",
  CREW_SECTION_HELPER: "crewSectionHelper",
}

export default ManifestKeys
