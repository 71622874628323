const AciHighwayCargo = {
  TITLE: "title",
  CARGO_TYPE: "cargoType",
  CONSOLIDATED: "consolidated",
  CARRIER_CODE: "carrierCode",
  CCN: "ccn",
  FIRST_EXPECTED_PORT: "firstExpectedPort",
  ARRIVAL_DATE: "arrivalDate",
  ARRIVAL_TIME: "arrivalTime",
  ARRIVAL_DATE_AND_TIME: "arrivalDateAndTime",
  ARRIVAL_DATE_AND_TIME_ZONE: "arrivalDateAndTimeZone",
  ENTRY_NO: "entryNo",
  CARGO_LOADED_IN: "cargoLoadedIn",
  PAPER_RELEASE: "paperRelease",
  INBOND_DESTINATION_OFFICE_CODE: "inbondDestinationOfficeCode",
  WAREHOUSE_CODE: "warehouseCode",
  POL_CITY: "polCity",
  POL_COUNTRY_CODE: "polCountryCode",
  POL_STATE_CODE: "polStateCode",
  POAADMINPRINT_FLAG: "poaAdminPrintFlag",
  POA_CITY: "poaCity",
  POA_COUNTRY_CODE: "poaCountryCode",
  POA_STATE_CODE: "poaStateCode",
  ADMIN_NAME: "adminName",
  ADMIN_CONTACT: "adminContact",
  SPECIAL_INSTRUCTIONS: "specialInstructions",
  PORT_OF_EXIT: "portOfExit",
  ACQUITTAL_NO: "acquittalNo",
  DATE: "date",
  PRINT_REMARKS: "printRemarks",
  MESSAGE_FUNCTION: "messageFunction",
  AMENDMENT_CODE: "amendmentCode",
  STATUS: "status",
  REVIEW_STATUS: "reviewStatus",
  TRIP_NO: "tripNo",
  TEMPLATE_FLAG: "Create as Cargo Template",
  TEMPLATE_NAME: "Template Name",
  RESPONSE_TITLE: "responseTitle",
  EDIT_CARGO: "editCargo",
  VIEW_CARGO: "viewCargo",
  ATTACH_SENT: "attachSentRecord",
  ATTACH: "attach", 
  ATTACH_CARGO_MSG: "attachCargoMessage",
  ATTACH_CARGO_SUCESS_MSG: "attachCargoSuccessMsg",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  DELETE_BUTTON: "deleteButton",
  DETACH: "detachButton",
  DETACH_SENT_REC:"detachSentRecord",
  DETACH_MESSAGE:"detachMessage",
  DETACH_SUCCESS_MSG:"detachSuccessMsg",
  CANCEL:"cancel",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  CREATIONDATETIME: "createdDateTime",
  SUBMITTED_DATE_TIME: "submittedDateTime",
  UPDATE_BUTTON: "updateButton",
  PLACE_OF_LOADING_TITLE:"placeOfLoadingTitle",
  PLACE_OF_ACCEPTANCE_TITLE:"placeOfAcceptanceTitle",
  ADMINSTRATIVE_CONTACT:"adminstrativeContact",
  PRINT_A8A:"printA8A",
  PRINT_A49:"printA49",
  PRINT_MULTIPLE_RECORDS:"printMultipleRecords",
  SELECT_CSV_TYPE: "selectCsvType",
  PLACE_OF_ACCEPTANCE_HIDE_TEXT: "placeOfAcceptanceHideText",
  PLACE_OF_ACCEPTANCE_SHOW_TEXT: "placeOfAcceptanceShowText",
  PLACE_OF_ACCEPTANCE_FOOTER_TEXT: "placeOfAcceptanceFooterText",
  
  ADMINISTRATIVE_CONTACT_HIDE_TEXT: "administrativeContactHideText",
  ADMINISTRATIVE_CONTACT_SHOW_TEXT: "administrativeContactShowText",
  ADMINISTRATIVE_CONTACT_FOOTER_TEXT: "administrativeContactFooterText",

  PRINT_A8A_HIDE_TEXT: "printA8AHideText",
  PRINT_A8A_SHOW_TEXT: "printA8AShowText",
  PRINT_A8A_FOOTER_TEXT: "printA8AFooterText",
  HIDE_TEXT: "hideText",
  SHOW_TEXT: "showText",
  FOOTER_TEXT: "footerText",
  ADDITIONAL_CARGO: "additionalCargo",
  SHIPPER_NAME: "shipperName",
  CONSIGNEE_NAME: "consigneeName",
  CUSTOMS_BROKER_NAME: "customsBrokerName",
  CUSTOMS_BROKER_CONTACT: "customsBrokerContact",
  CUSTOMS_BROKER: "customsBroker",
  PHONE_NO: "phoneNo",
  FAX_NO: "faxNo",
  EMAIL: "email",
  CargoParties: {
    TITLE: "cargoParties.title",
    PARTY_TYPE: "cargoParties.partyType",
    BUSINESS_NO: "cargoParties.businessNo",
    PARTY_NAME: "cargoParties.partyName",
    ADDRESS: "cargoParties.address",
    CITY: "cargoParties.city",
    POSTAL_CODE: "cargoParties.postalCode",
    COUNTRY_CODE: "cargoParties.countryCode",
    STATE_CODE: "cargoParties.stateCode",
    ADMIN_NAME: "cargoParties.adminName",
    ADMIN_CONTACT: "cargoParties.adminContact",
    PARTY_DROP_DOWN: "cargoParties.partyDropDown",
    SAVE_FLAG: "cargoParties.saveFlag",
    PARTY_IDENTIFICATION:"cargoParties.partyIdentification"
  },
  CargoCommodity: {
    TITLE: "cargoCommodity.title",
    PRODUCT_DESC: "cargoCommodity.productDesc",
    QUANTITY: "cargoCommodity.quantity",
    QUANTITY_U_O_M: "cargoCommodity.quantityUOM",
    WEIGHT: "cargoCommodity.weight",
    WEIGHT_UOM: "cargoCommodity.weightUom",
    HS_CODE: "cargoCommodity.hsCode",
    MARKS_NUMBER: "cargoCommodity.marksNumber"
  },
  HazardousMaterial: {
    TITLE: "hazardousMaterial.title",
    CARGO_COMMODITY: "hazardousMaterial._cargoCommodityId",
    UNDG_CODE: "hazardousMaterial.undgCode",
    UNDG_DESC: "hazardousMaterial.undgDesc",
    ADMIN_NAME: "hazardousMaterial.adminName",
    ADMIN_CONTACT: "hazardousMaterial.adminContact"
  },
  Validations: {
    MAX_LENGTH_4: "validations.maxLength4",
    MIN_LENGTH_4: "validations.minLength4",
    CARRIER_CODE_REGX: "validations.carrierCodeRegx",
    MAX_LENGTH_25: "validations.maxLength25",
    MIN_LENGTH_5: "validations.minLength5",
    ALPHA_NUMERIC_REGX: "validations.alphaNumericRegx",
    CARRIER_CODE_PREFIX: "validations.carrierCodePrefix",
    ARRIVAL_DATE_REGX: "validations.arrivalDateTimeRegx",
    PRINT_DATE_REGX: "validations.printDateRegx",
    MAX_LENGTH_30: "validations.maxLength30",
    MAX_LENGTH_35: "validations.maxLength35",
    MAX_LENGTH_60: "validations.maxLength60",
    ALPHA_NUMERIC_WITH_SPACE: "validations.aplhaNumericWithSpace",
    ALPHA_NUMERIC_WITH_SPLCHARS: "validations.alphaNumericWithSplChar",
    MAX_LENGTH_256: "validations.maxLength256",
    MAX_LENGTH_300: "validations.maxLength300",
    MAX_LENGTH_200: "validations.maxLength200",
    MAX_LENGTH_10: "validations.maxLength10",
    MAX_LENGTH_15: "validations.maxLength15",
    MAX_LENGTH_48: "validations.maxLength48",
    MAX_LENGTH_6: "validations.maxLength6",
    NUMERIC_REGX: "validations.numericRegx",
    MSGFUN_FOR_AC: "validations.msgFunForAC",
    MSGFUN_FOR_DR: "validations.msgFunForDR",
    MSGFUN_FOR_CL: "validations.msgFunForCL",
    MSGFUN_FOR_MR: "validations.msgFunForMR",
    MSGFUN_FOR_RJ: "validations.msgFunForRJ",
    MSGFUN_FOR_CR: "validations.msgFunForCR",
    EMAIL_VALID: "validations.emailValid",
  },
  ATTACH_CARGO_SECTION_HELPER: "attachCargoSectionHelper",
  CARGO_SECTION_HELPER: "cargoSectionHelper",
  COMMODITY_SECTION_HELPER: "commoditySectionHelper",
}

export default AciHighwayCargo
