const AceIsfKeys = {
  TITLE: "title",
  TITLE_ISF5: "titleIsf5",
  ISF_SHIPMENT_AND_FILER_REFERENCE: "isfShipmentAndFilerReference",
  PARTY_DOC_TYPE_DETAILS: "partyDocTypeDetails",
  DOCUMENT_DETAILS: "documentDetails",
  PARTY_DETAILS: "partyDetails",
  PARTY_TYPE: "partyType",
  MANUFACTURER_HTS_DETAILS: "manufacturerHtsDetails",
  MY_REFERENCE: "myReference",
  ISF_SHIPMENT: "isfShipment",
  SHIPMENT_REFERENCE: "shipmentReference",
  FILER_REFERENCE: "filerReference",
  COMMERCIAL_PARTY: "commercialParty",
  DELIVERY_LOCATION: "deliveryLocation",
  HTS_DETAILS: "htsDetails",
  CONTAINER_INFO: "containerInfo",
  ID: "id",
  SHIPMENT_TYPE: "shipmentType",
  MODE_OF_TRANSPORT: "modeOfTransport",
  ENTRY_NO: "entryNo",
  IMPORTER_DOC_TYPE: "importerDocType",
  IMPORTER_REF_NO: "importerRefNo",
  DATE_OF_BIRTH: "dateOfBirth",
  COUNTRY_ISSUANCE: "countryIssuance",
  NAME: "name",
  CBP_BOND: "cbpBond",
  BOND_TYPE: "bondType",
  BOND_HOLDER_DOC_TYPE: "bondHolderDocType",
  BOND_HOLDER_DOC_NO: "bondHolderDocNo",
  BOND_TERM: "bondTerm",
  BOND_TERM_REF_NO: "bondTermRefNo",
  BOND_TERM_SURETY_CODE: "bondTermSuretyCode",
  AGENT_CODE: "agentCode",
  SCAC: "scac",
  ACTION_CODE: "actionCode",
  ACTION_REASON_CODE: "actionReasonCode",
  REF_BOOKING_NO: "refBookingNo",
  PORT_OF_LADING: "portOfLading",
  ESTIMATE_LOADING_DATE: "estimateLoadingDate",
  CBP_TRANSACTION_NO: "cbpTransactionNo",
  CBP_TRANSACTION_NO_INFO: "cbpTransactionNoInfo",
  RESPONSE_DATE_TIME: "responseDateTime",
  CREATED_DATE_TIME: "createdDateTime",
  SUBMITTED_DATE_TIME: "submittedDateTime",
  UPDATE_BUTTON: "updateButton",
  SAVE_BUTTON: "saveButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_BUTTON: "editButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  BOND_NO: "bondNo",
  MASTER_B_L: "masterBL",
  COUNTRY_UNLADING: "countryUnlading",
  FOREIGN_PORT_UNLADING: "foreignPortUnlading",
  COUNTRY_DELIVERY: "countryDelivery",
  FOREIGN_PLACE_DELIVERY: "foreignPlaceDelivery",
  STATUS: "status",
  SUBMIT_SUCCESS_MSG:"submitSuccessMsg",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  TEMPLATE_FLAG: "templateFlag",
  TEMPLATE_NAME: "templateName",
  CREATED_DATE: "createdDate",
  SHIPMENT_SUB_TYPE:"shipmentSubType",
  ESTIMATED_VALUE:"estimatedValue",
  ESTIMATED_QUANTITY:"estimatedQuantity",
  UNIT_OF_MEASURE:"unitOfMeasure",
  ESTIMATED_WEIGHT:"estimatedWeight",
  WEIGHT_QUALIFIERS:"weightQualifiers",
  CARNET_NUMBER: "carnetNumber",
  EDIT_ISF10: "editISF10",
  PRINT_ISF10: "printISF10",
  EDIT_ISF5: "editISF5",
  VIEW_ISF: "viewIsf",
  EDIT_SENT_RECORDS: "editSentRecords",
  DELETE_RECORDS: "deleteRecords",
  SAME_AS: "sameAs",
  PRINT_ISF5:"printISF5",
  Shipment: {
    TITLE: "shipment.title",
    ID: "shipment.id",
    HEADER_ID: "shipment.headerId",
    BL_TYPE: "shipment.blType",
    BL_NO: "shipment.blNo",
    BL_MATCHED: "shipment.blMatched"
  },
  Party: {
    PARTY_ID: "party.partyId",
    PARTY_ID_LOOKUP: "party.partyIdLookup",
    SAVE_TO_PARTY_MASTER: "party.saveToPartyMaster",
    DOC_TYPE: "party.docType",
    DOC_NUMBER: "party.docNumber",
    PARTY_NAME: "party.partyName",
    SECONDARY_INFO_TYPE: "party.secondaryInfoType",
    SECONDARY_INFO: "party.secondaryInfo",
    ADDRESS: "party.address",
    CITY: "party.city",
    COUNTRY: "party.country",
    POSTAL_CODE: "party.postalCode",
    PROVINCE: "party.province"
  },
  Hts: {
    TITLE: "hts.title",
    ID: "hts.id",
    MANUFACTURER_PARTY_ID: "hts.manufacturerPartyId",
    HTS_NO: "hts.htsNo",
    COUNTRY_ORIGIN: "hts.countryOrigin"
  },
  Container: {
    TITLE: "container.title",
    ID: "container.id",
    HEADER_ID: "container.headerId",
    CONTAINER_NO: "container.containerNo",
    DESCRIPTION_CODE: "container.descriptionCode",
    CONTAINER_INITIAL: "container.containerInitial",
    TYPE_CODE: "container.typeCode",
    SIZE_TYPE_CODE: "container.sizeTypeCode"
  },
  Transaction: {
    TITLE: "transaction.title",
    ID: "transaction.id",
    HEADER_ID: "transaction.headerId",
    BATCH_ID: "transaction.batchId"
  },
  Response: {
    TITLE: "response.title",
    ID: "response.id",
    HEADER_ID: "response.headerId",
    STATUS_CODE: "response.statusCode",
    ERROR_CODE: "response.errorCode",
    DESCRIPTION: "response.description",
    REMARKS: "response.remarks",
    RECEIVED_DATE: "response.receivedDate",
    CBP_PROCESSED_DATE: "response.cbpProcessedDate",
    CBP_BATCH_NO: "response.cbpBatchNo",
    TRANSACTION_NO: "response.transactionNo",
    STATUS_NOTIFICATION: "response.statusNotification",
    DISPOSITION_CODE: "response.dispositionCode"
  },
  Validation: {
    DOB_DATE_REGEX: "validation.dobDateRegx",
    ESTIMATE_LOADING_DATE_DATE_REGEX: "validation.estimateLoadingDateDateRegx"
  }
}

export default AceIsfKeys
