import React, { createRef, useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  base: {
    position: 'relative'
  },
  fake: {
    overflow: 'auto hidden',
    width: 'calc(100% - 1rem)',
    '&, & > *': { height: '1rem'}
  },
  table: {
    
  }
}))

function DoubleScroll({children, ...props}) {
  const classes = useStyles()
  const [measuredWidth, setMeasuredWidth] = useState('100%')
  const [displayState, setDisplayState] = useState('none')
  const ref = createRef()
  const outerRef = createRef()

  const setInfo = () => {
    setMeasuredWidth(ref.current?.scrollWidth)
    setDisplayState(ref.current?.scrollWidth === ref.current?.clientWidth ? 'none' : 'block')
  }

  const scrollFn = () => outerRef.current.scrollLeft = ref.current.scrollLeft
  const scrollFnFake = () => ref.current.scrollLeft = outerRef.current.scrollLeft

  useEffect(() => {
    window.addEventListener('resize', setInfo)
  }, [])
  useEffect(() => {
    setInfo()
  }, [ref])

  return <>
    <Box>
      <Box className={classes.fake} display={displayState} ref={outerRef} onScroll={scrollFnFake}>
        <Box width={measuredWidth} height='1rem'/>
      </Box>
      <Box  {...props} ref={ref} onScroll={scrollFn}>{children}</Box>
    </Box>
  </>
}

export default DoubleScroll