const AciAirKeys = {
  TITLE: "title",
  ID: "id",
  MSG_TYPE: "msgType",
  MESSAGE_FUNCTION: "messageFunction",
  FORM_TYPE: "formType",
  CUSTOMS_PROC: "customsProc",
  CONV_REF_NO: "convRefNo",
  VOYAGE_NO: "voyageNo",
  MOT: "mot",
  ORIGINAL_C_C_N: "originalCCN",
  CARRIER_CODE: "carrierCode",
  CCN: "ccn",
  SRN: "srn",
  CREATED_BY: "createdBy",
  PORT_OF_LOADING: "portOfLoading",
  RECEIPT_COUNTRY: "receiptCountry",
  RECEIPT_CITY: "receiptCity",
  DELIVERY_COUNTRY: "deliveryCountry",
  DELIVERY_CITY: "deliveryCity",
  CUSTOMS_OFF: "customsOff",
  WAREHOUSE_DISCHARGE: "warehouseDischarge",
  SPLIT_SHIPMENT_IND: "splitShipmentInd",
  RAMP_TRANSFER_IND: "rampTransferInd",
  SUPP_REQ_IND: "suppReqInd",
  SPECIAL_INS: "specialIns",
  SCHEDULE_DATE_TIME: "scheduleDateAndTime",
  SCHEDULE_SUBMISSION_FLAG: "scheduledSubmissionFlag",
  TEMPLATE_FLAG: "templateFlag",
  TEMPLATE_NAME: "templateName",
  PART_ARRIVAL_REF_NO: "partArrivalRefNo",
  CREATE_ACR: "createACR",
  EDIT_ACR: "updateACR",
  VIEW_ACR: "viewACR",
  CREATE_SSR: "createSSR",
  EDIT_SSR: "editSSR",
  VIEW_SSR: "viewSSR",
  CREATE_S10: "createS10",
  EDIT_S10: "editS10",
  VIEW_S10: "viewS10",
  ACR_TITLE: "acrTitle",
  SSR_TITLE: "ssrTitle",
  S10_TITLE: "s10Title",
  HEADER: "header",
  SCHEDULE: "schedule",
  ACTIVITY_LOGS: "activityLogs",
  CREATED_DATE_TIME: "createdDateTime",
  LOGIN_USER_ID: "loginUserId",
  FUNCTION_CODE: "functionCode",
  AUDIT_DESC: "auditDescription",
  DELIVERY_TERMINAL_NAME: "deliveryTerminalName",
  CRN_HELP_TEXT: "crnHelpText",
  MANIFEST_QUANTITY_HELP_TEXT:"manifestQuantityHelpText",
  CCN_PLAIN: "ccnPlain",
  SRN_PLAIN: "srnPlain",
  PARN_PLAIN: "parnPlain",
  SENT_DATE: "sentDate",
  ACR_TABLE: "acrTable",
  SSR_TABLE: "ssrTable",
  S10_TABLE: "s10Table",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  STATUS: "status",
  UNIQUE_CONSIGNMENT_REF_NO: "uniqueConsignmentRefNo",
  RECEIPT_TERMINAL_NAME: "receiptTerminalName",
  CARGO_FACILITY_LOC: "cargoFacilityLoc",
  CUSTOMS_OFF_DISCHARGE: "customsOfficeDischarge",
  CUSTOMS_OFF_DISCHARGE_LOC: "customsOfficeDischargeLoc",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  SUPPLEMENTARY_MATCH: "supplementaryMatch",
  RISK_ASSESSMENT_STATUS: "riskAssessmentStatus",
  AciAirRouteList: {
    TITLE: "aciAirRouteList.routeInformation",
    CARRIER_CODE: "aciAirRouteList.carrierCode",
    LOCATION_CODE: "aciAirRouteList.locationCode"
  },
  AciAirPartyList: {
    TITLE: "aciAirPartyList.partyInformation",
    PARTY_DROP_DOWN: "aciAirPartyList.partyList",
    SAVE_FLAG: "aciAirPartyList.saveParty",
    PARTY_IDENTIFICATION: "aciAirPartyList.partyIdentification",
    PARTY_TYPE: "aciAirPartyList.partyType",
    PARTY_NAME1: "aciAirPartyList.partyName1",
    PARTY_NAME2: "aciAirPartyList.partyName2",
    ADDRESS1: "aciAirPartyList.address1",
    ADDRESS2: "aciAirPartyList.address2",
    CITY: "aciAirPartyList.city",
    STATE_CODE: "aciAirPartyList.stateCode",
    POSTAL_CODE: "aciAirPartyList.postalCode",
    COUNTRY_CODE: "aciAirPartyList.countryCode",
    CONTACT_PERSON: "aciAirPartyList.contactPerson",
    TEL_NO: "aciAirPartyList.telephoneNumber"
  },
  AciAirManifestQtyList: {
    TITLE: "aciAirManifestQtyList.manifestQuantity",
    QUANTITY: "aciAirManifestQtyList.quantity",
    UOM: "aciAirManifestQtyList.uom"
  },
  AciAirEquipmentList: {
    TITLE: "aciAirEquipmentList.equipmentInformation",
    CONTAINERID: "aciAirEquipmentList.equipmentID"
  },
  AciAirItemList: {
    PRODUCT_DROP_DOWN: "aciAirItemList.productDropdown",
    SAVE_FLAG: "aciAirItemList.saveProduct",
    PRODUCT_IDENTIFICATION: "aciAirItemList.productId",
    TITLE: "aciAirItemList.goodsDetails",
    ITEM_NO: "aciAirItemList.itemNumber",
    LADING_QUANTITY: "aciAirItemList.ladingQuantity",
    LADING_UOM: "aciAirItemList.ladingUOM",
    PRODUCT_DESC: "aciAirItemList.productDescription",
    PRODUCT_DESC1: "aciAirItemList.productDescription1",
    PRODUCT_DESC2: "aciAirItemList.productDescription2",
    PRODUCT_DESC3: "aciAirItemList.productDescription3",
    PRODUCT_DESC4: "aciAirItemList.productDescription4",
    PRODUCT_DESC5: "aciAirItemList.productDescription5",
    PRODUCT_DESC6: "aciAirItemList.productDescription6",
    PRODUCT_DESC7: "aciAirItemList.productDescription7",
    PRODUCT_DESC8: "aciAirItemList.productDescription8",
    GROSS_WEIGHT_UOM: "aciAirItemList.grossWeightUOM",
    GROSS_WEIGHT: "aciAirItemList.grossWeight",
    MEASUREMENT_UOM: "aciAirItemList.measurementUOM",
    MEASUREMENT_VOL: "aciAirItemList.measurementVolume",
    UNDG_CODE: "aciAirItemList.dangerousGoodsCode",
    MARK_NO: "aciAirItemList.marksNo",
    HS_CODE: "aciAirItemList.hsCode"
  },
  AciAirStatusNotifyList: {
    TITLE: "aciAirStatusNotifyList.statusNotification",
    STATUS_TYPE: "aciAirStatusNotifyList.statusType",
    EMAIL: "aciAirStatusNotifyList.eMail",
    PHONE_NUMBER: "aciAirStatusNotifyList.phoneNumber",
    MSG_TYPE: "aciAirStatusNotifyList.messageType"
  },
  Response:{
    TITLE: "response.responseMessage",
    TAB_LOCKED: "response.message"
  }
}

export default AciAirKeys
