import React from 'react'
import clsx from 'clsx'
import { useCheckboxStyles } from '../../../theme/upsComponents'

function CheckedIcon() {
  const checkboxClasses = useCheckboxStyles()

  return (
    <span className={clsx(checkboxClasses.icon, checkboxClasses.checkedIcon)} />
  )
}

export default CheckedIcon
