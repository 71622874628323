const AceHighwayAbiInbondEventKeys = {
  TITLE: "title",
  RESPONSE_TITLE: "responseTitle",
  ACTIVITYLOG_TITLE: "activityLogTitle",
  ID: "id",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  STATUS: "status",
  RESPONSE_DATE: "responseDate",
  FILER_CODE: "filerCode",
  PORT_CODE: "portCode",
  ACTION_CODE: "actionCode",
  INBOND_NUMBER: "inbondNumber",
  MASTER_BOL_ISSUER_CODE: "masterBolIssuerCode",
  MASTER_BOL: "masterBol",
  HOUSE_BOL: "houseBol",
  FIRM_LOCATION: "firmLocation",
  CONTAINER_NUMBER: "containerNumber",
  ARRIVAL_DATE: "arrivalDate",
  ARRIVAL_TIME: "arrivalTime",
  ARRIVAL_PORT: "arrivalPort",
  INBOND_CARRIER_CODE: "inbondCarrierCode",
  BONDED_CARRIER_ID: "bondedCarrierId",
  CITY: "city",
  STATE_CODE: "stateCode",
  EXPORT_MOT: "exportMot",
  EXPORT_CONVEYANCE: "exportConveyance",
  SAVE_BUTTON: "saveButton",
  UPDATE_BUTTON: "updateButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_SENT_RECORDS: "editSentRecords",
  EDIT_BUTTON: "editButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_DRAFT_RECORDS: "deleteDraftRecords",
  CANCEL_BUTTON: "cancelButton",
  CANCEL_MULTIPLE_RECORDS:"cancelMultipleRecords",
  CLONE_BUTTON: "cloneButton",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ONEREC_ALERT: "submitOneRecordAlert",
  LOGIN_USER_ID: "loginUserId",
  FUNCTION_CODE: "functionCode",
  AUDIT_DESC: "auditDescription",
  Validations:{
    MIN_LENGTH: "validations.minLength",
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumericRegx",
    ALPHANUMERIC_SPACE: "validations.alphaNumericSpaceRegx",
    ALPHANUMERIC_SPECIAL_CHAR: "validations.alphaNumericSpecialChar",
    NUMERIC: "validations.numericErrorMessage",
    INVALID_ARRIVAL_DATE_TIME_FORMAT:"validations.arrivalDateTimeFormat"
  }
}

export default AceHighwayAbiInbondEventKeys
