import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form'
import _ from 'lodash'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import clsx from 'clsx'

const DEFAULT_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100, 500, 1000]

const {
  button: { CngButton, CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  table: {
    marginTop: 0,
    minWidth: 900,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableRow-root': {
        '& .MuiTableCell-head': {
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.2,
          padding: '12px 16px',
          textTransform: 'uppercase',
          '& .MuiTypography-root': {
            cursor: 'default'
          }
        },
        '&.table-filter-row': {
          '& .MuiTableCell-head': {
            textTransform: 'none',
            '& .MuiInputBase-root': {
              fontSize: 14,
              '& .MuiOutlinedInput-input': {
                paddingTop: 8,
                paddingBottom: 8
              }
            }
          }
        }
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected
        },
        '& .MuiTableCell-root': {
          borderBottom:
            theme.palette.mode === 'dark'
              ? '1px solid rgba(255, 255, 255, 0.2)'
              : '1px solid rgba(224, 224, 224, 1)',
          padding: '12px 16px',
          fontSize: 14
        }
      }
    }
  },
  itemsPerPage: {
    backgroundColor: theme.palette.background.sectionOddBg,
    borderRadius: theme.shape.borderRadius,
    '& .MuiSelect-root': {
      fontSize: 14,
      padding: '8px 24px 8px 8px'
    }
  },
  loader: {
    alignItems: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: theme.shape.borderRadius,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  },
  headerSortSelect: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 700,
      padding: '4px 24px 4px 0'
    }
  },
  checkbox: {
    padding: '0 12px',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: 1.2
    }
  }
}))

const LocalTable = React.forwardRef((props, ref) => {
  const {
    actions,
    columns,
    data: tableData,
    defaultFilters,
    defaultPage,
    defaultPageSize,
    defaultSelected,
    defaultSort,
    header,
    moreActions,
    onRowClick,
    propertyIndicator = 'id',
    rowActions,
    select,
    selectActions,
    loading = false
  } = props

  const [data, setData] = useState()
  const [sort, setSort] = useState(defaultSort || null)
  const [filters, setFilters] = useState(defaultFilters || null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(defaultPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [selected, setSelected] = useState(defaultSelected)
  const [moreActionsPopover, setMoreActionsPopover] = useState({
    anchorEl: null,
    rowData: null
  })
  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const filteredAndSortedData = useMemo(() => {
    let data = [...tableData]

    if (_.isEmpty(filters) && _.isEmpty(sort)) return data

    function filterData() {
      filters.forEach((filter) => {
        switch (filter.type) {
          case 'checkbox': {
            data = data.filter((datum) =>
              filter.value.includes(datum[filter.field])
            )
            break
          }

          case 'select': {
            data = data.filter((datum) => datum[filter.field] === filter.value)
            break
          }

          case 'textfield': {
            const regex = new RegExp(filter.value, 'i')

            data = data.filter((datum) => {
              if (!datum[filter.field]) return false
              return regex.test(datum[filter.field])
            })
            break
          }

          default:
            throw new Error('Invalid filter type.')
        }
      })
    }

    function sortData() {
      data.sort((a, b) => {
        const { sortField } = sort
        const sortType = sort.sortType.toLowerCase()

        if (sortType === 'asc') {
          return a[sortField] < b[sortField] ? -1 : 1
        } else if (sortType === 'desc') {
          return a[sortField] > b[sortField] ? -1 : 1
        }
      })
    }

    if (!_.isEmpty(filters)) filterData()
    if (!_.isEmpty(sort)) sortData()

    return data
  }, [filters, sort, tableData])

  useEffect(() => {
    const result = filteredAndSortedData.slice(
      page * pageSize,
      page * pageSize + pageSize
    )

    setData(result)
  }, [filteredAndSortedData, page, pageSize])

  useEffect(() => { setPage(0) }, [filteredAndSortedData])

  const hasHeader = !_.isEmpty([...actions, ...selectActions]) || header
  const hasFilterRow = !_.isEmpty(
    columns.filter((column) => !_.isEmpty(column?.filterConfig))
  )
  const hasActionColumn = !_.isEmpty([...rowActions, ...moreActions])
  const currentPageSelectedRows = selected.filter((selectedRow) =>
    data
      .map((rowData) => rowData[propertyIndicator])
      .includes(selectedRow[propertyIndicator])
  )
  const totalColumnAmount =
    columns.length + (hasActionColumn ? 1 : 0) + (select ? 1 : 0)

  function handleColumnSortChange(sortKey) {
    if (!sort) {
      setSort({ sortField: sortKey, sortType: 'asc' })
    } else {
      if (sort.sortField === sortKey) {
        if (sort.sortType === 'desc') {
          setSort(null)
        } else if (sort.sortType === 'asc') {
          setSort({ sortField: sortKey, sortType: 'desc' })
        }
      } else {
        setSort({ sortField: sortKey, sortType: 'asc' })
      }
    }
  }

  function handlePageSizeChange(event) {
    setPage(0)
    setPageSize(parseInt(event.target.value))
  }

  const handleSelectAllRows = (event) => {
    const clonedSelected = [...selected]

    // Selects rows from other table page
    const otherPageSelectedRows = clonedSelected.filter(
      (selectedRow) =>
        !data
          .map((rowData) => rowData[propertyIndicator])
          .includes(selectedRow[propertyIndicator])
    )

    if (event.target.checked) {
      setSelected([...otherPageSelectedRows, ...data])
    } else {
      setSelected(otherPageSelectedRows)
    }
  }

  function handleSelectRow(datum) {
    const clonedSelected = [...selected]
    const index = clonedSelected.findIndex(
      (selectedRow) =>
        selectedRow[propertyIndicator] === datum[propertyIndicator]
    )

    if (index !== -1) {
      clonedSelected.splice(index, 1)
    } else {
      clonedSelected.push(datum)
    }

    setSelected(clonedSelected)
  }

  if (ref) {
    ref.current = {
      setLoading: setIsLoading,
      selectedRows: selected,
      setSelectedRows: setSelected
    }
  }

  return (
    <>
      <Box className={clsx(classes.root, 'new-ui-table-component')}>
        {hasHeader && (
          <Box padding={2}>
            <Grid container justify='space-between' spacing={2}>
              {select && !_.isEmpty(selected) ? (
                <>
                  <Grid item xs>
                    <Typography color='primary' variant='body2'>
                      {`${selected.length} item/s selected`}
                    </Typography>
                    <Typography variant='caption'>
                      {page * pageSize + 1}-
                      {Math.min(page * pageSize + pageSize, tableData.length)}
                      &nbsp;of&nbsp;{tableData.length}
                    </Typography>
                  </Grid>
                  <Grid item xs='auto'>
                    {!_.isEmpty(selectActions) && (
                      <Grid alignItems='center' container spacing={2}>
                        {selectActions.map((action, index) => (
                          <Grid item key={index} xs='auto'>
                            <CngButton
                              color={action.color}
                              onClick={() => action?.onClick(selected)}
                              size={action.size || 'medium'}
                              startIcon={
                                <FontAwesomeIcon icon={action.icon} size='xs' />
                              }
                              variant='outlined'
                              {...action.buttonProps}
                              style={{
                                minWidth: 100,
                                ...action.buttonProps?.style
                              }}
                            >
                              {action.label}
                            </CngButton>
                          </Grid>
                        ))}
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={() => setSelected([])}
                            size='small'
                          >
                            <CloseRoundedIcon fontSize='small' />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs>
                    {header}
                  </Grid>
                  <Grid item xs='auto'>
                    {!_.isEmpty(actions) && (
                      <Grid alignItems='center' container spacing={2}>
                        {actions.map((action, index) => (
                          <Grid item key={index} xs='auto'>
                            <CngButton
                              color={action.color}
                              onClick={action?.onClick}
                              size={action.size || 'medium'}
                              startIcon={
                                <FontAwesomeIcon icon={action.icon} size='xs' />
                              }
                              {...action.buttonProps}
                            >
                              {action.label}
                            </CngButton>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
        <PerfectScrollbar>
          <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
            <TableHead>
              <TableRow>
                {select && (
                  <TableCell padding='checkbox'>
                    <Checkbox
                      disabled={_.isEmpty(data)}
                      indeterminate={
                        selected.length > 0 &&
                        currentPageSelectedRows.length < data.length
                      }
                      checked={
                        !_.isEmpty(data) &&
                        currentPageSelectedRows.length === data.length
                      }
                      onChange={handleSelectAllRows}
                      size='small'
                    />
                  </TableCell>
                )}
                {columns.map((column, index) => {
                  const isColumnSorted = sort
                    ? column.sortKey === sort.sortField
                    : false

                  return (
                    <TableCell
                      key={index}
                      onClick={
                        column?.sortKey
                          ? () => handleColumnSortChange(column.sortKey)
                          : undefined
                      }
                    >
                      <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='row'
                        style={{ gap: 8 }}
                      >
                        {isColumnSorted && (
                          <FontAwesomeIcon
                            color={theme.palette.primary.main}
                            icon={[
                              'fal',
                              sort.sortType === 'asc'
                                ? 'caret-up'
                                : 'caret-down'
                            ]}
                          />
                        )}
                        <Typography
                          color={isColumnSorted ? 'primary' : 'initial'}
                          variant='inherit'
                        >
                          {column?.title}
                        </Typography>
                      </Box>
                    </TableCell>
                  )
                })}
                {hasActionColumn && <TableCell align='right'>Action</TableCell>}
              </TableRow>
              {hasFilterRow && (
                <TableRow className='table-filter-row'>
                  {select && <TableCell />}
                  <TableFilterRow
                    columns={columns}
                    filters={filters}
                    onFilter={(filters) => setFilters(filters)}
                  />
                  {hasActionColumn && <TableCell />}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {!_.isEmpty(data) ? (
                data.map((datum, rowIndex) => {
                  const isSelected = selected
                    .map((selectedRow) => selectedRow[propertyIndicator])
                    .includes(datum[propertyIndicator])

                  return (
                    <TableRow
                      key={datum[propertyIndicator] || rowIndex}
                      selected={isSelected}
                      {...(onRowClick && {
                        hover: true,
                        onClick: () => onRowClick(datum),
                        style: { cursor: 'pointer' }
                      })}
                    >
                      {select && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            onClick={(event) => {
                              event.stopPropagation()
                              handleSelectRow(datum)
                            }}
                            checked={isSelected}
                            size='small'
                          />
                        </TableCell>
                      )}
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex}>
                          {column.render
                            ? column.render(datum)
                            : _.get(datum, column.field)}
                        </TableCell>
                      ))}
                      {hasActionColumn && (
                        <TableCell align='right'>
                          <Grid
                            container
                            justify='flex-end'
                            spacing={1}
                            wrap='nowrap'
                          >
                            {rowActions.map((action, index) => {
                              const tooltip =
                                typeof action.tooltip === 'string'
                                  ? action.tooltip
                                  : typeof action.tooltip === 'function'
                                  ? action.tooltip(datum)
                                  : null

                              const ActionItem = React.forwardRef(
                                (props, ref) => {
                                  const disabled =
                                    typeof action.disabled === 'boolean'
                                      ? action.disabled
                                      : typeof action.disabled === 'function'
                                      ? action.disabled(datum)
                                      : false

                                  return (
                                    <span ref={ref} {...props}>
                                      <CngIconButton
                                        disabled={disabled}
                                        onClick={(event) => {
                                          event.stopPropagation()
                                          action.onClick(datum)
                                        }}
                                        icon={action.icon}
                                        size='small'
                                        type='outlined'
                                        {...action.iconButtonProps}
                                      />
                                    </span>
                                  )
                                }
                              )

                              return (
                                <Grid key={index} item xs='auto'>
                                  {tooltip ? (
                                    <Tooltip
                                      disableFocusListener
                                      placement='top'
                                      title={tooltip}
                                    >
                                      <ActionItem />
                                    </Tooltip>
                                  ) : (
                                    <ActionItem />
                                  )}
                                </Grid>
                              )
                            })}
                            {!_.isEmpty(moreActions) && (
                              <Grid item xs='auto'>
                                <CngIconButton
                                  icon={['fal', 'ellipsis-h']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setMoreActionsPopover({
                                      anchorEl: event.currentTarget,
                                      rowData: datum
                                    })
                                  }}
                                  size='small'
                                  type='outlined'
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={totalColumnAmount}>
                    No records to display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <Box padding={2}>
          <Grid container alignItems='center' justify='space-between' spacing={2}>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems='center' spacing={1}>
                <Grid item xs='auto'>
                  <Typography variant='caption'>Items per page:</Typography>
                </Grid>
                <Grid item xs='auto'>
                  <FormControl
                    className={classes.itemsPerPage}
                    hiddenLabel
                    size='small'
                    variant='filled'
                  >
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      onChange={handlePageSizeChange}
                      select
                      SelectProps={{ native: true }}
                    >
                      {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {tableData?.length > 0 &&
              <Grid item xs={12} sm={4}>
                <Typography component='div' style={{ textAlign: 'center' }} variant='caption'>
                  {page * pageSize + 1}-
                  {Math.min(page * pageSize + pageSize, filteredAndSortedData.length)}
                  &nbsp;of&nbsp;{filteredAndSortedData.length}
                </Typography>
              </Grid>
            }

            <Grid item xs={12} sm={4}>
              <Grid container justify='flex-end'>
                <Grid item xs='auto'>
                  <Pagination
                    className={classes.pagination}
                    count={Math.ceil(filteredAndSortedData.length / pageSize)}
                    page={page + 1}
                    onChange={(e, page) => setPage(page - 1)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {isLoading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <MoreActionsPopover
        anchorEl={moreActionsPopover.anchorEl}
        open={moreActionsPopover.anchorEl ? true : false}
        onClose={() => setMoreActionsPopover({ anchorEl: null, rowData: null })}
        moreActions={moreActions}
        rowData={moreActionsPopover.rowData}
      />
    </>
  )
})

export default LocalTable

LocalTable.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  header: PropTypes.node,
  moreActions: PropTypes.array,
  rowActions: PropTypes.array,
  select: PropTypes.bool
}

LocalTable.defaultProps = {
  actions: [],
  columns: [],
  data: [],
  defaultPage: 0,
  defaultPageSize: 10,
  defaultSelected: [],
  moreActions: [],
  propertyIndicator: '_id',
  rowActions: [],
  select: false,
  selectActions: []
}

function TableFilterRow(props) {
  const { columns, filters: filterValues, onFilter } = props

  const filters = useMemo(
    () =>
      columns
        .filter((column) => !_.isEmpty(column?.filterConfig))
        .map((column) => ({
          ...column.filterConfig,
          field: column?.field,
          type: column.filterConfig.type || 'textfield'
        })),
    [columns]
  )

  const initDefaultValues = useCallback(() => {
    if (!filters || _.isEmpty(filters)) return undefined

    let defaultValues = {}

    filters.forEach((filter) => {
      switch (filter.type) {
        case 'checkbox': {
          defaultValues[filter.field] = getFilterValue(filter.field) ?? []
          break
        }

        case 'select':
        case 'textfield': {
          defaultValues[filter.field] = getFilterValue(filter.field) ?? ''
          break
        }

        default:
          throw new Error('Invalid field type.')
      }
    })

    return defaultValues
  }, [filters])

  const methods = useForm({ defaultValues: initDefaultValues() })

  useEffect(() => {
    const subscription = methods.watch(
      _.debounce((value) => {
        const result = transformFilterValues(value)
        onFilter(result)
      }, 1000)
    )

    return () => subscription.unsubscribe()
  }, [methods.watch])

  function getFilterValue(field) {
    return filterValues?.find((filter) => filter.field === field)?.value
  }

  function transformFilterValues(values) {
    let result = []

    for (const property in values) {
      if (!values[property] || _.isEmpty(values[property])) continue

      const currentFilter = filters.find((filter) => filter.field === property)

      result.push({
        field: property,
        type: currentFilter.type,
        value: values[property]
      })
    }

    return result
  }

  function renderField(filter) {
    switch (filter.type) {
      case 'checkbox': {
        return <CheckboxFilterField filter={filter} />
      }

      case 'select': {
        return (
          <Controller
            name={filter.field}
            control={methods.control}
            render={({ field }) => (
              <TextField
                hiddenLabel
                select
                SelectProps={{
                  displayEmpty: true,
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                      disablePadding: true
                    }
                  }
                }}
                size='small'
                variant='outlined'
                {...field}
              >
                <MenuItem value=''>Select</MenuItem>
                {filter.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )
      }

      case 'textfield': {
        return (
          <Controller
            name={filter.field}
            control={methods.control}
            render={({ field }) => (
              <TextField
                placeholder='Search'
                hiddenLabel
                size='small'
                variant='outlined'
                {...field}
              />
            )}
          />
        )
      }

      default:
        throw new Error('Invalid field type.')
    }
  }

  return (
    <FormProvider {...methods}>
      {columns.map((column, colIndex) => {
        const index = filters.findIndex(
          (filter) => filter.field === column.field
        )

        return (
          <TableCell key={column?.field || colIndex}>
            {index !== -1 && (
              <Box minWidth={100}>{renderField(filters[index])}</Box>
            )}
          </TableCell>
        )
      })}
    </FormProvider>
  )
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: 4,
    width: (props) => props.width || theme.breakpoints.values.sm
  }
}))(Paper)

function CheckboxFilterField(props) {
  const { filter } = props

  const [popover, setPopover] = useState({ anchorEl: null })
  const { setValue, watch } = useFormContext()
  const value = watch(filter.field)

  return (
    <>
      <TextField
        hiddenLabel
        onClick={(event) => setPopover({ anchorEl: event.currentTarget })}
        inputProps={{
          readOnly: true,
          style: { cursor: 'pointer' },
          value: value.length === 0 ? 'All' : `${value.length} selected`
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <ArrowDropDownRoundedIcon fontSize='small' />
            </InputAdornment>
          )
        }}
        size='small'
        variant='outlined'
      />
      <Popover
        anchorEl={popover.anchorEl}
        onClose={() => setPopover({ anchorEl: null })}
        open={popover.anchorEl ? true : false}
        {...DEFAULT_POPOVER_PROPS}
      >
        <StyledPopoverWrapper width={200}>
          <CheckboxField
            name={filter.field}
            onChange={(data) => setValue(filter.field, data)}
            options={filter.options}
            value={value}
          />
        </StyledPopoverWrapper>
      </Popover>
    </>
  )
}

function CheckboxField(props) {
  const { name, onChange, options, value } = props
  const classes = useStyles()

  function handleCheckboxGroupChange(event) {
    const result = _.xor(value, [event.target.value])
    onChange(result)
  }

  return (
    <FormGroup className={classes.checkbox}>
      {options.map((option) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value.includes(option.value)}
              inputProps={{ name: name }}
              onChange={handleCheckboxGroupChange}
              size='small'
              value={option.value}
            />
          }
          key={option.value}
          label={option.label}
        />
      ))}
    </FormGroup>
  )
}

function MoreActionsPopover(props) {
  const { anchorEl, onClose, open, moreActions, rowData } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width='auto'>
        <MenuList disablePadding>
          {!_.isEmpty(moreActions) &&
            rowData &&
            moreActions.map((action, index) => {
              const tooltip =
                typeof action.tooltip === 'string'
                  ? action.tooltip
                  : typeof action.tooltip === 'function'
                  ? action.tooltip(rowData)
                  : null

              const ActionMenuItem = () => {
                const disabled =
                  typeof action.disabled === 'boolean'
                    ? action.disabled
                    : typeof action.disabled === 'function'
                    ? action.disabled(rowData)
                    : false

                return (
                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      action.onClick(rowData)
                      onClose()
                    }}
                  >
                    {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                    <Typography variant='body2' style={{ fontWeight: 700 }}>
                      {action.label}
                    </Typography>
                  </MenuItem>
                )
              }

              return tooltip ? (
                <Tooltip key={index} placement='top' title={tooltip}>
                  <span>
                    <ActionMenuItem />
                  </span>
                </Tooltip>
              ) : (
                <ActionMenuItem key={index} />
              )
            })}
        </MenuList>
      </StyledPopoverWrapper>
    </Popover>
  )
}
