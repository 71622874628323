const SbciProductMasterKeys = {
  TITLE: "title",
  ID: "id",
  PRODUCTID: "productId",
  PART_NO: "partNo",
  HTS_CODE: "htsCode",
  PRODUCT_DESC: "productDesc",
  PART_TYPE: "partType",
  PURCHASE_ORDER_NO: "purchaseOrderNo",
  COUNTRY: "country",
  UNIT_VALUE: "unitValue",
  BIND_RULING_ID_TYPE: "bindRulingIdType",
  BIND_RULING_ID: "bindRulingId",
  SPCL_PRG_IND: "spclPrgInd",
  NAFTA_CRITERIA: "naftaCriteria",
  DATE_RANGE_FROM: "dateRangeFrom",
  DATE_RANGE_TO: "dateRangeTo",
  NET_COST: "netCost",
  IS_PRODUCER: "isProducer",
  NOT_A_PRODUCER_OF_GOODS: "notAProducerOfGoods",
  INCLUDE_PROD_NAFTA: "includeProdNafta",
  PART_REF_NO: "partRefNo",
  QUANTITY: "quantity",
  QUANTITY_UOM: "quantityUom",
  UOM1_FACTOR1: "uom1Factor1",
  UOM2_FACTOR1: "uom2Factor1",
  GROSS_WT: "grossWt",
  NET_WT: "netWt",
  CORPID: "corpid",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  YES : "yes",
  NO : "no",
  AddiInfo: {
    TITLE: "addiInfo.title",
    ID: "addiInfo.id",
    PROD_ID: "addiInfo.prodId",
    ADDITIONAL_INFO_TYPE: "addiInfo.additionalInfoType",
    ADDITIONAL_INFO_NO: "addiInfo.additionalInfoNo"
  },
  OgaCode: {
    TITLE: "ogaCode.title",
    ID: "ogaCode.id",
    PROD_ID: "ogaCode.prodId",
    OGA_CODE: "ogaCode.ogaCode",
    OGA_DOC_NO: "ogaCode.ogaDocNo"
  },
  FccTab: {
    TITLE: "fccTab.title",
    ID: "fccTab.id",
    PROD_ID: "fccTab.prodId",
    IMPORT_CONDITION_NO: "fccTab.importConditionNo",
    FCC_IDENTIFIER: "fccTab.fccIdentifier",
    IMPORT_CONDITION: "fccTab.importCondition",
    FCC_REQUIREMENT: "fccTab.fccRequirement",
    TRADE_NAME: "fccTab.tradeName",
    WITHHOLD_INSPECTION: "fccTab.withholdInspection"
  },
  FdaTab: {
    TITLE: "fdaTab.title",
    ID: "fdaTab.id",
    PROD_ID: "fdaTab.prodId",
    PRODUCT_CODE: "fdaTab.productCode",
    STORAGE_STATUS: "fdaTab.storageStatus",
    COUNTRY: "fdaTab.country",
    MANUFACTURER_ID: "fdaTab.manufacturerId",
    SHIPPER_ID: "fdaTab.shipperId",
    MARKER: "fdaTab.marker"
  },
  FdaAffirmation: {
    TITLE: "fdaAffirmation.title",
    ID: "fdaAffirmation.id",
    FDA_TAB_ID: "fdaAffirmation.fdaTabId",
    AFFIRMATION_CODE: "fdaAffirmation.affirmationCode",
    AFFIRMATION_QUALIFIER: "fdaAffirmation.affirmationQualifier"
  },
  FdaQtyUom: {
    TITLE: "fdaQtyUom.title",
    ID: "fdaQtyUom.id",
    FDA_TAB_ID: "fdaQtyUom.fdaTabId",
    QUANTITY: "fdaQtyUom.quantity",
    UOM: "fdaQtyUom.uom"
	 
  },
  Validations:{
    DATE_TO_LATER_THAN_DATE_FROM : "validations.dateToLaterThanDateFrom",
    ERROR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR: "validations.errMsgAlphaNumericNoSpecialChar",
    ERROR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validations.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validations.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validations.errMsgMinLength",
    ERROR_MSG_ALPHA:"validations.errMsgAlpha",
    ERROR_MSG_DECIMAL1:"validations.errMsgDecimal1",
    ERROR_MSG_DECIMAL2:"validations.errMsgDecimal2",
    ERROR_MSG_DECIMAL3:"validations.errMsgDecimal3",
  }
}

export default SbciProductMasterKeys
