const EhblCloseMsgKeys = {
  TITLE: "title",
  PREVIOUS_CCN: "previousCcn",
  PRIMARY_CCN: "primaryCcn",
  UNIQUE_REFERENCE_NO: "uniqueRefNo",
  MANIFEST_ID: "manifestId",
  STATUS: "status",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_DATE: "submittedDate",
  SUBMITTED_BY: "submittedBy",

  SAVE_BUTTON: "saveButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  UPDATE_BUTTON: "updateButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_SENT_RECORDS: "editSentRecord",

  CARRIER_CODE: "carrierCode",
  CLOSE_MESSAGE_NUMBER: "closeMessageNumber",
  MESSAGE_FUNCTION_CODE: "messageFunctionCode",
  AMENDMENT_CODE: "amendmentCode",
  BUSINESS_NAME: "businessName",
  RELATED_CCN_LIST: "relatedCcnList",
  RELATED_CCN: "relatedCcn",

  REPSONSE_LIST: "responseList",
  BATCH_ID: "batchId",
  RECEIVED_DATETIME: "receivedDateTime",
  PROCESS_DATETIME: "processDateTime",

  MSG_FUNC_ORIGINAL: "msgFuncOriginal",
  MSG_FUNC_CHANGE: "msgFuncChange",
  MSG_FUNC_CANCEL: "msgFuncCancel",
  MSG_FUNC_PROPOSED_AMENDMENT: "msgFuncProposedAmendment",
  PRINT_BUTTON: "printButton",
  SUBMIT_SUCCESS_MSG:"submitSuccessMsg",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  ValidationMessage:{
    ERROR_MSG_ALPHA_NUMERIC: "validationMessage.errMsgAlphaNumeric",
    ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR: "validationMessage.errMsgAlphaNumericWithSpecialChar",
    ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE: "validationMessage.errMsgAlphaNumericWithSpecialCharNoSpace",
    ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN: "validationMessage.errMsgAlphaNumericWithHypen",
    ERROR_MSG_NUMERIC: "validationMessage.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validationMessage.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validationMessage.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validationMessage.errMsgMinLength",
    ERROR_MSG_ALPHA:"validationMessage.errMsgAlpha"
  },
  ACTIVITY_LOGS_INFO: {
    TITLE: "activityLogsInfo.title",
    DATE_TIME: "activityLogsInfo.dateTime",
    USERNAME: "activityLogsInfo.username",
    FUNCTION_CODE: "activityLogsInfo.functionCode",
    AUDIT_DESC: "activityLogsInfo.auditDesc",
  }
}

export default EhblCloseMsgKeys