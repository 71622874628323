import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DEFAULT_PROPS = {
  options: [],
  spacing: 1,
  itemsPerRow: 2
}

const useStyles = makeStyles((theme) => ({
  option: {
    backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    '& .MuiFormControlLabel-label': {
      width: '100%'
    }
  }
}))

function RadioButtonGroup(props) {
  const {
    disabled,
    itemsPerRow = DEFAULT_PROPS.itemsPerRow,
    options = DEFAULT_PROPS.options,
    spacing = DEFAULT_PROPS.spacing,
    value,
    ...otherProps
  } = props

  const classes = useStyles()

  function getItemsPerRow() {
    switch (itemsPerRow) {
      case 1:
        return 12
      case 2:
        return 6
      case 3:
        return 4
      case 4:
        return 3
      case 6:
        return 2
      default:
        return 6
    }
  }

  return (
    <RadioGroup {...otherProps}>
      <Grid container spacing={spacing}>
        {options.map((option, index) => (
          <Grid key={index} item xs={12} sm={6} md={getItemsPerRow()}>
            <FormControlLabel
              className={classes.option}
              disabled={disabled}
              control={
                <Radio
                  checked={option.value === value}
                  checkedIcon={
                    <FontAwesomeIcon
                      color='inherit'
                      icon={['fas', 'circle-dot']}
                    />
                  }
                  disableRipple
                  icon={
                    <FontAwesomeIcon color='inherit' icon={['fad', 'circle']} />
                  }
                />
              }
              label={option.label}
              labelPlacement='start'
              value={option.value}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  )
}

RadioButtonGroup.propTypes = {
  itemsPerRow: PropTypes.oneOf([1, 2, 3, 4, 6]),
  options: PropTypes.array.isRequired
}

export default RadioButtonGroup
