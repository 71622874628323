import React, { useEffect, useMemo, useState } from 'react'
import { useServices } from 'cng-web-lib'
import { Doughnut } from 'react-chartjs-2'
import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { getStatusMetadata } from '../../common/NACommon'

const useStyles = makeStyles((theme) => ({
  legend: {
    '& .MuiTableCell-root': {
      padding: '8px 0',
      borderBottom: 0
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        backgroundColor: `${theme.palette.background.paper} !important`
      }
    }
  },
  select: {
    '&.MuiFilledInput-root': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      fontSize: 14,
      '& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
        paddingTop: 8,
        paddingBottom: 8
      }
    }
  }
}))

function DoughNutChart(props) {
  const { endpoint, title, onChartElementClicked } = props
  const [data, setData] = useState({})
  const [filterType, setFilterType] = useState('Today')
  const { fetchRecords } = useServices()
  const classes = useStyles()

  const chartData = useMemo(
    () => ({
      labels: Object.keys(data),
      datasets: [
        {
          data: Object.values(data),
          backgroundColor: Object.keys(data).map(
            (status) => getStatusMetadata(status).color
          )
        }
      ]
    }),
    [data]
  )

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    let partyId = (fileForUserDetails != null && fileForUserDetails != undefined) ? fileForUserDetails.fileForUserPartyId : null;

    fetchRecords.execute(
      endpoint,
      partyId != null ? { dataToBeSent: { filterType, partyId } } : { dataToBeSent: { filterType } },
      (data) => {
        setData(data.dataMapping)
      },
      (error) => {
        console.error(error)
      }
    )
  }, [filterType])

  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant='h5' style={{ fontWeight: 700 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <FormControl hiddenLabel size='small'>
              <Select
                disableUnderline
                onChange={(event) => setFilterType(event.target.value)}
                value={filterType}
                variant='filled'
                className={classes.select}
                MenuProps={{
                  MenuListProps: { dense: true, disablePadding: true }
                }}
              >
                <MenuItem value='Today'>Today</MenuItem>
                <MenuItem value='Last7Days'>Last 7 days</MenuItem>
                <MenuItem value='Last30Days'>Last 30 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box marginTop={1}>
          {onChartElementClicked && (
            <Doughnut data={chartData} legend={{ display: false }} onElementsClick={(e) => onChartElementClicked(e, title)} />
          )}
          {!onChartElementClicked && (
            <Doughnut data={chartData} legend={{ display: false }} />
          )}
          <Box marginTop={2}>
            <Table className={classes.legend}>
              <TableBody>
                {Object.keys(data).map((statusCode) => {
                  const status = getStatusMetadata(statusCode)

                  return (
                    <TableRow key={statusCode}>
                      <TableCell>
                        <Grid container alignItems='center' spacing={2}>
                          <Grid item xs='auto'>
                            <Box
                              bgcolor={status.color}
                              borderRadius='50%'
                              height={16}
                              width={16}
                            />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant='body2'
                              style={{ fontWeight: 700 }}
                            >
                              {status.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography variant='body2' style={{ fontWeight: 700 }}>
                          {data[statusCode]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default DoughNutChart
