const CrewMasterKeys = {
  TITLE: "title",
  RESPONSE_TITLE: "responseTitle",
  ACCT_ID: "acctId",
  CREW_ID: "crewId",
  CREW_TYPE: "crewType",
  SCAC: "scac",
  CUSTOM_PROXIMITY_ID: "customProximityId",
  FIRST_NAME: "firstName",
  MIDDLE_NAME: "middleName",
  LAST_NAME: "lastName",
  DOB: "dob",
  NATIONALITY: "nationality",
  TEL_NO: "telNo",
  EMAIL: "email",
  STATUS: "status",
  ACE_ID: "aceId",
  FAST_CARD: "fastCard",
  GENDER: "gender",
  ADDRESS_1: "address1",
  ADDRESS_2: "address2",
  ADDRESS_3: "address3",
  CITY_NAME: "cityName",
  STATE: "state",
  POSTAL_CODE: "postalCode",
  LICENSE_NO: "licenseNo",
  LICENSE_COUNTRY: "licenseCountry",
  LICENSE_STATE: "licenseState",
  CDL_NO: "cdlNo",
  CDL_COUNTRY: "cdlCountry",
  CDL_STATE: "cdlState",
  HAZMAT_ENDORSEMENT: "hazmatEndorsement",
  SUBMISSION_DATE: "submissionDate",
  RESPONSE_DATE: "responseDate",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SUBMIT_SENT_RECORDS: "submitSentRecords",
  SUBMIT_DIALOG_TITLE: "submitDialogTitle",
  SUBMIT_DIALOG_MESSAGE: "submitDialogMessage",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_ERROR_MESSAGE: "submitErrorMessage",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORDS: "editSentRecords",
  DELETE_BUTTON: "deleteButton",
  DELETE_SENT_RECORDS: "deleteSentRecords",
  MSG_FUNC_TYPE: "msgFuncType",
  MSG_FUNC_ADD: "msgFuncAdd",
  MSG_FUNC_DEACTIVATE: "msgFuncDeactivate",
  MSG_FUNC_MODIFY: "msgFuncModify",
  VIEW_BUTTON: "viewButton",
  InternationalDoc: {
    TITLE: "internationalDoc.title",
    DOC_TYPE: "internationalDoc.docType",
    DOC_NO: "internationalDoc.docNo",
    COUNTRY: "internationalDoc.country",
    STATE: "internationalDoc.state",
    EXPIRY_DATE: "internationalDoc.expiryDate"
  }
}

export default CrewMasterKeys
