const AdvanceCommercialInformationAirKeys = {
  TITLE: "title",
  ACR_RADIO_BUTN: "aciairFromType",
  USER_ID: "userId",
  CCN: "ccn",
  NEW_STATUS: "newStatus",
  CURRENT_STATUS: "currentStatus",
  PARN: "parn",
  MODULE: "module"

}

export default AdvanceCommercialInformationAirKeys
