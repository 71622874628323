const PartyConfigKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  MODULE: "module",
  FILTER_BY_USER_FLAG: "filterByUserFlag",
  EDIT_BUTTON: "editButton",
  DELETE_BUTTON: "deleteButton",
  CREATE_BUTTON: "createButton",
  DELETE_MESSAGE: "deleteMessage",
  DELETE_CONFIRM_MESSAGE: "deleteConfirmMessage",
  DELETE_CANCEL_MESSAGE: "deleteCancelMessage"
}

export default PartyConfigKeys
