import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naPortAutocompleteField from './NaPortAutocompleteField'

function NaAesPOUPortAutocompleteField(props) {
  return naPortAutocompleteField(
    props,
    CodeMaintenanceType.DOC_PORT,
    "AES_POU"
  )
}

export default NaAesPOUPortAutocompleteField