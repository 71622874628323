const AceHighwayManifestKeys = {
  RESPONSE_TITLE: "responseTitle",
  MANIFEST_RESPONSE_TITLE: "manifestResponseTitle",
  TRIP_RESPONSE_TITLE: "tripResponseTitle",
  SHIPMENT_RESPONSE_TITLE: "shipmentResponseTitle",
  PAXLST_RESPONSE_TITLE: "paxlstResponseTitle",
  LINKING_RESPONSE_TITLE: "linkingResponseTitle",
  BATCH_ID: "batchId",
  CBSA_PROCESSING_DATE: "cbsaProcessingDate",
  CBP_REF_NO: "cbpRefNo",
  TRIP_NO: "tripNo",
  RESPONSE_CODE: "responseCode",
  RESPONSE_TEXT: "responseText",
  NO_OF_PACKAGE: "noOfPackage",
  ENTRY_NO: "entryNo",
  ENTRY_TYPE: "entryType",
  SHIPMENT_CONTROL_NO: "shipmentControlNo",
  RESPONSE_DATE: "responseDate",
  SUBMISSION_TYPE: "submissionType",
  SUBMIT_ID:"submitId",
  METHOD:"method"
}

export default AceHighwayManifestKeys
