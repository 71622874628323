import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import PropTypes from 'prop-types'
import NaProductMasterAutoCompleteMasterField from './NaProductMasterAutoCompleteMasterField'

function NaProductMasterAutoCompleteField(props) {
    return NaProductMasterAutoCompleteMasterField(
      props,
      CodeMaintenanceType.PRODUCT_MASTER
    )
  }
  
  export default NaProductMasterAutoCompleteField