import React from 'react'
import { Chip } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import AciHighwayManifestApiUrls from "../../apiUrls/AciHighwayManifestApiUrls"
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

function TemplateSelectDialogWithOptionACIHWY(props) {
  const {
    manifestTemplateDialog,
    setManifestTemplateDialog,
    setConfirmDialog,
    showNotification,
    translatedTextsObject,
    setLicensePlateCount,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location,
    setOnChangeCarrierCode,
    setRefreshCarrierCodeAndTripNo
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()

  function handleApplyManifestTemplate(template) {
    if (template) {
      const {
        carrierCode,
        tripNum,
        expArrivalDate,
        expArrivalTime,
        firstExpectedPort,
        timeZone,
        //templateFlag,
        //templateName,
        //status,
        messageFunction,
        //amendmentCode,
        saveTruckFlag,
        truckId,
        conveyanceType,
        conveyanceNum,
        licenseNum,
        countryCode,
        state,
        email,
        sealNo1,
        sealNo2,
        sealNo3,
        sealNo4,
        sealNo5,
        sealNo6,
        sealNo7,
        sealNo8,
        sealNo9,
        sealNo10,
        sealNo11,
        sealNo12,
        sealNo13,
        sealNo14,
        sealNo15,
        sealNo16,
        sealNo17,
        sealNo18,
        sealNo19,
        sealNo20,
        iit,
        lvs,
        postal,
        flyingTrucks,
        version,
        personDTO,
        trailerDTO
      } = template

      var arrivalTimeSeparate = moment(expArrivalDate).format('HH:mm');
      var arrivalDateSeparate = moment(expArrivalDate).format('YYYY-MM-DD');
      personDTO.forEach((person) => {
        person.id = null;
        person.createdBy = null;
        person.createdDate = null;
        person.updatedBy = null;
        person.updatedDate = null;
        person.personDocumentDTO.forEach((personDoc) => {
          personDoc.id = null;
          personDoc.createdBy = null;
          personDoc.createdDate = null;
          personDoc.updatedBy = null;
          personDoc.updatedDate = null;
        });
      });

      trailerDTO.forEach((trailer) => {
        trailer.id = null;
        trailer.createdBy = null;
        trailer.createdDate = null;
        trailer.updatedBy = null;
        trailer.updatedDate = null;
      });

      if (isTable) {
        var templateJson = {
          "baseFilterDTO": null,
          "errorMessages": null,
          "customData": null,
          "id": null,
          "version": 0,
          "createdBy": null,
          "createdDate": null,
          "updatedBy": null,
          "updatedDate": null,
          "partyId": null,
          "carrierCode": carrierCode,
          "tripNum": tripNum,
          "expArrivalDate": arrivalDateSeparate,
          "expArrivalTime": arrivalTimeSeparate,
          "firstExpectedPort": firstExpectedPort,
          "timeZone": timeZone,
          "templateFlag": false,
          "templateName": "",
          "status": "",
          "messageFunction": messageFunction,
          "amendmentCode": "",
          "saveTruckFlag": saveTruckFlag,
          "truckId": truckId,
          "conveyanceType": conveyanceType,
          "conveyanceNum": conveyanceNum,
          "licenseNum": licenseNum,
          "countryCode": countryCode,
          "state": state,
          "email": email,
          "sealNo1": sealNo1,
          "sealNo2": sealNo2,
          "sealNo3": sealNo3,
          "sealNo4": sealNo4,
          "sealNo5": sealNo5,
          "sealNo6": sealNo6,
          "sealNo7": sealNo7,
          "sealNo8": sealNo8,
          "sealNo9": sealNo9,
          "sealNo10": sealNo10,
          "sealNo11": sealNo11,
          "sealNo12": sealNo12,
          "sealNo13": sealNo13,
          "sealNo14": sealNo14,
          "sealNo15": sealNo15,
          "sealNo16": sealNo16,
          "sealNo17": sealNo17,
          "sealNo18": sealNo18,
          "sealNo19": sealNo19,
          "sealNo20": sealNo20,
          "iit": iit,
          "lvs": lvs,
          "postal": postal,
          "flyingTrucks": flyingTrucks,
          "personDTO": personDTO,
          "trailerDTO": trailerDTO
        }

        history.push(`${location.pathname}/add`, templateJson)

      } else {
        setValue('carrierCode', carrierCode || '', { shouldDirty: true })
        setValue('tripNum', carrierCode + tripNum || '', { shouldDirty: true })
        setValue('expArrivalDate', arrivalDateSeparate || '', { shouldDirty: true })
        setValue('expArrivalTime', arrivalTimeSeparate || '', { shouldDirty: true })
        setValue('firstExpectedPort', firstExpectedPort || '', { shouldDirty: true })
        setValue('timeZone', timeZone || '', { shouldDirty: true })
        //setValue('templateFlag',templateFlag || '', { shouldDirty: true })
        //setValue('templateName',templateName || '', { shouldDirty: true })
        //setValue('status',status || '', { shouldDirty: true })
        setValue('messageFunction', messageFunction || '', { shouldDirty: true })
        //setValue('amendmentCode',amendmentCode || '', { shouldDirty: true })
        setValue('saveTruckFlag', saveTruckFlag || '', { shouldDirty: true })
        setValue('truckId', truckId || '', { shouldDirty: true })
        setValue('conveyanceType', conveyanceType || '', { shouldDirty: true })
        setValue('conveyanceNum', conveyanceNum || '', { shouldDirty: true })
        setValue('licenseNum', licenseNum || '', { shouldDirty: true })
        setValue('countryCode', countryCode || '', { shouldDirty: true })
        setValue('state', state || '', { shouldDirty: true })
        setValue('email', email || '', { shouldDirty: true })
        setValue('sealNo1', sealNo1 || '', { shouldDirty: true })
        setValue('sealNo2', sealNo2 || '', { shouldDirty: true })
        setValue('sealNo3', sealNo3 || '', { shouldDirty: true })
        setValue('sealNo4', sealNo4 || '', { shouldDirty: true })
        setValue('sealNo5', sealNo5 || '', { shouldDirty: true })
        setValue('sealNo6', sealNo6 || '', { shouldDirty: true })
        setValue('sealNo7', sealNo7 || '', { shouldDirty: true })
        setValue('sealNo8', sealNo8 || '', { shouldDirty: true })
        setValue('sealNo9', sealNo9 || '', { shouldDirty: true })
        setValue('sealNo10', sealNo10 || '', { shouldDirty: true })
        setValue('sealNo11', sealNo11 || '', { shouldDirty: true })
        setValue('sealNo12', sealNo12 || '', { shouldDirty: true })
        setValue('sealNo13', sealNo13 || '', { shouldDirty: true })
        setValue('sealNo14', sealNo14 || '', { shouldDirty: true })
        setValue('sealNo15', sealNo15 || '', { shouldDirty: true })
        setValue('sealNo16', sealNo16 || '', { shouldDirty: true })
        setValue('sealNo17', sealNo17 || '', { shouldDirty: true })
        setValue('sealNo18', sealNo18 || '', { shouldDirty: true })
        setValue('sealNo19', sealNo19 || '', { shouldDirty: true })
        setValue('sealNo20', sealNo20 || '', { shouldDirty: true })
        setValue('iit', iit || '', { shouldDirty: true })
        setValue('lvs', lvs || '', { shouldDirty: true })
        setValue('postal', postal || '', { shouldDirty: true })
        setValue('flyingTrucks', flyingTrucks || '', { shouldDirty: true })
        setValue('version', version || '', { shouldDirty: true })
        setValue('personDTO', personDTO || '', { shouldDirty: true })
        setValue('trailerDTO', trailerDTO || '', { shouldDirty: true })

        setOnChangeCarrierCode(true);
        setRefreshCarrierCodeAndTripNo(true);
        showNotification('success', 'Template applied successfully.')
        trigger()
      }
      // iitCodes = watch(['iitCode1', 'iitCode2', 'iitCode3', 'iitCode4']) //change from const to vat and set again here, but the collapsible section cannot refresh

    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID'
    },
    {
      field: 'templateName',
      title: 'TEMPLATE NAME'
    },
    {
      field: 'carrierCode',
      title: 'CARRIER CODE'
    },
    {
      field: 'firstExpectedPort',
      title: 'FIRST EXPECTED PORT'
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    },
    {
      field: 'createdDate',
      //sortKey: 'CREATED DATE',
      title: 'CREATED DATE',
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      //sortKey: 'UPDATED DATE',
      title: 'UPDATED DATE',
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.status,
      value: 'status'
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'ASC' }]}
      fetch={{ url: AciHighwayManifestApiUrls.GET }}
      fetchFilters={
        [
          { field: 'templateFlag', operator: 'equal', value: true },
          { field: 'status', operator: 'equal', value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyManifestTemplate}
      onClose={() => setManifestTemplateDialog(false)}
      open={manifestTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title='Manifest template'
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOptionACIHWY