import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naAesStateCodeAutoCompleteMasterField from './NaAesStateCodeAutoCompleteMasterField'

function NaAesStateCodeAutoCompleteField(props) {
  return naAesStateCodeAutoCompleteMasterField(
    props,
    CodeMaintenanceType.STATE_CODE,
  )
}

export default NaAesStateCodeAutoCompleteField