import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

const LookupsContext = React.createContext()

const LookupsConsumer = LookupsContext.Consumer

function getKey(pathname) {
  return pathname.split('/').splice(1, 2).join('/')
}

const LookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const location = useLocation()

  async function initialize(promises, transform) {
    const lookupSessionStorage = sessionStorage.getItem('lookups')

    if (lookupSessionStorage) {
      const lookups = JSON.parse(lookupSessionStorage)
      const key = getKey(location.pathname)

      if (lookups?.[key]) {
        setLookups(lookups)
      } else {
        await fetchAndSaveLookups(promises, transform)
      }
    } else {
      await fetchAndSaveLookups(promises, transform)
    }
  }

  async function fetchAndSaveLookups(promises, transform) {
    const key = getKey(location.pathname)

    const requests = promises.map((promise) => {
      const { fetch, transform, url, ...rest } = promise.request

      return fetch.execute(url, rest, transform)
    })

    Promise.all(requests).then((responses) => {
      const result = responses.reduce(
        (acc, curr, index) => ({ ...acc, [promises[index]['key']]: curr }),
        {}
      )

      const transformedData = transform ? transform(result) : result
      const lookups = { [key]: transformedData }

      setLookups(lookups)
      updateSessionStorage(lookups)
    })
  }

  function updateSessionStorage(lookups) {
    const lookupSessionStorage = sessionStorage.getItem('lookups')

    if (!lookupSessionStorage) {
      sessionStorage.setItem('lookups', JSON.stringify(lookups))
    } else {
      const existingLookups = JSON.parse(lookupSessionStorage)

      sessionStorage.setItem(
        'lookups',
        JSON.stringify({ ...existingLookups, ...lookups })
      )
    }
  }

  function getLookupValue(name, value) {
    const key = getKey(location.pathname)

    if (!lookups?.[key]) return value

    return lookups[key]?.[name]?.[value] || value
  }

  return (
    <LookupsContext.Provider value={{ initialize, lookups, getLookupValue }}>
      {children}
    </LookupsContext.Provider>
  )
}

export default LookupsProvider

export { LookupsContext, LookupsConsumer }
