import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naCountryCodeAutocompleteField from './NaCountryCodeAutocompleteField'

function NaNbciCountryCodeAutocompleteField(props) {
  return naCountryCodeAutocompleteField(
    props,
    CodeMaintenanceType.NA_COUNTRY_CODE,
    "cci"
  )
}

export default NaNbciCountryCodeAutocompleteField