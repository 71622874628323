const ConveyanceKeys = {
  TITLE: "title",
  MESSAGE_FUNCTION: "messageFunction",
  MSG_TYPE: "msgType",
  MESSAGE_TYPE: "messageType",
  FORM_TYPE: "formType",
  CONVEYANCE_REF_NO: "conveyanceRefNo",
  CARRIER_CODE: "carrierCode",
  FLIGHT_NO: "flightNo",
  AIR_CRAFT_TYPE: "airCraftType",
  AIR_CRAFT_REG_N_O: "airCraftRegNO",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  DEPARTURE_DATE_AND_TIME: "departureDateAndTime",
  DEPARTURE_PORT: "departurePort",
  ARRIVAL_DATE_AND_TIME: "arrivalDateAndTime",
  ARRIVAL_PORT: "arrivalPort",
  ARRIVAL_TERMINAL: "arrivalTerminal",
  REPORT_TYPE: "reportType",
  TEMPLATE_NAME: "templateName",
  TEMPLATE_FLAG: "saveAsTemplate",
  SCHEDULE_DATE_AND_TIME: "scheduleDateAndTime",
  SCHEDULED_SUBMISSION_FLAG: "scheduledSubmissionFlag",
  STATUS: "status",
  VIEW_CONVEYANCE: "viewConveyance",
  EDIT_CONVEYANCE: "editConveyance",
  ADD_CONVEYANCE: "addConveyance",
  CRN_HELP_TEXT: "crnHelpText",
  ACTIVITY_LOGS: "activityLogs",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  ERROR_TEXT_LABEL: "errorTextLabel",
  CONVEYANCE_ROUTER_HELPER_TEXT:"conveyanceRouteHelperText",
  ConveyanceRouteDTOs: {
    TITLE: "conveyanceRouteDTOs.title",
    LOCATION_CODE: "conveyanceRouteDTOs.locationCode"
  },
  ConveyanceStatusNotifyList: {
    TITLE: "conveyanceStatusNotifyList.title",
    MSG_TYPE: "conveyanceStatusNotifyList.msgType",
    NAME: "conveyanceStatusNotifyList.name",
    EMAIL: "conveyanceStatusNotifyList.email",
    PHONE_NUMBER: "conveyanceStatusNotifyList.phoneNumber",
    STATUS_TYPE: "conveyanceStatusNotifyList.statusType"
  },
  Response:{
    TITLE: "response.responseMessage",
    TAB_LOCKED: "response.tab"
  }
}

export default ConveyanceKeys
