import React from 'react'
import clsx from 'clsx'
import { useCheckboxStyles } from '../../../theme/upsComponents'

function IndeterminateIcon() {
  const checkboxClasses = useCheckboxStyles()

  return (
    <span
      className={clsx(checkboxClasses.icon, checkboxClasses.indeterminateIcon)}
    />
  )
}

export default IndeterminateIcon
