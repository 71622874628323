const AnnouncementKeys = {
  TITLE: "title",
  ID: "id",
  CONTENT: "content",
  START_DATE: "startDate",
  END_DATE: "endDate",
  PRIORITY: "priority",
  ACTIVE: "active",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version",
  PORTAL_ANNOUNCEMENT: "portalAnnouncement",
  CLOSE_BUTTON: "closeButton",
  SPECIAL_INSTRUCTION: "specialInstruction",
  AnnouncementWhiteLabelGroup: {
    TITLE: "announcementWhiteLabelGroup.title",
    HEADER_ID: "announcementWhiteLabelGroup.headerId",
    WHITE_LABEL_GROUP: "announcementWhiteLabelGroup.whiteLabelGroup"
  },
  Validations:{ 
    ERROR_MSG_ALPHA_NUMERIC : "validations.errMsgAlphaNumeric",
    ERROR_MSG_NUMERIC : "validations.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH : "validations.errMsgMaxLength"
  }
}

export default AnnouncementKeys
