import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { default as MuiAlert } from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    padding: '0 16px',
    width: (props) => props?.fullWidth ? '100%' : 'auto', 
    '&.MuiAlert-standardInfo': {
      color: theme.palette.component.bannerAlert.displayText || theme.palette.text.secondary,
      backgroundColor: theme.palette.component.bannerAlert.bannerFill || theme.palette.grey[100],
      '& .MuiAlert-icon': {
        alignItems: 'center',
        color: theme.palette.component.bannerAlert.actionIconFill || theme.palette.grey[500],
        padding: '8px 0',
      },
      '& .MuiAlert-message': {
        padding: '8px 0',
        fontSize: 12
      }
    }
  }
}))

// Created this component to customize Mui's alert component, e.g.: colors, etc.
function Alert(props) {
  const { fullWidth, ...otherProps } = props
  const classes = useStyles({ fullWidth })

  return <MuiAlert className={classes.root} {...otherProps} />
}

export default Alert
