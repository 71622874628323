const AciHighwayKeys = {
  TITLE: "title",
  CCN: "ccn",
  ENTRY_NO: "entryNo",
  WAREHOUSE_CODE: "warehouseCode",
  STATUS: "status",
  ARRIVAL_DATE_TIME: "arrivalDateTime",
  CREATE_DATE_TIME: "createDateTime",
  SUBMIT_DATE_TIME: "submitDateTime",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  CREATIONDATETIME: "createdDateTime",
  ALPHA_NUMERIC_REGX:"alphaNumericRegx", 
  MAX_LENGTH_25:"maxLength25",
  MAX_LENGTH_35:"maxLength35",
  ENTRY_NO_REQ: "entryNumReq",
  CCN_REQ: "ccnReq",
  ONLY_ONE_REQ: "onlyOneReq"
}

export default AciHighwayKeys
