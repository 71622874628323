import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naStateCodeAutoCompleteMasterField from './NaStateCodeAutoCompleteMasterField'

function NaStateCodeAutoCompleteField(props) {
  return naStateCodeAutoCompleteMasterField(
    props,
    CodeMaintenanceType.STATE_CODE,
  )
}

export default NaStateCodeAutoCompleteField