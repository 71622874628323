const HSCodeKeys = {
  TITLE: "title",
  INTLCODE: "intlcode",
  LANGUAGE: "language",
  INDICATOR: "indicator",
  SUBHEADING_ID: "subheadingId",
  DESCRIPTION_ENG: "descriptionEng",
  DESCRIPTION_OTH: "descriptionOth",
  UOM_ID: "uomId",
  HS_CATEGORY_ID: "hsCategoryId"
}

export default HSCodeKeys
