const DANGEROUS_GOODS = 'DangerousGoodsCode'
const AIRPORT='AirPortCode'
const AIRCRAFT_TYPE='AircraftTypeCode'
const TERMINAL='TerminalName'
const SUBLOCATION='Sublocation'
const CUSTOMS_OFFICE='CustomsOffice'
const CARRIER='CarrierCode'
const HSCODE='HSCode'
const PARTY_MASTER="PartyMaster"
const PRODUCT_MASTER="ProductMaster"
const DOC_PORT="DocPortCode"
const STATE_CODE="CountryStateCode"
const FIRM_CODES="FirmCode"
const SBCI_INVOICE="SbciInvoiceBaseDTO"
const UOM_CODE="UOMCode"
const NA_COUNTRY_CODE="NACountryCode"

export {
  DANGEROUS_GOODS,
  AIRPORT,
  AIRCRAFT_TYPE,
  TERMINAL,
  SUBLOCATION,
  CUSTOMS_OFFICE,
  CARRIER,
  HSCODE,
  PARTY_MASTER,
  PRODUCT_MASTER,
  DOC_PORT,
  STATE_CODE,
  FIRM_CODES,
  SBCI_INVOICE,
  UOM_CODE,
  NA_COUNTRY_CODE
}
