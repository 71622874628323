import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles
} from '@material-ui/core'

const {
  form: {
    field: { CngSwitchField }
  }
} = components

const useStyles = makeStyles((theme) => ({
  label: {
    borderColor:
      theme.palette.component.textFormControl.normalBorder ||
      theme.palette.divider,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    margin: 0,
    minHeight: 48,
    padding: '8px 16px',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      width: '100%'
    },
    '& > .MuiSwitch-root': {
      margin: 0
    }
  }
}))

function Switch(props) {
  const { error, fullWidth = false, label, ...otherProps } = props

  const ref = useRef(null)
  const classes = useStyles()

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <FormControlLabel
        className={classes.label}
        control={<CngSwitchField inputRef={ref} {...otherProps} />}
        label={label}
        labelPlacement='start'
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Switch
