import React from 'react'
import {
  Typography
} from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'

function TemplateSelectDialogWithOptionEHBL(props) {
  const {
    manifestTemplateDialog,
    setManifestTemplateDialog,
    setConfirmDialog,
    showNotification,
    translatedTextsObject,
    setLicensePlateCount,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location, 
    title
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()

  function handleApplyManifestTemplate(template) {
    if (template) {
      const {
        manifestId,
        corpid,
        movementType,
        indicatorMode,
        primaryCcn,
        carrierCode,
        ccn,
        portOfDischargeCode,
        portOfDestCode,
        messageFuncCode,
        consolidationInd,
        totalWeight,
        status,
        closeStatus,
        responseDatetime,
        serviceBureauId,
        upsClientId,
        userRole,
        email,
        mobileNo,
        totalWeightUom,
        shipperName,
        consigneeName,
        entryNo,
        amendmentCode,
        ucn,
        b2bComment,
        destSubLocCode,
        discSubLocCode,
        undgContactName,
        undgContactNo,
        specialInstruction,
        isDangerousGoods,
        hanInstruction,
        volume,
        volumeUom,
        containerizedInd,
        mfInd,
        mfDatetime,
        businessName,
        freightFwdCode,
        uniqueRefNo,
        clMsgInd,
        prevCcn,
        noticeReasonCode,
        houseRefNo,
        usPortOfExit,
        usPortOfExitDesc,
        frnPortOfLoading,
        frnPortOfLoadingDesc,
        remarks,
        printDate,
        accountOff,
        storageDate,
        partyId,
        submittedBy,
        submittedDate,
        templateName,
        modeOfSubmission,
        containerDetails,
        tradeParty,
        productDetails,
        statusNotifyParties
      } = template

      //var arrivalTimeSeparate = moment(arrivalDate).tz("Canada/Eastern").format('HH:mm');
      //var arrivalDateSeparate = moment(arrivalDate).tz("Canada/Eastern").format('YYYY-MM-DD');

      if(isTable){

        let tradePartyValue =  tradeParty.map(x=> {
          return {
            ...x,
            id:null,
            manifestId:null,
            hblId:null
          }
      })

      let containerDetailsValue =  containerDetails.map(x=> {
          return {
            ...x,
            id:null,
            manifestId:null,
            hblId:null
          }
      })

      let productDetailsValue =  productDetails.map(x=> {
          return {
            ...x,
            id:null,
            manifestId:null,
            hblId:null
          }
      })

      let statusNotifyPartiesValue =  statusNotifyParties.map(x=> {
          return {
            ...x,
            id:null,
            manifestId:null,
            hblId:null
          }
      })

        var templateJson = {
          "baseFilterDTO": null,
            "errorMessages": null,
            "customData": null,
            "id": null,
            "version": 0,
            "manifestId": null,
            "corpid": null,
            "movementType": movementType,
            "indicatorMode": indicatorMode,
            "primaryCcn": primaryCcn,
            "carrierCode": carrierCode,
            "ccn": ccn,
            "portOfDischargeCode": portOfDischargeCode,
            "portOfDestCode": portOfDestCode,
            "messageFuncCode": null,
            "consolidationInd": consolidationInd,
            "totalWeightString": null,
            "totalWeight": totalWeight,
            "status": "DR",
            "closeStatus": null,
            "responseDatetime": null,
            "serviceBureauId": null,
            "respEdiPath": null,
            "upsClientId": null,
            "userRole": null,
            "email": null,
            "mobileNo": null,
            "totalWeightUom": totalWeightUom,
            "shipperName": null,
            "consigneeName": null,
            "entryNo": null,
            "amendmentCode": null,
            "ucn": null,
            "b2bComment": b2bComment,
            "destSubLocCode": destSubLocCode,
            "discSubLocCode": discSubLocCode,
            "undgContactName": undgContactName,
            "undgContactNo": undgContactNo,
            "specialInstruction": specialInstruction,
            "isDangerousGoods":isDangerousGoods,
            "hanInstruction": hanInstruction,
            "volume": volume,
            "volumeUom": volumeUom,
            "containerizedInd": containerizedInd,
            "mfInd": null,
            "mfDatetime": null,
            "businessName": businessName,
            "freightFwdCode": freightFwdCode,
            "uniqueRefNo": null,
            "clMsgInd": null,
            "prevCcn": prevCcn,
            "noticeReasonCode": null,
            "houseRefNo": houseRefNo,
            "usPortOfExit": usPortOfExit,
            "usPortOfExitDesc": null,
            "frnPortOfLoading": frnPortOfLoading,
            "frnPortOfLoadingDesc": null,
            "remarks": remarks,
            "printDate": printDate,
            "accountOff": accountOff,
            "storageDate": storageDate,
            "partyId": partyId,
            "submittedBy": null,
            "submittedDate": null,
            "templateName": null,
            "modeOfSubmission": "UI",
            "oriCreatedBy": null,
            "oriUpdatedBy": null,
            "oriSubmittedBy": null,
            "templateFlag": null,
            "isFTP": null,
            "validationErrorList": null,
            "transactionId": null,
            "tradeParty": tradePartyValue,
            "containerDetails": containerDetailsValue,
            "productDetails": productDetailsValue,
            "statusNotifyParties": statusNotifyPartiesValue
        }

        history.push(`${location.pathname}/add`, templateJson)

      }else{

        let isDangerousGoodsInput = isDangerousGoods == "Y" ? true : false
        let containerizedIndInput = containerizedInd == "Y" ? true : false
        let consolidationIndInput = consolidationInd == "Y" ? true : false

        setValue('movementType',movementType || '', { shouldDirty: true })
        setValue('indicatorMode',indicatorMode || '', { shouldDirty: true })
        setValue('primaryCcn',primaryCcn || '', { shouldDirty: true })
        setValue('carrierCode',carrierCode || '', { shouldDirty: true })
        setValue('ccn',ccn || '', { shouldDirty: true })
        setValue('portOfDischargeCode',portOfDischargeCode || '', { shouldDirty: true })
        setValue('portOfDestCode',portOfDestCode || '', { shouldDirty: true })
        setValue('consolidationInd',consolidationIndInput || '', { shouldDirty: true })
        setValue('totalWeight',totalWeight || '', { shouldDirty: true })
        setValue('totalWeightUom',totalWeightUom || '', { shouldDirty: true })
        setValue('destSubLocCode',destSubLocCode || '', { shouldDirty: true })
        setValue('discSubLocCode',discSubLocCode || '', { shouldDirty: true })
        setValue('undgContactName',undgContactName || '', { shouldDirty: true })
        setValue('undgContactNo',undgContactNo || '', { shouldDirty: true })
        setValue('specialInstruction',specialInstruction || '', { shouldDirty: true })
        setValue('isDangerousGoods',isDangerousGoodsInput || '', { shouldDirty: true })
        setValue('hanInstruction',hanInstruction || '', { shouldDirty: true })
        setValue('volume',volume || '', { shouldDirty: true })
        setValue('volumeUom',volumeUom || '', { shouldDirty: true })
        setValue('containerizedInd',containerizedIndInput || '', { shouldDirty: true })
        setValue('freightFwdCode',freightFwdCode || '', { shouldDirty: true })
        setValue('usPortOfExit',usPortOfExit || '', { shouldDirty: true })
        setValue('frnPortOfLoading',frnPortOfLoading || '', { shouldDirty: true })
        setValue('remarks',remarks || '', { shouldDirty: true })
        setValue('printDate',DateTimeFormatter.toClientDate(printDate) || '', { shouldDirty: true })
        setValue('accountOff',accountOff || '', { shouldDirty: true })
        setValue('storageDate',DateTimeFormatter.toClientDate(storageDate) || '', { shouldDirty: true })

        setValue('b2bComment',b2bComment || '', { shouldDirty: true })
        setValue('prevCcn',prevCcn || '', { shouldDirty: true })
        setValue('houseRefNo',houseRefNo || '', { shouldDirty: true })
        setValue('businessName',businessName || '', { shouldDirty: true })

        let tradePartyValue =  tradeParty.map(x=> {
            return {
              ...x,
              id:null,
              manifestId:null,
              hblId:null
            }
        })

        let containerDetailsValue =  containerDetails.map(x=> {
            return {
              ...x,
              id:null,
              manifestId:null,
              hblId:null
            }
        })

        let productDetailsValue =  productDetails.map(x=> {
            return {
              ...x,
              id:null,
              manifestId:null,
              hblId:null
            }
        })

        let statusNotifyPartiesValue =  statusNotifyParties.map(x=> {
            return {
              ...x,
              id:null,
              manifestId:null,
              hblId:null
            }
        })

        setValue('tradeParty',tradePartyValue || '', { shouldDirty: true })
        setValue('containerDetails',containerDetailsValue || '', { shouldDirty: true })
        setValue('productDetails',productDetailsValue || '', { shouldDirty: true })
        setValue('statusNotifyParties',statusNotifyPartiesValue || '', { shouldDirty: true })
        
        showNotification('success', 'Template applied successfully.')
        trigger()
      }
      // iitCodes = watch(['iitCode1', 'iitCode2', 'iitCode3', 'iitCode4']) //change from const to vat and set again here, but the collapsible section cannot refresh

    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'primaryCcn',
      title: translatedTextsObject.primaryCcn
    },
    {
      field: 'ccn',
      title: translatedTextsObject.ccn
    },
    {
      field: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (data) =>
      data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [    
    {
      value: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      value: 'primaryCcn',
      title: translatedTextsObject.primaryCcn
    },
    {
      value: 'ccn',
      title: translatedTextsObject.ccn
    },
    {
      value: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      value: 'createdDate',
      title: translatedTextsObject.createdDate
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'ASC' }]}
      fetch={{ url: EhblHouseBillApiUrls.GET }}
      fetchFilters={
        [
          { field: 'status', operator: EQUAL, value: 'TP' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyManifestTemplate}
      onClose={() => setManifestTemplateDialog(false)}
      open={manifestTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title={title}
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOptionEHBL