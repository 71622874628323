const H2hSubFailsKeys = {
  TITLE: "title",
  ID: "id",
  H2H_ID: "h2hId",
  PO_NUMBER: "poNumber",
  WALLMART_ITEM_NO: "wallmartItemNo",
  FILE_TYPE: "fileType",
  PROCESS_STATUS: "processStatus",
  RECEIVED_DATE: "receivedDate",
  RECEIVED_FILE_NAME: "receivedFileName",
  ACKNOWLEDGED_FILE_NAME: "acknowledgedFileName",
  REJ_ERR_MSG: "rejErrMsg"
}

export default H2hSubFailsKeys
