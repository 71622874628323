const FaxTrackerKeys = {
  TITLE: "title",
  USER_ID: "userId",
  FAX_RECEIVED_DATE: "faxReceivedDate",
  NOTIFICATION: "notification",
  DRIVER_EMAIL: "driverEmail",
  DRIVER_PHONE_NO: "driverPhoneNo",
  FAX_TYPE: "faxType",
  CARRIER_SCAC: "carrierSCAC",
  CARRIER_CODE: "carrierCode",
  TRIP_NO: "tripNo",
  EXPECTED_ARRIVAL_DATE: "expectedArrivalDate",
  ETA: "eta",
  TRANSIT_INDICATOR: "transitIndicator",
  FIRST_PORT: "firstPort",
  NO_OF_PAGES: "noOfPages",
  NO_OF_SHIPMENTS: "noOfShipments",
  FWD_TO_BROKER: "fwdToBroker",
  BROKER_NAME: "brokerName",
  BROKER_FAX: "brokerFax",
  BROKER_EMAIL: "brokerEmail",
  REMARKS: "remarks",
  STATUS: "status",
  FILER_USER_ID: "filerUserId",
  FAX_USER_ID: "faxUserId",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  EDIT_BUTTON: "editButton",
  DELETE_BUTTON: "deleteButton",
  VIEW_BUTTON: "viewButton",
  Validations:{
    MIN_LENGTH: "validations.minLength",
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumeric",
    EMAIL: "validations.email",
    TRIP_NUM_PREFIX: "validations.tripNumPrefix",
    NUMERIC: "validations.numeric",
  }
}

export default FaxTrackerKeys
