const UserNamePasswordKeys = {
    CHANGE_PASSWORD: "changePassword",
    SECURITY_QUESTION: "securityQuestion",
    SECURITY_ANSWER: "securityAnswer",
    RESET_PASSWORD: "resetPassword",
    SECURITY_QUESTION_ANSWER: "securityQuestionAnswer",
    SECURITY_QUESTION_ANSWER_UPDATED: "securityQuestionAnswerUpdated",
    SET_SECURITY_QUESTION: "setSecurityQuestion",
    GET_SECURITY_QUESTION: "getSecurityQuestion",
    UPDATE_SECURITY_QUESTION: "updateSecurityQuestion",
    USER_ID: "userId",
    EMAIL_ADDRESS: "emailAddress",
    PASSWORD: "password",
    NEW_PASSWORD: "newPassword",
    CONFIRM_NEW_PASSWORD: "confirmNewPassword",
    SET_NEW_PASSWORD: "setNewPassword",
    FORGOT_PASSWORD: "forgotPassword",
    CANCEL: "cancel",
    REQUESTORNAME: "requestorName",
    HELP_TEXT_1: "helpText1",
    HELP_TEXT_2: "helpText2",
    HELP_TEXT_3: "helpText3",
    HELP_TEXT_4: "helpText4",
    HELP_TEXT_5: "helpText5",
    HELP_TEXT_6: "helpText6",
    ERROR_MESSAGE_1: "errorMessage1",
    ERROR_MESSAGE_2: "errorMessage2",
    ERROR_MESSAGE_3: "errorMessage3",
    ERROR_MESSAGE_4: "errorMessage4",
    ERROR_MESSAGE_5: "errorMessage5",
    ERROR_MESSAGE_6: "errorMessage6",
    ERROR_MESSAGE_7: "errorMessage7",
    ERROR_MESSAGE_8: "errorMessage8",
    ERROR_MESSAGE_9: "errorMessage9",
    ERROR_MESSAGE_10: "errorMessage10",
    ERROR_MESSAGE_11: "errorMessage11",
    ERROR_MESSAGE_12: "errorMessage12",
    ERROR_MESSAGE_13: "errorMessage13",
    ERROR_MESSAGE_14: "errorMessage14",
    ERROR_MESSAGE_15: "errorMessage15",
    ERROR_MESSAGE_16: "errorMessage16",
    ERROR_MESSAGE_17: "errorMessage17",
    SUBMIT_BUTTON: "submit",
    SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
    SUBMIT_ERROR_MESSAGE: "submitErrorMessage"
  }
  
export default UserNamePasswordKeys
  