import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import PropTypes from 'prop-types'
import naWareHouseCodeWithoutCodeDescAutoCompleteField from './NaWareHouseCodeWithoutCodeDescAutoCompleteField'

function NaSublocationWithoutCodeAutocompleteField(props) {
  return naWareHouseCodeWithoutCodeDescAutoCompleteField(
    props,
    CodeMaintenanceType.SUBLOCATION
  )
}

export default NaSublocationWithoutCodeAutocompleteField