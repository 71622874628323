import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import  NaCodeMaintenanceApiURLs  from '../../../../apiUrls/NaCodeMaintenanceApiUrls'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngServerSideAutocompleteField
    },
  },
  CngGridItem,
} = components

function NaUSPortOfExitAutocompleteField(props) {
  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={true}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['code', 'description']}
      lookupPageableProps={{
        customData: { codeMType: CodeMaintenanceType.DOC_PORT },
        url: NaCodeMaintenanceApiURLs.GET,
        label: (record)=> {
          return `${record.code};${record.descriptionEn.trim()}`
        },
        value: 'code',
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: true
          },
          {
            field: 'set_indicator',
            operator: 'equal',
            value: 'UPS_POE'
          },
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

NaUSPortOfExitAutocompleteField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaUSPortOfExitAutocompleteField