import React from 'react'
import { Grid, Box, Card, makeStyles, Typography } from '@material-ui/core'
import HelperText from '../../views/common/HelperText'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  section: {
    borderRadius: theme.shape.borderRadius,
    '& .MuiCardHeader-title': {
      fontWeight: 700
    }
  },
  header: {
    backgroundColor:
      theme.palette.background?.sectionOddBg || theme.palette.grey[200]
  }
}))

function CngSection(props) {
  const { title, subheader, action, children, helperText, ...otherProps } =
    props

  const classes = useStyles()

  return (
    <Card className={classes.section} variant='outlined' {...otherProps}>
      <Box className={classes.header} padding='12px 16px'>
        <Grid alignItems='center' container spacing={2}>
          {helperText && (
            <Grid item xs='auto'>
              <HelperText helperText={helperText} />
            </Grid>
          )}
          <Grid item xs>
            {title && _.isString(title) ? (
              <Typography variant='h5' style={{ fontWeight: 600 }}>
                {title}
              </Typography>
            ) : (
              title
            )}
            {subheader && _.isString(subheader) ? (
              <Typography variant='body2'>{subheader}</Typography>
            ) : (
              subheader
            )}
          </Grid>
          {action && (
            <Grid item xs='auto'>
              {action}
            </Grid>
          )}
        </Grid>
      </Box>
      <Box padding={2}>{children}</Box>
    </Card>
  )
}

export default CngSection
