const AceHighwayKeys = {
  TITLE: "title",
  LEGEND: "legend",
  SHIPMENT_TYPE: "shipmentType",
  ENTRY_TYPE: "entryType",
  ENTRY_NO: "entryNo",
  STATUS: "status",
  SHIPPER_NAME: "shipperName",
  CONSIGNEE_NAME: "consigneeName",
  CREATED_DATE: "createdDate",
  SUBMISSION_DATE: "submissionDate",
  RESPONSE_DATE: "Response Date",
  MANIFEST_ID: "manifestId",
  SCN: "scn",
  ACQUITTAL_NO: "acquittalNo",
  POINT_OF_LOADING: "pointOfLoading",
  FDA_INDICATOR: "fdaIndicator",
  SPLIT_INDICATOR: "splitIndicator",
  SERVICE_TYPE: "serviceType",
  IN_BOND7512_NO: "inBond7512No",
  MASTER_IN_BOND_NO: "masterInBondNo",
  MEXICAN_PEDIMENTO: "mexicanPedimento",
  FOREIGN_DESTINATION: "foreignDestination",
  IN_BOND_DESTINATION: "inBondDestination",
  FIRMS_CODE: "firmsCode",
  EXIT_DATE: "exitDate",
  QUANTITY: "quantity",
  HIDE_TEXT: "hideText",
  SHOW_TEXT: "showText",
  FOOTER_TEXT: "footerText",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecords",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  CANCEL_BUTTON: "cancelButton",
  DELETE_BUTTON: "deleteButton",
  SUBMIT_BUTTON: "submitButton",
  UPLOAD_SHIPMENT_BUTTON: "uploadShipmentButton",
  SAVE_BUTTON: "saveButton",
  UPDATE_BUTTON: "updateButton",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  ATTACH_SHIPMENT_MSG: "attachShipmentMsg",
  ATTACH_SHIPMENT_SUCCESS_MSG: "attachShipmentSuccessMsg",
  ATTACH_BUTTON: "attachButton",
  ATTACH_SENT_RECORDS: "attachSentRecords",
  HEADER_ID: "headerId",
  DETACH_BUTTON: "detachButton",
  DETACH_MSG: "detachMsg",
  DETACH_SUCCESS_MSG: "detachSuccessMsg",
  DETACH_SENT_REC: "detachSentRecord",
  CANT_EDIT_ST_RECORD: "cantEditST",
  CANT_SUBMIT_FROM_VIEW_PAGE: "cantSubmitFromViewPage",
  CANT_SUBMIT_DIFF_SHIP_TYPE: "cantSubmitDiffShipType",
  CANT_DETACH_FROM_VIEW_PAGE: "cantDetachFromViewPage",
  MESSAGE_FUNCTION: "messageFunction",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_ERROR_MESSAGE: "submitErrorMessage",
  SUBMIT_EMPTY_AMENDMENT_CODE: "submitEmptyAmendmentCode",
  MESSAGE_FUNCTION_ADD: "msgFuncAdd",
  MESSAGE_FUNCTION_MODIFY: "msgFuncModify",
  MESSAGE_FUNCTION_CANCEL: "msgFuncCancel",
  MESSAGE_FUNCTION_DELINK: "msgFuncDelink",
  AMENDMENT_CODE: "amendmentCode",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  CUSTOMS_BROKER_NAME: "customsBrokerName",
  CUSTOMS_BROKER_CONTACT: "customsBrokerContact",
  CUSTOMS_BROKER: "customsBroker",
  BROKER_ENTRY_NO: "brokerEntryNo",
  ADDITIONAL_SHIPMENT: "additionalShipment",
  UPLOAD_XML_DILAOG_TITLE:"uploadXmlDialogTitle",
  UPLOAD_XML_BUTTON:"uploadXmlButton",
  UPLOAD_XML_SUCCESS_MSG:"uploadXmlSuccessMsg",
  UPLOAD_XML_ERROR_MSG:"uploadXmlErrorMsg",
  DISCARD_BUTTON:"discardButton",
  UPLOAD_XML_FILE_EMPTY_ERROR_MSG:"uploadXmlFileEmptyErrorMsg",
  MASTER_SHIPMENT_ID:"masterShipmentId",
  TRIP_NO: "tripNo",
  ARRIVAL_DATE: "arrivalDate",
  PartyInfo: {
    TITLE: "partyInfo.title",
    PARTY_DROP_DOWN: "partyInfo.partyList",
    PARTY_TYPE: "partyInfo.partyType",
    PARTY_IDENTIFIER: "partyInfo.partyIdentifier",
    NAME1: "partyInfo.name1",
    NAME2: "partyInfo.name2",
    ADDRESS1: "partyInfo.address1",
    ADDRESS2: "partyInfo.address2",
    ADDRESS3: "partyInfo.address3",
    CITY_NAME: "partyInfo.cityName",
    POSTAL_CODE: "partyInfo.postalCode",
    COUNTRY: "partyInfo.country",
    STATE: "partyInfo.state",
    TEL_NO: "partyInfo.telNo",
    EMAIL: "partyInfo.email",
    CTPAT_IND: "partyInfo.ctpatInd",
    PARTY_IDENTIFIER_CODE:"partyInfo.partyIdentifierCode",
    PARTY_IDENTIFIER_VALUE:"partyInfo.partyIdentifierValue",
    PARTY_IDENTIFIER_VALUE_NOTE:"partyInfo.partyIdentifierValueNote",
    PARTY_IDENTIFIER_VALUE_EIN:"partyInfo.partyIdentifierValueEIN",
    PARTY_IDENTIFIER_VALUE_SSN:"partyInfo.partyIdentifierValueSSN",
    PARTY_IDENTIFIER_VALUE_CAN:"partyInfo.partyIdentifierValueCAN",
    PORT_CODE:"partyInfo.portCode",
    ROUTING_CODE:"partyInfo.routingCode",
    BROKER_DOWNLOAD_REF_NO:"partyInfo.brokerDownloadRefNo",
    PORT_OF_LOADING:"partyInfo.portOfLoading",
    FILER_CODE:"partyInfo.filerCode",
    SAVE_FLAG:"partyInfo.saveFlag",
    PHONE_NO: "partyInfo.phoneNo",
    FAX_NO: "partyInfo.faxNo",
    BONDED_CARRIER_NAME: "partyInfo.bondedCarrierName"
  },
  GoodsDetails: {
    VIN_TITLE: "goodsDetails.vinTitle",
    TITLE: "goodsDetails.title",
    GOODS_DESCRIPTION: "goodsDetails.goodsDescription",
    NO_OF_PACKAGES: "goodsDetails.noOfPackages",
    NO_OF_PACKAGES_UOM: "goodsDetails.noOfPackagesUom",
    GROSS_WEIGHT: "goodsDetails.grossWeight",
    GROSS_WEIGHT_UOM: "goodsDetails.grossWeightUom",
    MONETARY_AMOUNT: "goodsDetails.monetaryAmount",
    COUNTRY_OF_ORIGIN: "goodsDetails.countryOfOrigin",
    HARMONIZED_CODE_TITLE: "goodsDetails.harmonizedCodeTitle",
    HARMONIZED_CODE1: "goodsDetails.harmonizedCode1",
    HARMONIZED_CODE2: "goodsDetails.harmonizedCode2",
    HARMONIZED_CODE3: "goodsDetails.harmonizedCode3",
    HARMONIZED_CODE4: "goodsDetails.harmonizedCode4",
    HARMONIZED_CODE5: "goodsDetails.harmonizedCode5",
    C4_CODE_TITLE: "goodsDetails.c4CodeTitle",
    C4_CODE1: "goodsDetails.c4Code1",
    C4_CODE2: "goodsDetails.c4Code2",
    C4_CODE3: "goodsDetails.c4Code3",
    C4_CODE4: "goodsDetails.c4Code4",
    C4_CODE5: "goodsDetails.c4Code5",
    MARKS_NO_TITLE: "goodsDetails.marksNoTitle",
    MARKS_NO1: "goodsDetails.marksNo1",
    MARKS_NO2: "goodsDetails.marksNo2",
    MARKS_NO3: "goodsDetails.marksNo3",
    MARKS_NO4: "goodsDetails.marksNo4",
    MARKS_NO5: "goodsDetails.marksNo5",
    VIN1: "goodsDetails.vin1",
    VIN2: "goodsDetails.vin2",
    VIN3: "goodsDetails.vin3",
    VIN4: "goodsDetails.vin4",
    VIN5: "goodsDetails.vin5",
    VIN6: "goodsDetails.vin6",
    VIN7: "goodsDetails.vin7",
    VIN8: "goodsDetails.vin8",
    VIN9: "goodsDetails.vin9",
    VIN10: "goodsDetails.vin10",
    VIN11: "goodsDetails.vin11",
    VIN12: "goodsDetails.vin12",
    VIN13: "goodsDetails.vin13",
    VIN14: "goodsDetails.vin14",
    VIN15: "goodsDetails.vin15",
    VIN16: "goodsDetails.vin16",
    VIN17: "goodsDetails.vin17",
    VIN18: "goodsDetails.vin18",
    VIN19: "goodsDetails.vin19",
    VIN20: "goodsDetails.vin20"
  },
  DangerousGoods: {
    TITLE: "dangerousGoods.title",
    GOODS_DETAILS: "dangerousGoods._goodsDetailsId",
    UNDG_CODE: "dangerousGoods.undgCode",
    UNDG_CONTACT_NAME: "dangerousGoods.undgContactName",
    UNDG_CONTACT_NO: "dangerousGoods.undgContactNo"
  },
  Validations:{
    MIN_LENGTH: "validations.minLength",
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumeric",
    ALPHANUMERIC_SPECIAL_CHAR: "validations.alphaNumericSpecialChar",
    NUMERIC: "validations.numeric",
    EMAIL_VALID:"validations.emailValid",
  },
  ATTACH_SHIPMENT_SECTION_HELPER: "attachShipmentSectionHelper",
  SHIPMENT_HEADER_SECTION_HELPER: "shipmentHeaderSectionHelper",
  PARTY_SECTION_HELPER: "partySectionHelper",
  GOODS_SECTION_HELPER: "goodsSectionHelper"
}

export default AceHighwayKeys
