const AceOcnVesselArrivalResponseKeys = {
  TITLE: "title",
  OCN_VESSEL_ARRIVAL_ID: "ocnVesselArrivalId",
  TRANSACTION_ID: "transactionId",
  ENTITY_NO: "entityNo",
  CBP_PORT: "cbpPort",
  MNF_SEQ_NO: "mnfSeqNo",
  ERROR_MSG: "errorMsg",
  CARRIER_CODE: "carrierCode",
  TRANSMISSION_DATE: "transmissionDate",
  TOTAL_MNF_READ: "totalMnfRead",
  TOTAL_PORT_READ: "totalPortRead",
  TOTAL_BILL_READ: "totalBillRead",
  TOTAL_HOUSE_BILL_READ: "totalHouseBillRead",
  TOTAL_AMENDMENT_READ: "totalAmendmentRead",
  TOTAL_H01_RECORD_INPUT: "totalH01RecordInput",
  TOTAL_REJECT: "totalReject",
  TOTAL_ACCEPT: "totalAccept",
  TOTAL_RECORD_READ: "totalRecordRead",
  RECEIVED_DATE: "receivedDate",
  CBP_BATCH_NO: "cbpBatchNo",
  CBP_PROCESSED_DATE: "cbpProcessedDate",
  REMARKS: "remarks",
  CREATEDBY: "createdby",
  CREATED_DATE: "createdDate",
  UPDATEDBY: "updatedby",
  UPDATED_DATE: "updatedDate"
}

export default AceOcnVesselArrivalResponseKeys
