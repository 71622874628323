const AciHighwayResponse = {
  CARGO_RESPONSE: "cargoResponseTitle",
  MANIFEST_RESPONSE: "manifestResponseTitle",
  D4_RESPONSE: "d4ResponseTitle",
  STATUS: "status",
  RESPONSE_ID: "responseId",
  RESPONSE_DATE: "responseDate",
  TRIP_NO: "tripNo",
  RESPONSE_CODE: "responseCode",
  RESPONSE_DISPOSITION_CODE: "responseDispositionCode",
  RESPONSE_TEXT: "responseText",
  ENTRY_NO: "entryNo",
  ENTRY_TYPE: "entryType",
  CBSA_RESPONSE_DATE: "cbsaResponseDate",
  CCN: "ccn",
  SUBMISSION_TYPE: "submissionType",
  REMARKS: "remarks",
  ERRORCODE: "Error Code",
  REJECT_TYPE: "Reject Type",
  ERROR_DESC: "Error Description",
  RESPONSE_TEXT: "Response Text",
  SYS_DATE_TIME: "sysProcessDateTime",
  CBSA_REF_NO: "cbsaRefNo",
  CBSA_RES_DATE_TIME: "cbsaDateTime",
  VIEW: "view",
  NOTICE_REASON_CODE: "noticeReasonCode",
  PORT_OF_CLEARANCE: "portOfClearance",
  PORT_OF_REPORT: "portOfReport",
  PORT_OF_ARRIVAL: "portOfArrival",
  PORT_OF_DESTINATION: "portOfDestination",
  D4_NOTICE_TITLE: "d4NoticeTitle",
  RELEASE_NOTICE_TITLE: "releaseNoticeTitle",
  RECEIVED_DATE: "receivedDate",
  RESPONSE_DATE_VALUE: "responseDateValue",
  ENTRY_NUMBER: "entryNumber",
  RELEASE_DATE: "releaseDate",
  CUSTOMS_OFFICE_CODE: "customsOfficeCode",
  WAREHOUSE_CODE: "warehouseCode",
  RNS_MESSAGE: "rnsMessage",
  ACI_CARGO_STATUS: "aciCargoStatus",
  PRINT_MULTIPLE_RECORDS: "printMultipleRecords",
  PRINT_D4: "printD4",
  SUBMIT_ID:"submitId",
  METHOD:"method"
}

export default AciHighwayResponse