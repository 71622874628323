const SbciManifestKeys = {
  TITLE: "title",
  ID: "id",
  VERSION: "version",
  MANIFEST_NO: "manifestNo",
  CLIENT_CODE: "clientCode",
  FILER_CODE: "filerCode",
  FIRMS_CODE: "firmsCode",
  ARRIVAL_DATE: "arrivalDate",
  MANIFEST_DATE: "manifestDate",
  PORT_ENTRY_CODE: "portEntryCode",
  PORT_OF_ARRIVAL: "portOfArrival",
  DESCRIPTION: "description",
  TRANSPORT_MODE: "transportMode",
  LICENSE_PLATE_NO: "licensePlateNo",
  PERSON_IN_CHARGE: "personInCharge",
  TOTAL_ENTERED_VALUE: "totalEnteredValue",
  TOTAL_GROSS_WT: "totalGrossWt",
  TOTAL_NET_WT: "totalNetWt",
  TOTAL_FREIGHT_CHARGE: "totalFreightCharge",
  HAZMAT_IND: "hazmatInd",
  STATUS: "status",
  CORPID: "corpid",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  ACTIVITY_LOGS: "activity_logs",
  VIEW_MANIFEST: "view_manifest",
  EDIT_MANIFEST: "edit_manifest",
  DATE_TIME: "date_time",
  LOGIN_USER_ID: "login_user_id",
  ACTION_USER_ID: "action_user_id",
  FUNCTION_CODE: "function_code",
  AUDIT_DESC: "audit_desc",
  MODE: "mode",
  VIEW_ATTACH_INVOICES: "viewAttachInvoice",
  ATTACH_BUTTON: "attachButton",
  Response:{
    TITLE: "response.title",
    TAB_LOCKED: "response.tabLocked"
  },
  STEP_LABEL_MANIFEST: "stepLabelManifest",
  STEP_LABEL_ATTACH_INVOICES: "stepLabelAttachInvoices",
  TEMPLATE_NAME:"templateName",
  TEMPLATE_FLAG:"templateFlag",
  CLONE_ID:"cloneId",
  CLONE_BUTTON:"cloneButton",
  SUBMIT_BUTTON:"submitButton",
  SAVE_AS_TEMPLATE:"saveAsTemplate",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  EDIT_SENT_RECORDS : "editSentRecords",
  DELETE_SENT_RECORDS : "deleteSentRecords",
  EDIT_BUTTON : "editButton",
  DELETE_BUTTON : "deleteButton",
  VIEW_BUTTON : "viewButton",
  YES : "yes",
  NO : "no",
  TEMPLATE: "template",
  DISCARD_BUTTON: "discardButton",
  SAVE_AND_PROCEED_BUTTON: "saveAndProceedButton",
  UPDATE_BUTTON: "updateButton",
  BACK_BUTTON: "backButton",
  SAVE_AS_DRAFT_BUTTON: "saveAsDraftButton",
  SAVE_AND_SUBMIT_BUTTON: "saveAndSubmitButton",
  ATTACH_BUTTON: "attachButton",
  Validations:{
    DATE_TO_LATER_THAN_DATE_FROM : "validations.dateToLaterThanDateFrom",
    ERROR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR: "validations.errMsgAlphaNumericNoSpecialChar",
    ERROR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validations.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validations.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validations.errMsgMinLength",
    ERROR_MSG_ALPHA:"validations.errMsgAlpha",
    ERROR_MSG_DECIMAL1:"validations.errMsgDecimal1",
    ERROR_MSG_DECIMAL2:"validations.errMsgDecimal2",
    ERROR_MSG_DECIMAL3:"validations.errMsgDecimal3",
  }
}

export default SbciManifestKeys
