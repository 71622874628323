const ValidationErrorLogKeys = {
  TITLE: "title",
  PROC_TIME: "procTime",
  FLIGHT_NO: "flightNo",
  DOC_TYPE: "docType",
  TRANSPORT_ID: "transportId",
  PARN: "parn",
  ERROR_TYPE: "errorType",
  AciAirFedexValidationDTO: {
    TITLE: "aciAirFedexValidationDTO.title",
    SEQ_NO: "aciAirFedexValidationDTO.seqNo",
    ERROR_DESC: "aciAirFedexValidationDTO.errorDesc",
    ERROR_FILED: "aciAirFedexValidationDTO.errorFiled"
  }
}

export default ValidationErrorLogKeys
