import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  function getSize(size) {
    switch (size) {
      case 'small':
        return theme.spacing(3)
      case 'large':
        return theme.spacing(7)
      case 'medium':
      default:
        return theme.spacing(5)
    }
  }

  return {
    root: {
      width: (props) => getSize(props.size),
      height: (props) => getSize(props.size)
    }
  }
})

function CountryFlagIcon(props) {
  const { countryCode, size, ...otherProps } = props
  const classes = useStyles({ size })

  return (
    <Avatar
      src={`https://flagcdn.com/h240/${countryCode.toLowerCase()}.jpg`}
      className={classes.root}
      {...otherProps}
    />
  )
}

export default CountryFlagIcon

CountryFlagIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

CountryFlagIcon.defaultProps = {
  size: 'medium'
}
