import React from 'react'
import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup
} from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: (props) => (props.fullWidth ? 'flex' : 'inline-flex')
  },
  button: {
    flexGrow: (props) => (props.fullWidth ? 1 : 'unset'),
    textTransform: 'none'
  }
}))

function ToggleButtonGroup(props) {
  const { fullWidth = false, options, ...otherProps } = props

  const classes = useStyles({ fullWidth })

  return (
    <MuiToggleButtonGroup className={classes.root} {...otherProps}>
      {options.map((option, index) => (
        <ToggleButton
          className={classes.button}
          key={index}
          value={option.value}
          {...option.props}
        >
          {option.label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  )
}

export default ToggleButtonGroup
