const AesPartyScreeningKeys = {
  TITLE: "title",
  PARTY_NAME: "partyName",
  ADDRESS: "address",
  COUNTRY_CODE: "countryCode",
  SCREENING_BUTTON: "screeningButton",
  SOURCE: "source",
  START_DATE: "startDate",
  END_DATE: "endDate",
  PARTY_SCREENING_DETAILS: "partyScreeningDetails",
  ADDRESS_DETAILS: "addressDetails",
  SOURCE_INFORMATION_URL: "sourceInformationUrl",
  SOURCE_LIST_URL: "sourceListUrl",
  STANDARD_ORDER: "standardOrder",
  FEDERAL_REGISTER_NOTICE: "federalRegisterNotice",
  CITY: "city",
  STATE: "state",
  COUNTRY: "country",
  PROVIDE_AT_LEAST_ONE_SEARCH_CRITERIA: "provideAtLeastOneSearchCriteria",
  ERROR_SEARCH_PARTY_SCREENING_MSG: "errorSearchPartyScreeningMsg",
}

export default AesPartyScreeningKeys
