const AciAirKeys = {
    RESPONSE_MSG_TITLE: "responseMsgtitle",
    CCN: "responseCCN",
    CBSA_PROCESSING_DATE: "responseCBSAProcessingDate",
    ERROR_CODE: "responseErrorDetails",
    RECEIVED_DATE: "responseReceivedDate",
    STATUS: "status",
    ERRORCODE: "responseErrorCode",
    REJECT_TYPE: "responseRejectType",
    ERROR_DESC: "responseErrorDesc",
    RESPONSE_TEXT: "responseText",
    D4_TITLE: "responseD4Notices",
    SYSTEM_PROCESSED_DATE_TIME: "responseSystemDate",
    CBSA_REF_NO: "responseCBSARefNo",
    CBSA_PROCESSED_DATE_TIME: "responseResponseDate",
    REMARKS: "responseRemarks",
    VIEW: "responseView",
    DOCUMENT_TYPE: "responseDocType",
    MESSAGE_FUNCTION: "responseMessageFunction",
    ERROR_TEXT_LABEL: "errorTextLabel",
    ERROR_CODE_LABEL: "errorCodeLabel",
    REJECT_TYPE_LABEL: "rejectTypeLabel",
    ERROR_DESC_LABEL: "errorDescLabel",
    RESPONSE_TEXT_LABEL: "responseTextLabel",
    ERROR_INFO_LABEL: "errorInfoLabel"
  }
  
  export default AciAirKeys