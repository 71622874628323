import React from 'react'
import { useCheckboxStyles } from '../../../theme/upsComponents'

function Icon() {
  const checkboxClasses = useCheckboxStyles()

  return <span className={checkboxClasses.icon} />
}

export default Icon
