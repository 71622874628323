import React from 'react'
import { makeStyles, useTheme, Step, StepButton, StepIcon, StepLabel, Stepper as MuiStepper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    '& .MuiStepLabel-label': {
      fontWeight: 700
    }
  },
  root: {
    justifyContent: 'center',
    '& > .MuiStepConnector-root.MuiStepConnector-horizontal': {
      maxWidth: 100
    }
  },
  stepIcon: {
    fontSize: 16,
    height: 32,
    width: 32,
    '&.MuiStepIcon-active, &.MuiStepIcon-completed': {
      color: (props) =>
        props.color ? props.color.color : theme.palette.primary.main
    }
  }
}))

function StepIconComponent(props) {
  const classes = useStyles({ color: props.color })
  return <StepIcon classes={{ root: classes.stepIcon }} {...props} />
}

function Stepper(props) {
  const {
    activeStep,
    completed,
    onSetActiveStep = () => {},
    steps,
    stepIconColor
  } = props
  const theme = useTheme()
  const classes = useStyles()

  return (
    <MuiStepper activeStep={activeStep} className={[classes.root, theme.name]} nonLinear>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton
            completed={completed ? completed[index] : undefined}
            onClick={() => onSetActiveStep(index)}
          >
            <StepLabel
              className={classes.label}
              StepIconComponent={StepIconComponent}
              StepIconProps={{
                color: stepIconColor ? stepIconColor[index] : undefined,
                Icon: Step.icon
              }}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default Stepper
