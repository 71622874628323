import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'

const {
  form: {
    field: { CngCheckboxField }
  }
} = components

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: (props) =>
      props.checked ? theme.palette.grey[100] : theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: (props) =>
      props.direction === 'start' ? 'row-reverse' : 'row',
    '& > .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0
    },
    '& > .MuiFormHelperText-root': {
      display: 'none'
    }
  },
  body: {
    color: theme.palette.text.secondary,
    paddingLeft: (props) =>
      props.direction === 'start' ? theme.spacing(2) : 0,
    '& .MuiTypography-body2': {
      color: 'inherit',
      fontWeight: 600,
      lineHeight: 1.2
    },
    '& .MuiTypography-caption': {
      color: 'inherit',
      lineHeight: 1.2,
      marginTop: 4
    }
  }
}))

function CheckboxButton(props) {
  const { checked, label, labelPlacement = 'end', description, ...rest } = props

  const ref = useRef(null)
  const theme = useTheme()
  const classes = useStyles({
    checked: checked,
    direction: labelPlacement
  })

  return (
    <Box className={classes.root} onClick={() => ref.current.click()}>
      <CngCheckboxField
        inputRef={ref}
        onClick={(event) => event.stopPropagation()}
        style={{ padding: theme.spacing(2) }}
        checked={checked}
        {...rest}
      />
      <Box flexGrow={1}>
        <Box className={classes.body}>
          <Typography variant='body2'>{label}</Typography>
          {description && (
            <Typography component='div' variant='caption'>
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CheckboxButton
