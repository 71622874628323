import React from 'react'
import { Box, Card, CardHeader, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  section: {
    borderRadius: theme.shape.borderRadius,
    '& > .MuiCardHeader-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTypography-root': {
        fontWeight: 700
      }
    }
  }
}))

function CngSubSection(props) {
  const { title, action, children, ...otherProps } = props

  const classes = useStyles()

  return (
    <Card className={classes.section} variant='outlined' {...otherProps}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'body2' }}
        action={action}
      />
      <Box padding='12px 16px 16px'>{children}</Box>
    </Card>
  )
}

export default CngSubSection
