import  NaCodeMaintenanceApiURLs  from 'src/apiUrls/NaCodeMaintenanceApiUrls'

import { transformToLookupColumn } from '../utils/MaterialTableUtils'
import { useServices } from 'cng-web-lib'

function useNAFetchCodeMaintenanceLookup() {
  const { fetchRecords } = useServices()
  return (
    type,
    onError,
    filters = [],
    labelAccessor = 'descriptionEn',
    valueAccessor = 'code'
  ) => {
    return fetchRecords.execute(
      NaCodeMaintenanceApiURLs.GET,
      {
        customData: { codeMType: type },
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: 'true'
          },
          ...filters
        ]
      },
      (res) => {
        return transformToLookupColumn(
          res,
          'content',
          labelAccessor,
          valueAccessor
        )
      },
      (error) => {
        if (onError) {
          onError(error)
        }
        return []
      }
    )
  }
}

export default useNAFetchCodeMaintenanceLookup
