const ArrivalCertResponseKeys = {
  TITLE: "title",
  ID: "id",
  ARRIVAL_CERT_RESPONSE_ID: "arrivalCertResponseId",
  STATUS: "status",
  REMARKS: "remarks",
  RESPONSE_PATH: "responsePath",
  CCN: "ccn",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version"
}

export default ArrivalCertResponseKeys
