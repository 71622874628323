import React, { useEffect, useState, useRef } from 'react'
import { components, useServices } from 'cng-web-lib'
import CngField from 'src/components/cngcomponents/CngField'
import _ from 'lodash'
import {
  Box,
  Card,
  CircularProgress,
  Collapse,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  MenuList,
  Radio,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  ListItemIcon,
  withStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import TablePagination from './TablePagination'

const DEFAULT_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: 4,
    width: (props) => props.width || theme.breakpoints.values.sm
  }
}))(Paper)

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 24,
    height: 24,
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff, #fff 28%, transparent 32%)',
      content: '""'
    }
  },
  row: {
    '& > .MuiTableCell-root': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: 0,
      fontSize: 14,
      padding: '8px 16px'
    }
  },
  collapsibleRow: {
    borderBottom: 0,
    '& > .MuiTableCell-root': {
      padding: 0
    }
  },
  headerSortSelect: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 700,
      padding: '4px 24px 4px 0'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    backgroundColor: theme.palette.grey[200]
  },
  loader: {
    alignItems: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  scrollableContainer: {
    overflowX: 'auto', // Enable horizontal scrolling
    display: 'flex', // Ensure the container uses flexbox
    flexDirection: 'column', // Set the direction to column
    width: '100%', // Ensure the container takes the full width
  },
  tableWrapper: {
    minWidth: '100%', // Ensure the table takes at least the full width of its container
  },
  table: {
    marginTop: 0,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase',
        '& .MuiTypography-root': {
          cursor: 'default'
        }
      }
    }
  },
}))

function TemplateSelectDialog(props) {
  const {
    columns,
    extraFields,
    defaultSorts,
    fetch,
    fetchFilters = [],
    maxWidth = 'md',
    open,
    onApplyTemplate,
    onClose,
    search,
    sortOptions = [],
    title,
    rowActions,
    isTable
  } = props

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const tableContentRef = useRef(null)
  const topScrollBarRef = useRef(null)
  const bottomScrollBarRef = useRef(null)
  const tableContentWrapperRef = useRef(null)

  const [templates, setTemplates] = useState({ content: [], totalElements: 0, totalPages: 0 })
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [searchFlag, setSearchFlag] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [tableSorts, setTableSorts] = useState(defaultSorts || [])
  const { fetchPageableRecords } = useServices()
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      setPage(0)
      setItemsPerPage(10)
    } else {
      setSearchTerm(null)
    }
  }, [open])

  useEffect(() => {
    if (searchTerm != '') {
      setPage(0)
      setItemsPerPage(10)
    }
  }, [searchTerm])

  useEffect(() => {
    if (open) {
      setIsFetching(true)

      fetchPageableRecords.execute(
        fetch.url,
        {
          page: page,
          pageSize: itemsPerPage,
          filters:
            searchTerm !== ''
              ? [
                ...fetchFilters,
                {
                  field: search.field,
                  operator: search.operator,
                  value: searchTerm
                }
              ]
              : [...fetchFilters],
          sorts: tableSorts
        },
        (res) => {
          const { content, totalElements, totalPages } = res

          setTemplates({ content, totalElements, totalPages })
        },
        (error) => console.log(error),
        () => {
          setIsFetching(false)
        }
      )
    }
  }, [itemsPerPage, page, open, searchTerm, tableSorts])

  const debounceSearch = _.debounce((value) => setSearchTerm(value), 800)

  function handleHeaderSortChange(field, value) {
    const currentSort = [...tableSorts][0]

    setTableSorts([{ ...currentSort, [field]: value }])
  }

  function handleDiscard() {
    setSelectedTemplate(null)
    onClose()
  }

  function handleApplyTemplate() {
    onApplyTemplate(selectedTemplate)
    onClose()
  }

  function handleItemsPerPageChange(event) {
    setPage(0)
    setItemsPerPage(parseInt(event.target.value))
  }

  let content = (
    <Card variant='outlined'>
      <Box padding={2}>
        <Grid container alignItems='center' justify='space-between' spacing={3}>
          {searchFlag ? (
            <Grid item xs={12}>
              <FormControl fullWidth hiddenLabel>
                <TextField
                  autoFocus
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <FontAwesomeIcon icon={['fal', 'search']} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setSearchFlag(false)}
                          size='small'
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={['fal', 'times']}
                            size='sm'
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onChange={(event) => debounceSearch(event.target.value)}
                  placeholder='Search for any keyword'
                  defaultValue={searchTerm}
                />
              </FormControl>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm='auto'>
                <Typography variant='caption'>
                  {`${templates.totalElements} item(s)`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm>
                <Grid
                  container
                  alignItems='center'
                  justify='flex-end'
                  spacing={2}
                >
                  <Grid item xs='auto'>
                    <CngIconButton
                      size='small'
                      type='outlined'
                      icon={['fal', 'search']}
                      onClick={() => setSearchFlag(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box position='relative'>
        {/* <ScrollBar
          tableContentRef={tableContentRef}
          onScroll={(event) => {
            const { scrollLeft } = event.target

            bottomScrollBarRef.current.scrollLeft = scrollLeft
            tableContentWrapperRef.current.scrollLeft = scrollLeft
          }}
          ref={topScrollBarRef}
        /> */}
        <div className={classes.scrollableContainer}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column.title}</TableCell>
                  ))}
                  <TableCell align='right' />
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.content.length > 0 ? (
                  templates.content.map((datum) => (
                    <Row
                      columns={columns}
                      extraFields={extraFields}
                      key={datum.id}
                      onSelect={(template) => setSelectedTemplate(template)}
                      selectedTemplate={selectedTemplate}
                      template={datum}
                      rowActions={rowActions}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length + 2}>No records to display</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        {/* <ScrollBar
          className={classes.bottomScrollBar}
          tableContentRef={tableContentRef}
          onScroll={(event) => {
            const { scrollLeft } = event.target

            topScrollBarRef.current.scrollLeft = scrollLeft
            tableContentWrapperRef.current.scrollLeft = scrollLeft
          }}
          ref={bottomScrollBarRef}
        /> */}
      </Box>
      <Box padding={2}>
        <TablePagination
          count={templates.totalElements}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          onPageChange={setPage}
          page={page}
        />
      </Box>
      {isFetching && (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      )}
    </Card>
  )

  return (
    <CngDialog
      dialogContent={content}
      dialogTitle={title}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      dialogAction={
        <>
          <CngButton color='secondary' onClick={handleDiscard}>
            Discard
          </CngButton>
          <CngButton
            color='primary'
            disabled={!selectedTemplate}
            onClick={handleApplyTemplate}
          >
            {isTable ? 'Create' : 'Insert & populate'}
          </CngButton>
        </>
      }
      shouldShowCloseButton
    />
  )
}

export default TemplateSelectDialog

function Row(props) {
  const { columns, extraFields, onSelect, selectedTemplate, template, rowActions } = props

  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const [rowActionsPopover, setRowActionsPopover] = useState({
    anchorEl: null,
    rowData: null
  })

  return (
    <>
      <TableRow
        className={classes.row}
        selected={selectedTemplate?.id === template.id}
      >
        <TableCell padding='checkbox'>
          <Radio
            checked={selectedTemplate?.id === template.id}
            checkedIcon={<span className={classes.checkedIcon} />}
            color='primary'
            icon={<span className={classes.icon} />}
            onChange={() => onSelect(template)}
          />
        </TableCell>
        {columns.map((column, index) => (
          <TableCell key={index}>
            {column.title === 'Action' && (
              <Grid item xs='auto'>
                <CngIconButton
                  icon={['fal', 'ellipsis-h']}
                  onClick={(event) => {
                    event.stopPropagation()
                    setRowActionsPopover({
                      anchorEl: event.currentTarget,
                      rowData: template
                    })
                  }}
                  size='small'
                  type='outlined'
                />
              </Grid>
            )}
            {column.render
              ? column.render(template)
              : _.get(template, column.field)}
          </TableCell>
        ))}
        <TableCell align='right'>
          {extraFields && !_.isEmpty(extraFields) && (
            <CngIconButton
              icon={['fal', expanded ? 'chevron-up' : 'chevron-down']}
              onClick={() => setExpanded((prev) => !prev)}
              size='small'
              type='outlined'
            />
          )}
        </TableCell>
      </TableRow>
      {extraFields && !_.isEmpty(extraFields) && (
        <TableRow className={classes.collapsibleRow}>
          <TableCell />
          <TableCell colSpan={columns.length}>
            <Collapse in={expanded}>
              <Box padding={2}>
                <Grid container spacing={2}>
                  {extraFields.map((field, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      {...field.gridProps}
                    >
                      <CngField label={field.label}>
                        {field.render
                          ? field.render(template)
                          : _.get(template, field.field)}
                      </CngField>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
      <RowActionsPopover
        anchorEl={rowActionsPopover.anchorEl}
        open={rowActionsPopover.anchorEl ? true : false}
        onClose={() => setRowActionsPopover({ anchorEl: null })}
        rowActions={rowActions}
        rowData={template}
      />
    </>
  )
}

function RowActionsPopover(props) {
  const { anchorEl, onClose, open, rowActions, rowData } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width='auto'>
        <MenuList disablePadding>
          {!_.isEmpty(rowActions) &&
            rowData &&
            rowActions.map((action, index) => {
              const ActionMenuItem = () => (
                <MenuItem
                  disabled={action.disabled ? action.disabled(rowData) : false}
                  onClick={() => action.onClick(rowData)}
                >
                  {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                  <Typography variant='body2' style={{ fontWeight: 700 }}>
                    {action.label}
                  </Typography>
                </MenuItem>
              )

              return action.tooltip && action.tooltip(rowData) !== null ? (
                <Tooltip
                  key={index}
                  placement='top'
                  title={action.tooltip(rowData)}
                >
                  <span>
                    <ActionMenuItem />
                  </span>
                </Tooltip>
              ) : (
                <ActionMenuItem key={index} />
              )
            })}
        </MenuList>
      </StyledPopoverWrapper>
    </Popover>
  )
}

const ScrollBar = React.forwardRef((props, ref) => {
  const { onScroll, tableContentRef, ...otherProps } = props
  const [width, setWidth] = useState(0)

  // Timeout required to retrive correct offsetWidth
  useEffect(() => {
    setTimeout(() => { setWidth(tableContentRef.current?.offsetWidth) }, 300)
  }, [tableContentRef.current?.offsetWidth])

  return (
    <div
      style={{
        height: 20,
        overflowX: 'scroll',
        overflowY: 'hidden'
      }}
      onScroll={onScroll}
      ref={ref}
      {...otherProps}
    >
      <div style={{ width: width, height: '100%' }} />
    </div>
  )
})