const PartyMasterKeys = {
  TITLE: "title",
  ACCTID: "acctId",
  PARTYIDN: "partyidn",
  PARTYTYPE: "partytype",
  PARTYNAME1: "partyname1",
  PARTYNAME2: "partyname2",
  ABBR_NAME: "abbrName",
  ADDRESS1: "address1",
  ADDRESS2: "address2",
  CITYNAME: "cityname",
  STATECODE: "statecode",
  POSTALCODE: "postalcode",
  COUNTRYCODE: "countrycode",
  CONTACTPERSON: "contactperson",
  CONTACT_FIRST_NAME: "contactFirstName",
  CONTACT_LAST_NAME: "contactLastName",
  TELEPHONENUMBER: "telephonenumber",
  ACTIVE: "active",
  DBA:"dba",
  ADDRESS3:"address3",
  EMAIL:"email",
  CRNO:"crNo",
  FAXNo:"faxNo",
  DIVISION:"division",
  LOCPORTCODE:"locPortCode",
  SECONDARY_INFO_TYPE: "secondaryInfoType",
  SECONDARY_INFO: "secondaryInfo",
  POBOXNUMBER:"poBoxNumber",
  CAPATIND:"ctpatInd",
  PartyIdentifier: {
    TITLE: "PartyIdentifier.title",
    PARTYIDENTIFIERCODE:"PartyIdentifier.partyIdentifierCode",
    PARTYIDENTIFIER:"PartyIdentifier.partyIdentifier"
  }
}

export default PartyMasterKeys
