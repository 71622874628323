import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naPortAutocompleteField from './NaPortAutocompleteField'

function FirmCodesAutocompleteField(props) {
  return naPortAutocompleteField(
    props,
    CodeMaintenanceType.FIRM_CODES,
    "AMS"
  )
}

export default FirmCodesAutocompleteField