const FdapnKeys = {
  TITLE: "title",
  PN_HEADER: "pnHeader",
  UPDATE_BUTTON: "updateButton",
  SAVE_BUTTON: "saveButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_BUTTON: "editButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  DISCARD_BUTTON: "discardButton",
  VIEW_FDAPN: "viewFdaPN",
  EDIT_SENT_RECORDS: "editSentRecords",
  DELETE_RECORDS: "deleteRecords",
  ID: "id",
  CREATED_DATE_TIME: "createdDateTime",
  SUBMITTED_DATE_TIME: "submittedDateTime",
  ACCT_ID: "acctId",
  CORPID: "corpid",
  REFERENCE_QUALIFIER: "referenceQualifier",
  REFERENCE_ID_NUM: "referenceIdNum",
  PN_CONFIRMATION_NUMBER: "pnConfirmationNumber",
  ANTICIPATED_ARRIVAL_DATE: "anticipatedArrivalDate",
  ANTICIPATED_ARRIVAL_TIME: "anticipatedArrivalTime",
  ARRIVAL_LOCATION: "arrivalLocation",
  FILER_CODE: "filerCode",
  BILL_TYPE: "billType",
  CARRIER_CODE: "carrierCode",
  ENTRY_TYPE: "entryType",
  MOT: "mot",
  ENVELOPE_NUMBER: "envelopeNumber",
  ACTION_CODE: "actionCode",
  STATUS: "status",
  SUBMITTER_OF_ENTRY: "submitterOfEntry",
  RESPONSE_DATETIME: "responseDatetime",
  SN: "sn",
  BASKET_NUMBER: "basketNumber",
  ULTIMATE_CONSIGNEE: "ultimateConsignee",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  SAVE_PRODUCT_FLAG: "saveProductFlag",
  PRODUCT_IDENTIFICATION: "productIdentification",
  PRODUCT_ID_LOOKUP: "productIdLookup",
  RESPONSES: "responses",
  EDIT_FDAPN: "editFDAPN",
  NAME: "name",
  SN_TYPE: "snType",
  PHONE_NUMBER: "phoneNumber",
  EAMIL: "email",
  STATUS_NOTIFICATION: "statusNotification",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  RESPONSES_FDAPN: "ResponsesFdapn",
  RESPONSES_SN: "ResponsesSn",
  RESPONSE_ID: "ResponseId",
  RESPONSE_DATE: "ResponseDate",
  NARRATIVE_MSG_TYPE: "NarrativeMsgType",
  NARRATIVE_MSG: "NarrativeMsg",
  PROCESSING_DATE: "ProcessingDate",
  REMARKS: "Remarks",
  GOV_AGENCY_CODE: "GovAgencyCode",
  GOV_AGENCY_PROGRAM_CODE: "GovAgencyProgramCode",
  PGA_ENTRY_LEVEL_STATUS_CODE: "PgaEntryLevelStatusCode",
  STATUS_REASON_CODE: "StatusReasonCode",
  PGA_LINE: "PgaLine",
  REF_ID_QUALIFIER: "refIdQualifier",
  REF_ID_NUMBER: "refIdNumber",
  REF_ID_RECEIPT_DATE: "refIdReceiptDate",
  PGA_LINE_SUB_REASON_CODE: "pgaLineSubReasonCode",
  SAVE_PARTY_FLAG: "savePartyFlag",
  PARTY_IDENTIFICATION: "partyIdentification",
  PARTY_ID_LOOKUP: "partyIdLookup",
  PRODUCT_MASTER: "productMaster",
  UPLOAD_FDAPN: "uploadFDAPN",
  PRINT_FDAPN: "printFDAPN",
  SUBMIT_MULTIPLE_RECORDS: "submitMultiple",
  PROD_NUM_PNC: "productNumberAndPNC",
  Bol: {
    TITLE: "bol.title",
    BOL_HEADER_ID: "bol.bolHeaderId",
    BILL_TYPE: "bol.billType",
    BOL_ISSUER_CODE: "bol.bolIssuerCode",
    BOL_NUMBER: "bol.bolNumber",
    PN_CONFIRMATION_NUMBER: "bol.pnConfirmationNumber"
  },
  Pga: {
    TITLE: "pga.title",
    PGA_HEADER_ID: "pga.pgaHeaderId",
    COMMERCIAL_DESC: "pga.commercialDesc",
    GOV_AGENCY_PROCESSING_CODE: "pga.govAgencyProcessingCode",
    INTENDED_USE_CODE: "pga.intendedUseCode",
    INTENDED_USE_DESC: "pga.intendedUseDesc",
    PRODUCT_NUMBER: "pga.productNumber",
    REMARKS_CODE: "pga.remarksCode",
    REMARKS_TEXT: "pga.remarksText",
    LPCO_NUMBER: "pga.lpcoNumber",
    LPCO_ISSUER: "pga.lpcoIssuer",
    LPCO_ISSUER_REGIONAL_DESC: "pga.lpcoIssuerRegionalDesc",
    LPCO_GOV_GEOGRAPHIC_QUALIFIER: "pga.lpcoGovGeographicQualifier",
    LPCO_ISSUER_LOCATION: "pga.lpcoIssuerLocation",
    ANTICIPATED_ARRIVAL_DATE: "pga.anticipatedArrivalDate",
    ANTICIPATED_ARRIVAL_TIME: "pga.anticipatedArrivalTime",
    ARRIVAL_LOCATION: "pga.arrivalLocation",
    TOTAL_QUANTITY: "pga.totalQuantity",
    UOM: "pga.uom",
    PACKAGE_TRACKING_CODE: "pga.packageTrackingCode",
    PACKAGE_TRACKING_NUMBER: "pga.packageTrackingNumber",
    COMMODITY_CHARACTERISTIC_DESC: "pga.commodityCharacteristicDesc",
    COUNTRY_OF_SHIPMENT: "pga.countryOfShipment",
    COUNTRY_OF_PRODUCTION: "pga.countryOfProduction",
    PLACE_OF_GROWTH: "pga.placeOfGrowth",
    COUNTRY_OF_ENTRY_REFUSAL: "pga.countryOfEntryRefusal",
    BASE_UNIT_QUANTITY: "pga.baseUnitQuantity",
    BASE_UNIT_UOM: "pga.baseUnitUom",
    BASE_UNIT: "pga.baseUnit",
    PN_CONFIRMATION_NUMBER: "pga.pnConfirmationNumber",
    BASKET_NUMBER: "pga.basketNumber",
    PRODUCT_ID: "pga.productId",
    ADDITIONAL_PN_DETAILS: "pga.additionalPNDetails",
    AFFIRMATION_COMPLIANCE_DETAILS: "pga.affirmationOfComplianceDetails",
    PRODUCT_CONDITION_DETAILS: "pga.productConditionDetails",
  },
  Party: {
    TITLE: "party.title",
    PGA: "party._pgaId",
    PARTY_PGA_ID: "party.partyPgaId",
    PARTY_TYPE: "party.partyType",
    PARTY_ID_TYPE: "party.partyIdType",
    PARTY_ID_TYPE_VALUE: "party.partyIdTypeValue",
    NAME: "party.name",
    ADDRESS1: "party.address1",
    ADDRESS2: "party.address2",
    ADDRESS3: "party.address3",
    ADDRESS4: "party.address4",
    ADDRESS5: "party.address5",
    APARTMENT_SUITE_NUMBER: "party.apartmentSuiteNumber",
    CITY_NAME: "party.cityName",
    STATE_PROVINCE: "party.stateProvince",
    POSTAL_CODE: "party.postalCode",
    COUNTRY_CODE: "party.countryCode",
    CONTACT_FIRST_NAME: "party.contactFirstName",
    CONTACT_PHONE_NUMBER: "party.contactPhoneNumber",
    EMAIL: "party.email",
  },
  AddParty: {
    TITLE: "addParty.title",
    PARTY: "addParty._partyId",
    PN_PARTY_ID: "addParty.pnPartyId",
    NAME: "addParty.name",
    ADDRESS1: "addParty.address1",
    ADDRESS2: "addParty.address2",
    ADDRESS3: "addParty.address3",
    ADDRESS4: "addParty.address4",
    ADDRESS5: "addParty.address5",
    CITY_NAME: "addParty.cityName",
    CONTACT_NAME: "addParty.contactName",
    EMAIL: "addParty.email"
  },
  Packaging: {
    TITLE: "packaging.title",
    PGA: "packaging._pgaId",
    PACKING_PGA_ID: "packaging.packingPgaId",
    PACKAGING_QUALIFIER: "packaging.packagingQualifier",
    QUANTITY: "packaging.quantity",
    UOM: "packaging.uom"
  },
  ContainerInfo: {
    TITLE: "containerInfo.title",
    PGA: "containerInfo._pgaId",
    CONTAINER_INFO_PGA_ID: "containerInfo.containerInfoPgaId",
    CONTAINER_NUMBER: "containerInfo.containerNumber"
  },
  Affirmation: {
    TITLE: "affirmation.title",
    PGA: "affirmation._pgaId",
    AFFIRMATION_PGA_ID: "affirmation.affirmationPgaId",
    COMPLIANCE_AFFIRMATION_CODE: "affirmation.complianceAffirmationCode",
    COMPLIANCE_AFFIRMATION_DESC: "affirmation.complianceAffirmationDesc"
  },
  ProductCond: {
    TITLE: "productCond.title",
    PGA: "productCond._pgaId",
    PRODUCT_COND_PGA_ID: "productCond.productCondPgaId",
    LOT_NUMBER: "productCond.lotNumber"
  },
  validations: {
    CARRIER_CODE_REGEX: "carrierCodeRegx"
  }
}

export default FdapnKeys
