import  TprApiUrls  from '../../../../apiUrls/TprApiUrls'
import { components, useServices} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

const {
  form: {
    field: {
      CngLookupAutocompleteField
    },
  },
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function TprPartyAutoCompleteMasterField(props) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngLookupAutocompleteField
      {...lookupAutocompleteProps}
      lookupProps={{
        url: TprApiUrls.GET,
        label: 'name',
        value: 'id',
        filters: [
          ...filters
        ],
        ...otherLookupProps
      }}
    />
  )
}

TprPartyAutoCompleteMasterField.propTypes = {
  lookupProps: PropTypes.object
}

export default TprPartyAutoCompleteMasterField
