import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'

const useStyles = makeStyles((theme) => ({
  status: {
    backgroundColor: (props) =>
      props.color ? props.color : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    color: (props) =>
      props.contrastColor ? props.contrastColor : theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    padding: '8px 16px',
    textTransform: 'uppercase'
  }
}))

function StatusBar(props) {
  const { status, ...rest } = props

  const { color, contrastColor, label } = getStatusMetadata(status)
  const classes = useStyles({ color, contrastColor })

  return (
    <Box className={classes.status} {...rest}>
      {label}
    </Box>
  )
}

export default StatusBar
