const EhblHouseBillKeys = {
  TITLE: "title",
  MANIFEST_ID: "manifestId",
  CORPID: "corpid",
  MOVEMENT_TYPE: "movementType",
  INDICATOR_MODE: "indicatorMode",
  PRIMARY_CCN: "primaryCcn",
  CARRIER_CODE: "carrierCode",
  CCN: "ccn",
  PORT_OF_DISCHARGE_CODE: "portOfDischargeCode",
  PORT_OF_DEST_CODE: "portOfDestCode",
  MESSAGE_FUNC_CODE: "messageFuncCode",
  CONSOLIDATION_IND: "consolidationInd",
  TOTAL_WEIGHT: "totalWeight",
  STATUS: "status",
  CLOSE_STATUS: "closeStatus",
  RESPONSE_DATETIME: "responseDatetime",
  SERVICE_BUREAU_ID: "serviceBureauId",
  UPS_CLIENT_ID: "upsClientId",
  USER_ROLE: "userRole",
  EMAIL: "email",
  MOBILE_NO: "mobileNo",
  TOTAL_WEIGHT_UOM: "totalWeightUom",
  SHIPPER_NAME: "shipperName",
  CONSIGNEE_NAME: "consigneeName",
  ENTRY_NO: "entryNo",
  AMENDMENT_CODE: "amendmentCode",
  UCN: "ucn",
  B2B_COMMENT: "b2bComment",
  DEST_SUB_LOC_CODE: "destSubLocCode",
  DISC_SUB_LOC_CODE: "discSubLocCode",
  UNDG_CONTACT_NAME: "undgContactName",
  UNDG_CONTACT_NO: "undgContactNo",
  SPECIAL_INSTRUCTION: "specialInstruction",
  IS_DANGEROUS_GOODS: "isDangerousGoods",
  HAN_INSTRUCTION: "hanInstruction",
  VOLUME: "volume",
  VOLUME_UOM: "volumeUom",
  CONTAINERIZED_IND: "containerizedInd",
  MF_IND: "mfInd",
  MF_DATETIME: "mfDatetime",
  BUSINESS_NAME: "businessName",
  FREIGHT_FWD_CODE: "freightFwdCode",
  UNIQUE_REF_NO: "uniqueRefNo",
  CL_MSG_IND: "clMsgInd",
  PREV_CCN: "prevCcn",
  NOTICE_REASON_CODE: "noticeReasonCode",
  HOUSE_REF_NO: "houseRefNo",
  US_PORT_OF_EXIT: "usPortOfExit",
  US_PORT_OF_EXIT_DESC: "usPortOfExitDesc",
  FRN_PORT_OF_LOADING: "frnPortOfLoading",
  FRN_PORT_OF_LOADING_DESC: "frnPortOfLoadingDesc",
  REMARKS: "remarks",
  PRINT_DATE: "printDate",
  ACCOUNT_OFF: "accountOff",
  STORAGE_DATE: "storageDate",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  TEMPLATE_NAME: "templateName",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  PRINT_A8A: "printA8A",
  PRINT_A8A_FOOTER_TEXT: "printA8AFooterText",
  PRINT_A8A_HIDE_TEXT: "printA8AHideText",
  PRINT_A8A_SHOW_TEXT: "printA8AShowText",
  EDIT_HOUSE_BILL: "editHouseBill",
  VIEW_HOUSE_BILL: "viewHouseBill",
  RESPONSE_TITLE: "responseTitle",

  MSG_FUNC_ORIGINAL: "msgFuncOriginal",
  MSG_FUNC_CHANGE: "msgFuncChange",
  MSG_FUNC_CANCEL: "msgFuncCancel",
  MSG_FUNC_PROPOSED_AMENDMENT: "msgFuncProposedAmendment",
  SUBMIT_BUTTON: "submitButton",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG:"submitErrorMessage",
  DETACH_BUTTON: "detachButton",
  DETACH_SENT_RECORD: "detachSentRecord",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORD: "editSentRecord",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_NOT_DRAFT_RECORD: "deleteNotDraftRecord",

  ATTACH_CARGO_SUCESS_MSG: "attachHouseBillSuccessMsg",
  ATTACH: "attach",
  ATTACH_SENT: "attachSentRecord",
  SET_STATUS_NOTIFICATION: "setStatusNotification",
  SAVE_BUTTON:"saveButton",
  UPDATE_BUTTON:"updateButton",
  MANIFEST_FORWARD_TITLE:"manifestForwardTitle",
  CREATE_CLOSE_MESSAGE:"createCloseMessage",
  CREATE_CLOSE_MESSAGE_SUCCESS_MESSAGE:"createCloseMessageSuccessMessage",
  CREATE_CLOSE_MESSAGE_ERROR_MESSAGE:"createCloseMessageErrorMessage",
  PROPAGATE:"propagate",
  PROPAGATE_SUCCESS_MESSAGE:"propagateSuccessMessage",
  PROPAGATE_ERROR_MESSAGE:"propagateErrorMessage",
  PREV_CCN_EMPTY:"prevCcnEmpty",
  PROPAGATE_SENT_RECORD:"propagateSentRecord",
  BACK_BUTTON:"backButton",
  CREATE_CLOSE_MESSAGE_SENT_RECORD:"createCloseMessageSentRecord",
  DETACH_SUCCESS_MESSAGE:"detachSuccessMsg",
  UPLOAD_CSV_DILAOG_TITLE:"uploadCsvDialogTitle",
  UPLOAD_CSV_BUTTON:"uploadCsvButton",
  UPLOAD_CSV_SUCCESS_MSG:"uploadCsvSuccessMsg",
  UPLOAD_CSV_ERROR_MSG:"uploadCsvErrorMsg",
  DISCARD_BUTTON:"discardButton",
  UPLOAD_CSV_FILE_EMPTY_ERROR_MSG:"uploadCsvFileEmptyErrorMsg",
  SAVE_SUCCESS_MSG:"saveSuccessMsg",
  UPDATE_SUCCESS_MSG:"updateSuccessMsg",
  DELETE_SUCCESS_MSG:"deleteSuccessMsg",
  SELECT_RECORD_SUBMIT:"selectRecordSubmit",
  HOUSE_BILL_TEMPLATE: "houseBillTemplate",
  PRINT_HBL: "printHBL",
  CREATED_BY_LOGIN_ID:"createdByLoginId",
  ContainerDetails: {
    CARGO_HEADER:"containerDetails.cargoHeader",
    TITLE: "containerDetails.title",
    HBL_ID: "containerDetails.hblId",
    MANIFEST_ID: "containerDetails.manifestId",
    MF_ID: "containerDetails.mfId",
    EQUIPMENT_ID: "containerDetails.equipmentId",
    EQUIPMENT_TYPE: "containerDetails.equipmentType",
    SEAL_NO: "containerDetails.sealNo",
    SEAL_NO_1: "containerDetails.sealNo1",
    SEAL_NO_2: "containerDetails.sealNo2",
    SEAL_NO_3: "containerDetails.sealNo3",
    SEAL_NO_4: "containerDetails.sealNo4",
    SEAL_NO_5: "containerDetails.sealNo5",
    SEAL_NO_6: "containerDetails.sealNo6",
    SEAL_NO_7: "containerDetails.sealNo7",
    SEAL_NO_8: "containerDetails.sealNo8",
    SEAL_NO_9: "containerDetails.sealNo9",
    SEAL_NO_10: "containerDetails.sealNo10",
    SEAL_NO_11: "containerDetails.sealNo11",
    SEAL_NO_12: "containerDetails.sealNo12",
    SEAL_NO_13: "containerDetails.sealNo13",
    SEAL_NO_14: "containerDetails.sealNo14",
    SEAL_NO_15: "containerDetails.sealNo15",
    SEAL_NO_16: "containerDetails.sealNo16",
    SEAL_NO_17: "containerDetails.sealNo17",
    SEAL_NO_18: "containerDetails.sealNo18",
    SEAL_NO_19: "containerDetails.sealNo19",
    SEAL_NO_20: "containerDetails.sealNo20",
    SEAL_NO_21: "containerDetails.sealNo21",
    SEAL_NO_22: "containerDetails.sealNo22",
    SEAL_NO_23: "containerDetails.sealNo23",
    SEAL_NO_24: "containerDetails.sealNo24",
    SEAL_NO_25: "containerDetails.sealNo25",
    SEAL_NO_26: "containerDetails.sealNo26",
    SEAL_NO_27: "containerDetails.sealNo27",
    SEAL_NO_28: "containerDetails.sealNo28",
    SEAL_NO_29: "containerDetails.sealNo29",
    SEAL_NO_30: "containerDetails.sealNo30",
    SEAL_NO_31: "containerDetails.sealNo31",
    SEAL_NO_32: "containerDetails.sealNo32",
    SEAL_NO_33: "containerDetails.sealNo33",
    SEAL_NO_34: "containerDetails.sealNo34",
    SEAL_NO_35: "containerDetails.sealNo35",
    SEAL_NO_36: "containerDetails.sealNo36",
    SEAL_NO_37: "containerDetails.sealNo37",
    SEAL_NO_38: "containerDetails.sealNo38",
    SEAL_NO_39: "containerDetails.sealNo39",
    SEAL_NO_40: "containerDetails.sealNo40"
  },
  TradeParty: {
    TITLE: "tradeParty.title",
    HBL_ID: "tradeParty.hblId",
    MANIFEST_ID: "tradeParty.manifestId",
    MF_ID: "tradeParty.mfId",
    CORPID: "tradeParty.corpid",
    PARTY_ID: "tradeParty.partyId",
    PARTY_NAME: "tradeParty.partyName",
    PARTY_TYPE: "tradeParty.partyType",
    DIVISION: "tradeParty.division",
    LOC_PORT_CODE: "tradeParty.locPortCode",
    PO_BOX_NO: "tradeParty.poBoxNo",
    ADDRESS: "tradeParty.address",
    CITY: "tradeParty.city",
    STATE_CODE: "tradeParty.stateCode",
    COUNTRY_CODE: "tradeParty.countryCode",
    POSTAL_CODE: "tradeParty.postalCode",
    CONTACT_NAME: "tradeParty.contactName",
    TELEPHONE_NUM: "tradeParty.telephoneNum",
    SNP_TYPE: "tradeParty.snpType",
    SNP_UCN: "tradeParty.snpUcn",
    SNP_B2B_COMMENT: "tradeParty.snpB2bComment",
    SNP_IDENTIFIER_VALUE: "tradeParty.snpIdentifierValue",
    SNP_ACCOUNT_SECURITY_NUMBER: "tradeParty.snpAccountSecurityNumber",
    SNP_FREIGHT_FORWARDER_CODE: "tradeParty.snpFreightForwarderCode",
    SNP_CBSA_CARRIER_CODE: "tradeParty.snpCbsaCarrierCode",
    SNP_WAREHOUSE: "tradeParty.snpWarehouse",
    SNP_BUSINESS_NUMBER: "tradeParty.snpBusinessNumber",
    SAVE_FLAG:"tradeParty.saveFlag"
  },
  ProductDetails: {
    CARGO_DETAILS:"productDetails.cargoDetails",
    TITLE: "productDetails.title",
    HBL_ID: "productDetails.hblId",
    MF_ID: "productDetails.mfId",
    QUANTITY: "productDetails.quantity",
    UOM: "productDetails.uom",
    DESCRIPTION: "productDetails.description",
    MARKS_AND_NUMBERS: "productDetails.marksAndNumbers",
    HS_CODE: "productDetails.hsCode",
    UNDG_CODE: "productDetails.undgCode",
    WEIGHT: "productDetails.weight",
    WEIGHT_UOM: "productDetails.weightUom",
    UNDG_CODE_TITLE:"productDetails.undgCodeTitle",
    SAVE_FLAG:"productDetails.saveFlag",
    PRODUCT_ID:"productDetails.productId"
  },
  StatusNotifyParties: {
    TITLE: "statusNotifyParties.title",
    MANIFEST_ID: "statusNotifyParties.manifestId",
    HBL_ID: "statusNotifyParties.hblId",
    NAME: "statusNotifyParties.name",
    SN_TYPE: "statusNotifyParties.snType",
    HBL_CCN: "statusNotifyParties.hblCcn",
    EMAIL: "statusNotifyParties.email",
    HP_NUM: "statusNotifyParties.hpNum",
    MODULE: "statusNotifyParties.module"
  },
  ValidationMessage:{
    ERROR_MSG_ALPHA_NUMERIC: "validationMessage.errMsgAlphaNumeric",
    ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR: "validationMessage.errMsgAlphaNumericWithSpecialChar",
    ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE: "validationMessage.errMsgAlphaNumericWithSpecialCharNoSpace",
    ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN: "validationMessage.errMsgAlphaNumericWithHypen",
    ERROR_MSG_NUMERIC: "validationMessage.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validationMessage.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validationMessage.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validationMessage.errMsgMinLength",
    ERROR_MSG_ALPHA:"validationMessage.errMsgAlpha",
    ERROR_MSG_DECIMAL1:"validationMessage.errMsgDecimal1",
    ERROR_MSG_DECIMAL2:"validationMessage.errMsgDecimal2",
    ERROR_MSG_DECIMAL3:"validationMessage.errMsgDecimal3",
  },
  ACTIVITY_LOGS_INFO: {
    TITLE: "activityLogsInfo.title",
    DATE_TIME: "activityLogsInfo.dateTime",
    USERNAME: "activityLogsInfo.username",
    FUNCTION_CODE: "activityLogsInfo.functionCode",
    AUDIT_DESC: "activityLogsInfo.auditDesc",
  }
}

export default EhblHouseBillKeys
