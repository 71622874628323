import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  makeStyles
} from '@material-ui/core'

const {
  form: { field: { CngSwitchField } }
} = components

const useStyles = makeStyles((theme) => ({
  section: {
    borderRadius: theme.shape.borderRadius,
    '& .MuiCardHeader-title': {
      fontWeight: 700
    }
  },
  header: {
    padding: 16,
    '& .MuiCardHeader-action': {
      marginTop: 0,
      marginRight: 0,
      '& .MuiSwitch-root': {
        margin: 0
      }
    }
  }
}))

function CollapsibleSection(props) {
  const {
    title,
    subheader,
    defaultExpanded=false,
    action,
    children,
    onExpandedChange = () => {},
    ...rest
  } = props
  const [expanded, setExpanded] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded]);

  return (
    <Card className={classes.section} variant='outlined' {...rest}>
      <CardHeader
        className={classes.header}
        title={title}
        subheader={subheader}
        action={
          <CngSwitchField
            checked={expanded}
            onChange={(_, value) => {
              setExpanded(value)
              onExpandedChange(value)
            }}
          />
        }
      />
      <Collapse in={expanded}>
        <Box padding="0px 16px 16px">{children}</Box>
      </Collapse>
    </Card>
  )
}

export default CollapsibleSection
