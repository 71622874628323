import React, { useState } from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  loader: {
    alignItems: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2
  }
}))

function withFormState(Component) {
  function WithFormStateComponent(props) {
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    return (
      <Box position='relative'>
        <Component loading={loading} onSetLoading={setLoading} {...props} />
        {loading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    )
  }

  return WithFormStateComponent
}

export default withFormState
