import React from 'react'
import moment from 'moment-timezone'
import AceHighwayShipmentApiUrls from "../../apiUrls/AceHighwayShipmentApiUrls"
import { constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import pathMap from 'src/paths/pathMap'

function TemplateSelectDialogWithOptionACEHWYManifest(props) {
  const {
    manifestDialog,
    setManifestDialog,
    showNotification,
    translatedTextsObject,
    setValue,
    reset,
    trigger
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()

  function handleApplyShipment(template) {
    if (template) {
      const {
        headerId,
        tripNo,
        arrivalDate,
        firstExpectedCode,
        bondNumber,
        inbondArrived
      } = template

      var convArrivalDate = moment(arrivalDate).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm');

      if (inbondArrived) {
        setValue('messageCode', '833' || '', { shouldDirty: true })
      } else {
        setValue('messageCode', '98' || '', { shouldDirty: true })
      }
      setValue('manifestID', headerId || '', { shouldDirty: true })
      setValue('tripNo', tripNo || '', { shouldDirty: true })
      setValue('etaFirstPortUS', convArrivalDate || '', { shouldDirty: true })
      setValue('firstPort', firstExpectedCode || '', { shouldDirty: true })
      setValue('inbond7512No', bondNumber || '', { shouldDirty: true })
      setValue('msgFunction', '64' || '', { shouldDirty: true })

      showNotification('success', 'Inbond Shipment applied successfully.')
      trigger()

    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'tripNo',
      sortKey: 'tripNo',
      title: translatedTextsObject.tripNo,
      render: (data) => {
        var manifestUrl = `${pathMap.ACE_HIGHWAY_MANIFEST}/edit/${data.headerId}`

        return (<div style={{ 'text-decoration': 'underline', cursor: 'pointer' }} onClick={(event) => {
          event.stopPropagation()
          history.push(manifestUrl)
        }}>{data.tripNo}</div>)
      }
    },
    {
      field: 'arrivalDate',
      sortKey: 'arrivalDate',
      title: translatedTextsObject.etaFirstPortUS,
      render: (data) =>
        data.arrivalDate &&
        moment(data.arrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'firstExpectedCode',
      sortKey: 'firstExpectedCode',
      title: translatedTextsObject.firstPort
    },
    {
      field: 'bondNumber',
      sortKey: 'bondNumber',
      title: translatedTextsObject.inbond7512No
    },
    {
      field: 'inBondDestination',
      sortKey: 'inBondDestination',
      title: translatedTextsObject.inbondDestination
    },
    {
      field: 'inbondArrived',
      sortKey: 'inbondArrived',
      title: translatedTextsObject.arrivalStatus,
      render: (data) => {
        return (<div style={{ 'text-decoration': 'underline', cursor: 'pointer' }} onClick={(event) => {
          event.stopPropagation()
          history.push(`${pathMap.ACE_HIGHWAY_MANIFEST}/edit/${data.headerId}`, { step: 4 })
        }}>{data.inbondArrived ? "Arrival of in-bond Inbond number" : "Not Received"}</div>)
      }
    },
    {
      field: 'inbondExported',
      sortKey: 'inbondExported',
      title: translatedTextsObject.exportStatus,
      render: (data) => {
        return (<div style={{ 'text-decoration': 'underline', cursor: 'pointer' }} onClick={(event) => {
          event.stopPropagation()
          history.push(`${pathMap.ACE_HIGHWAY_MANIFEST}/edit/${data.headerId}`, { step: 4 })
        }}>{data.inbondExported ? "Export of in-bond Inbond number" : "Not Received"}</div>)
      }
    }
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.tripNo,
      value: 'tripNo'
    },
    {
      label: translatedTextsObject.arrivalDate,
      value: 'arrivalDate'
    },
    {
      label: translatedTextsObject.firstExpectedPort,
      value: 'firstExpectedCode'
    },
    {
      label: translatedTextsObject.inbond7512No,
      value: 'bondNumber'
    },
    {
      label: translatedTextsObject.inbondDestination,
      value: 'inBondDestination'
    },
    {
      label: translatedTextsObject.arrivalStatus,
      value: 'inbondArrived'
    },
    {
      label: translatedTextsObject.exportStatus,
      value: 'inbondExported'
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'DESC' }]}
      fetch={{ url: AceHighwayShipmentApiUrls.CUSTOM_SEARCH_INBOND }}
      fetchFilters={
        [
          { field: 'isInBondShipment', operator: EQUAL, value: true },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyShipment}
      onClose={() => setManifestDialog(false)}
      open={manifestDialog}
      search={{ field: 'tripNo', operator: LIKE }}
      sortOptions={sortOptions}
      title='Manifest'
      isTable={true}
    />
  )
}

export default TemplateSelectDialogWithOptionACEHWYManifest