import React from 'react'
import { Box, Typography } from '@material-ui/core'

function CngField(props) {
  const { label, children } = props

  return (
    <Box display='flex' flexDirection='column' style={{ gap: 2 }}>
      {label && (
        <Typography
          variant='caption'
          color='textSecondary'
          style={{ fontWeight: 600 }}
        >
          {label}
        </Typography>
      )}
      <Typography component='div'>{children || '-'}</Typography>
    </Box>
  )
}

export default CngField
