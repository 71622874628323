import React from 'react'
import {
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
  itemsPerPage: {
    backgroundColor: theme.palette.background.sectionOddBg,
    borderRadius: theme.shape.borderRadius,
    '& .MuiSelect-root': {
      fontSize: 14,
      padding: '8px 24px 8px 8px'
    }
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: theme.shape.borderRadius,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  }
}))

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100, 500, 1000]

function TablePagination(props) {
  const { count, itemsPerPage, onItemsPerPageChange, onPageChange, page } = props

  const classes = useStyles()

  return (
    <Grid container justify='space-between' spacing={2}>
      <Grid item xs={12} sm='auto'>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs='auto'>
            <Typography variant='caption'>Items per page:</Typography>
          </Grid>
          <Grid item xs='auto'>
            <FormControl className={classes.itemsPerPage} hiddenLabel size='small' variant='filled'>
              <TextField
                InputProps={{ disableUnderline: true }}
                onChange={onItemsPerPageChange}
                select
                SelectProps={{ native: true }}
                value={itemsPerPage}
              >
                {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm>
        <Grid container justify='flex-end'>
          <Grid item xs='auto'>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(count / itemsPerPage)}
              page={page + 1}
              onChange={(e, page) => onPageChange(page - 1)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TablePagination
