import check from 'check-types'
import get from 'lodash/get'

function transformToLookupColumn(
  response,
  resultAccessor,
  labelAccessor,
  valueAccessor
) {
  const data = resultAccessor ? get(response, resultAccessor) : response
  return data.reduce((result, record) => {
    const label = check.string(labelAccessor)
      ? get(record, labelAccessor)
      : labelAccessor(record)
    const value = get(record, valueAccessor)
    result[value] = label
    return result
  }, {})
}

function showErrorNotification(notification, message) {
  if (notification && notification.error) {
    notification.error(message)
  }
}

function showSuccessNotification(notification, message) {
  if (notification && notification.success) {
    notification.success(message)
  }
}

export {
  showErrorNotification,
  showSuccessNotification,
  transformToLookupColumn
}
