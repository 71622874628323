//import  NaCodeMaintenanceApiURLs  from '../../../../apiUrls/NaCodeMaintenanceApiUrls'
import ConveyanceMasterApiUrls from "../../../../apiUrls/ConveyanceMasterApiUrls"
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField,
      CngServerSideAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaConveyanceMasterAutocompleteField(props, codeMaintenanceType) {
  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={false}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['conveyanceID']}
      lookupPageableProps={{
        customData: { "filters":filters},
        url: ConveyanceMasterApiUrls.GET,
        //customData: { codeMType: codeMaintenanceType },
        label: 'conveyanceID',
        value: 'conveyanceID',
        filters: [
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

NaConveyanceMasterAutocompleteField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaConveyanceMasterAutocompleteField
