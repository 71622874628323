const AcioceanKeys = {
  TITLE: "title",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  SNO: "sno",
  HEADER: "header",
  VESSEL: "vessel",
  CERTIFICATES: "certificates",
  DOCTYPE: "doctype",
  SCHEDULE: "schedule",
  CARRIER_CODE: "carrierCode",
  VESSEL_NAME: "vesselName",
  VESSEL_CODE: "vesselCode",
  VOYAGE_NO: "voyageNo",
  NATIONALITY: "nationality",
  MASTER_OPERATOR: "masterOperator",
  CONV_REFNO: "convRefno",
  ERROR_CODE: "errorCode",
  REJECT_TYPE: "rejectType",
  ERROR_DESC: "errorDesc",
  RESPONSE_TEXT: "responseText",
  CREW: "crew",
  PASSENGERS: "passengers",
  CONVEYANCE_TYPE: "conveyanceType",
  CONTAINER_SHIPMENT: "containerShipment",
  CUSTOMS_PROC: "customsProc",
  CHARTER_INFO: "charterInfo",
  SPECIALOPS: "specialops",
  CARGO_DESCRIPTION: "cargoDescription",
  REG_NO: "regNo",
  REG_DATE: "regDate",
  PLACE_OF_REGISTRY: "placeOfRegistry",
  NETT_WT: "nettWt",
  GROSS_WT: "grossWt",
  CNTRCARGO_WT: "cntrcargoWt",
  CARGO_WT: "cargoWt",
  DEAD_WT: "deadWt",
  LENGTH: "length",
  TEMPLATE_FLAG:"templateFlag",
  TEMPLATE_NAME:"templateName",
  D4_TITLE: "d4Notices",
  SYSTEM_PROCESSED_DATE_TIME: "sysProcDateTime",
  CBSA_REF_NO: "cbsaRefNo",
  CBSA_PROCESSED_DATE_TIME: "cbsaProcDateTime",
  REMARKS: "remarks",
  DOCUMENT_TYPE: "documentType",
  SAFETYCERT_NO: "safetycertNo",
  SAFETYCERT_EXPIRY_DATE: "safetycertExpiryDate",
  RADIOCERT_NO: "radiocertNo",
  RADIOCERT_EXPIRY_DATE: "radiocertExpiryDate",
  EQUIPCERT_NO: "equipcertNo",
  EQUIPCERT_EXPIRY_DATE: "equipcertExpiryDate",
  LOADLINECERT_NO: "loadlinecertNo",
  LOADLINECERT_EXPIRY_DATE: "loadlinecertExpiryDate",
  DERATCERT_NO: "deratcertNo",
  DERATCERT_EXPIRY_DATE: "deratcertExpiryDate",
  HEALTHCERT_NO: "healthcertNo",
  HEALTHCERT_EXPIRY_DATE: "healthcertExpiryDate",
  CIVILCERT_NO: "civilcertNo",
  CIVILCERT_EXPIRY_DATE: "civilcertExpiryDate",
  DEPARTURE_PORT: "departurePort",
  DEPARTURE_TERMINAL: "departureTerminal",
  DEPARTURE_PIER: "departurePier",
  ETD: "etd",
  ARRIVAL_PORT: "arrivalPort",
  ARRIVAL_TERMINAL: "arrivalTerminal",
  ARRIVAL_PIER: "arrivalPier",
  ETA: "eta",
  CUSTOMS_OFFICE_OF_DECLARATION: "customsOfficeOfExit",
  PORT_OF_DISCHARGE: "portOfDischarge",
  MESSAGE_FUNCTION: "messageFunction",
  A6A_STATUS: "a6aStatus",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  SUBMIT_BUTTON: "submitButton", 
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  CANCEL_BUTTON: "cancelButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_SENT_RECORDS : "deleteSentRecords",
  RESPONSE:"response",
  CREATIONDATETIME: "creationDateTime",
  NETT_WT_UOM : "nettWtUom",
  GROSS_WT_UOM : "grossWtUom",
  CNTRCARGO_WT_UOM : "cntrcargoWtUom",
  CARGO_WT_UOM : "cargoWtUom",
  DEAD_WT_UOM : "deadWtUom",
  LENGTH_UOM: "lengthUom",
  WEIGHT_UOM: "weightUOM",
  TON_UOM: "tonUOM",
  PARTY_ID: "partyId",
  ACTIONS:"actions",
  PRINT_A6:"Print A6",
  EXPORT_CSV:"exportCSV",
  PRINT_MULTIPLE_RECORDS:"Multiple records cannot be printed",
  MESSAGE_FUNCTION: "messageFuntion",
  CREATED_BY_LOGIN_ID: "createdByLoginId",
  FIELD_DESC: "fieldDesc",
  DELETE_CONTAINER: "deleteContainer",
  YES_BUTTON: "yesButton",
  BACK_BUTTON: "backButton",
  DELETE_CONTAINER_CONFIRM_MSG: "deleteContainerConfirmMsg",
  TaciOcnA6Party: {
    TITLE: "taciOcnA6Party.title",
    PARTY_TYPE: "taciOcnA6Party.partyType",
    PARTY_NAME1: "taciOcnA6Party.partyName1",
    PARTY_NAME2: "taciOcnA6Party.partyName2",
    ADDRESS1: "taciOcnA6Party.address1",
    ADDRESS2: "taciOcnA6Party.address2",
    CITY: "taciOcnA6Party.city",
    STATE_CODE: "taciOcnA6Party.stateCode",
    POSTAL_CODE: "taciOcnA6Party.postalCode",
    COUNTRY_CODE: "taciOcnA6Party.countryCode",
    CONTACT_PERSON: "taciOcnA6Party.contactPerson",
    TEL_NO: "taciOcnA6Party.telNo",
    PARTY_DROP_DOWN: "taciOcnA6Party.partyDropDown",
    SAVE_FLAG: "taciOcnA6Party.saveFlag",
    PARTY_IDENTIFICATION: "taciOcnA6Party.partyIdentification",
  },
  TaciOcnA6Route: {
    TITLE: "taciOcnA6Route.title",
    CREATED_BY: "taciOcnA6Route.createdBy",
    CREATED_DATE: "taciOcnA6Route.createdDate",
    UPDATED_BY: "taciOcnA6Route.updatedBy",
    UPDATED_DATE: "taciOcnA6Route.updatedDate",
    PORT_OF_CALL: "taciOcnA6Route.portOfCall",
    TERMINAL: "taciOcnA6Route.terminal",
    PIER_NO: "taciOcnA6Route.pierNo",
    A6_ROUTE_ID:"taciOcnA6Route.id"
  },
  TaciOcnA6Container: {
    TITLE: "taciOcnA6Container.title",
    CONTAINER_SIZE: "taciOcnA6Container.containerSize",
    CONTAINER_STATUS: "taciOcnA6Container.containerStatus",
    NO_OF_CONTAINERS: "taciOcnA6Container.noOfContainers",
  },
  TaciOcnA6SN: {
    TITLE: "taciOcnA6SN.title",
    NAME: "taciOcnA6SN.name",
    EMAIL: "taciOcnA6SN.email",
    PHNO: "taciOcnA6SN.phno",
    SN_TYP: "taciOcnA6SN.snTyp",
    SN_ID:"taciOcnA6SN.id"
  }
}

export default AcioceanKeys
