import React from 'react'
import { components, constants } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { Box, Grid } from '@material-ui/core'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

const DEFAULT_PROPS = {
  submitButtonLabel: 'Save',
  updateButtonLabel: 'Update'
}

function DialogForm(props) {
  const {
    disableBackdropClick = false,
    disabled,
    formProperties,
    isView,
    onClose,
    onSubmit,
    open,
    showNotification,
    submitButtonLabel = DEFAULT_PROPS.submitButtonLabel,
    title,
    value,
    updateButtonLabel = DEFAULT_PROPS.updateButtonLabel,
    ...otherProps
  } = props

  const { initialValues } = formProperties.formikProps

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data)
        resolve()
      }, 500)
    })
  }

  return (
    <CngDialog
      customDialogContent={
        <Box padding={2}>
          <CngForm
            fieldLevel='form'
            formikProps={{
              ...formProperties.formikProps,
              initialValues: value || initialValues,
              onSubmit: handleSubmit
            }}
            formState={FormState.COMPLETED}
            innerForm
            renderBodySection={(labelMap, shouldHideMap) => (
              <formProperties.Fields
                disabled={disabled || isView}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            )}
            renderButtonSection={() => (
              <ButtonSection
                initialValues={initialValues}
                isView={isView}
                label={value ? updateButtonLabel : submitButtonLabel}
                onClose={onClose}
              />
            )}
          />
        </Box>
      }
      dialogTitle={title}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return

        onClose()
      }}
      open={open}
      shouldShowCloseButton
      {...otherProps}
    />
  )
}

function ButtonSection(props) {
  const { initialValues, isView, label, onClose } = props

  const {
    reset,
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <Grid container justify='space-between' spacing={2}>
      {isView ? (
        <Grid item xs='auto'>
          <CngButton onClick={onClose}>
            Close
          </CngButton>
        </Grid>
      ) : (
        <>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              disabled={isSubmitting}
              onClick={() => reset(initialValues)}
            >
              Clear all
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton disabled={isSubmitting} onClick={onClose}>
                  Discard
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton disabled={isSubmitting} type='submit'>
                  {label}
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default DialogForm
