const AceOcnVesselDepartureKeys = {
  TITLE: "title",
  MANIFEST_HEADER_ID: "manifestHeaderId",
  CARRIER_SCAC: "carrierScac",
  VESSEL_NAME: "vesselName",
  VOYAGE_NUMBER: "voyageNumber",
  VESSEL_COUNTRY: "vesselCountry",
  PORT_OF_DISCHARGE: "portOfDischarge",
  DEPARTURE_DATE: "departureDate",
  DEPARTURE_TIME: "departureTime",
  LAST_FOREIGN_PORT: "lastForeignPort",
  NO_OF_BL: "noOfBl",
  DEPARTURE_STATUS: "departureStatus",
  RESPONSE_DATE: "responseDate",
  VESSEL_CODE: "vesselCode",
  ENTITY_NO: "entityNo",
  MODE_OF_TRANSPORT: "modeOfTransport",
  ETA: "eta",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  CREATE_VESSEL_DEPARTURE: "createVesselDeparture",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORD: "editSentRecord",
  DELETE_BUTTON: "deleteButton",
  DELETE_NOT_DRAFT_RECORD: "deleteNotDraftRecord",
  CREATE_VESSEL_DEPARTURE_TAB: "createVesselDepartureTab",
  VIEW_VESSEL_DEPARTURE_TAB: "viewVesselDepartureTab",
  VIEW_VESSEL_DEPARTURE_RESPONSE_TAB: "viewVesselDepartureResponseTab",
  Validations:{
    DEPARTURE_DATE_INVALID: "validations.departureDateInvalid",
    DEPARTURE_TIME_INVALID: "validations.departureTimeInvalid",
    ERROR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH : "validations.errMsgMaxLength"
  }
}

export default AceOcnVesselDepartureKeys
