import FileForUserContext from 'src/contexts/FileForUserContext'
import { useContext } from 'react'

function useFileForUser(){

    let {fileForUserValue} = useContext(FileForUserContext)

    return fileForUserValue;
}

export default useFileForUser