import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import naPortAutocompleteField from './NaPortAutocompleteField'

function NaUSForeignPortKAutocompleteField(props) {
  return naPortAutocompleteField(
    props,
    CodeMaintenanceType.DOC_PORT,
    "IFX_US_FRN_K"
  )
}

export default NaUSForeignPortKAutocompleteField