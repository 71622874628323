const AesShipmentKeys = {
  TITLE: "title",
  ID: "id",
  CORPID: "corpid",
  RELATED_COMPANY_IND: "relatedCompanyInd",
  US_TO_PUERTO_RICO_IND: "usToPuertoRicoInd",
  PUERTO_RICO_TO_US_IND: "puertoRicoToUsInd",
  FOREIGN_PRINCIPAL_PARTY_IND: "foreignPrincipalPartyInd",
  HAZMAT_IND: "hazmatInd",
  TO_BE_SOLD_EN_ROUTE_IND: "toBeSoldEnRouteInd",
  FILING_TYPE_IND: "filingTypeInd",
  AEI_FILING_TYPE: "aeiFilingType",
  SHIPMENT_REFERENCE_NUMBER: "shipmentReferenceNumber",
  ESTIMATED_EXPORT_DATE: "estimatedExportDate",
  PORT_OF_EXPORTATION: "portOfExportation",
  PORT_OF_UNLADING: "portOfUnlading",
  AES_INTERNAL_TRANSACTION_NUMBER: "aesInternalTransactionNumber",
  FOREIGN_TRADE_ZONE: "foreignTradeZone",
  ENTRY_NUMBER: "entryNumber",
  US_STATE_OF_ORIGIN: "usStateOfOrigin",
  ULTIMATE_DESTINATION_COUNTRY: "ultimateDestinationCountry",
  MODE_OF_TRANSPORT: "modeOfTransport",
  TOTAL_SHIPPING_WEIGHT: "totalShippingWeight",
  CARRIER_CODE: "carrierCode",
  CARRIER_VESSEL_NAME: "carrierVesselName",
  RESPONSE_DATETIME: "responseDatetime",
  ACTION_CODE: "actionCode",
  STATUS: "status",
  IN_BOND_TYPE: "inBondType",
  SHIPPER_NAME: "shipperName",
  CONSIGNEE_NAME: "consigneeName",
  FTP_USER_ID: "ftpUserId",
  ORIGINAL_ITN: "originalItn",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  ORI_CREATED_BY: "oriCreatedBy",
  ORI_UPDATED_BY: "oriUpdatedBy",
  ORI_SUBMITTED_BY: "oriSubmittedBy",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  HEADER_TITLE: "headerTitle",
  YES: "yes",
  NO: "no",
  SELECT: "select",
  MANDATORY_FIELD: "mandatoryField",
  OK_BUTTON: "okButton",
  SAVE_BUTTON: "saveButton",
  UPDATE_BUTTON: "updateButton",
  EDIT_SHIPMENT: "editShipment",
  LICENSE_DETAILS: "licenseDetails",
  DDTC_DETAILS: "ddtcDetails",
  ADD_NEW_BUTTON: "addNewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  NO_RECORD_TO_DISPLAY: "noRecordToDisplay",
  ACTION: "action",
  ADD: "add",
  CHANGE: "change",
  DELETE: "delete",
  DISCARD_MESSAGE: "discardMessage",
  DISCARD_BUTTON: "discardButton",
  YES_DISCARD: "yesDiscard",
  CONTINUE_EDITING: "continueEditing",
  SAVE_SUCCESS_MSG: "saveSuccessMsg",
  UPDATE_SUCCESS_MSG: "updateSuccessMsg",
  DELETE_SUCCESS_MSG: "deleteSuccessMsg",
  DELETE_CONFIRMATION_MSG: "deleteConfimationMsg",
  DELETE_CONFIRM_BUTTON: "deleteConfirmButton",
  CANCEL_ACTION_BUTTON: "cancelActionButton",
  CLONE_PREVIOUS_ONE: "clonePreviousOne",
  SUBMIT_BUTTON: "submitButton",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_ERROR_MESSAGE: "submitErrorMessage",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORD: "editSentRecord",
  DELETE_NOT_DRAFT_RECORD: "deleteNotDraftRecord",
  CREATE_BUTTON: "createButton",
  PRINT_BUTTON: "printButton",
  UPLOAD_CSV_BUTTON: "uploadCSVButton",
  FILE_EMPTY_MSG: "fileEmptyMsg",
  UPLOAD_DIALOG_TITLE: "uploadDialogTitle",
  UPLOAD_SUCCESS_MSG: "uploadSuccessMsg",
  UPLOAD_ERROR_MSG: "uploadErrorMsg",
  RESET_BUTTON:"resetButton",
  SEARCH_BUTTON: "searchButton",
  ADD_SUCCESS_MSG: "addSuccessMsg",
  PRODUCT_MASTER: "productMaster",
  COMMODITY_LINE_HEADER :  "commodityLineHeader",
  TransportationDetails: {
    TITLE: "transportationDetails.title",
    RELATED_DATA_TITLE: "transportationDetails.relatedDataTitle",
    HEADER_ID: "transportationDetails.headerId",
    EQUIPMENT_NUMBER: "transportationDetails.equipmentNumber",
    SEAL_NUMBER: "transportationDetails.sealNumber",
    TRANSPORTATION_REFERENCE_NUMBER: "transportationDetails.transportationReferenceNumber"
  },
  PartyDetails: {
    TITLE: "partyDetails.title",
    HEADER_ID: "partyDetails.headerId",
    PARTY_TYPE: "partyDetails.partyType",
    PARTY_IDENTIFIER_TYPE: "partyDetails.partyIdentifierType",
    PARTY_IDENTIFIER: "partyDetails.partyIdentifier",
    NAME: "partyDetails.name",
    CONTACT_FIRST_NAME: "partyDetails.contactFirstName",
    CONTACT_LAST_NAME: "partyDetails.contactLastName",
    ADDRESS_LINE1: "partyDetails.addressLine1",
    ADDRESS_LINE2: "partyDetails.addressLine2",
    CONTACT_PHONE_NUMBER: "partyDetails.contactPhoneNumber",
    CITY_NAME: "partyDetails.cityName",
    STATE_CODE: "partyDetails.stateCode",
    POSTAL_CODE: "partyDetails.postalCode",
    COUNTRY_CODE: "partyDetails.countryCode",
    USPPI_IRS_NUMBER: "partyDetails.usppiIrsNumber",
    USPPI_IRS_NUMBER_TYPE: "partyDetails.usppiIrsNumberType",
    ULTIMATE_CONSIGNEE_TYPE: "partyDetails.ultimateConsigneeType",
    EMAIL: "partyDetails.email",
    PARTY_ID: "partyDetails.partyId",
    SAVE_PARTY_FLAG: "partyDetails.savePartyFlag",
    PARTY_TEMPLATE: "partyDetails.partyTemplate",
    PARTY_DROPDOWN: "partyDetails.partyDropdown",
    ADD_PARTY_BUTTON: "partyDetails.addPartyButton"
  },
  CommodityDetails: {
    TITLE: "commodityDetails.title",
    HEADER_ID: "commodityDetails.headerId",
    PRODUCT_ID: "commodityDetails.productId",
    COMMODITY_DESCRIPTION: "commodityDetails.commodityDescription",
    HTS_NUMBER: "commodityDetails.htsNumber",
    ORIGIN_OF_GOODS: "commodityDetails.originOfGoods",
    QUANTITY1: "commodityDetails.quantity1",
    UOM1: "commodityDetails.uom1",
    QUANTITY2: "commodityDetails.quantity2",
    UOM2: "commodityDetails.uom2",
    VALUE_OF_GOODS: "commodityDetails.valueOfGoods",
    EXPORT_INFORMATION_CODE: "commodityDetails.exportInformationCode",
    SHIPPING_WEIGHT: "commodityDetails.shippingWeight",
    LICENSE_EXEMPTION_CODE: "commodityDetails.licenseExemptionCode",
    ECCN: "commodityDetails.eccn",
    EXPORT_LICENSE_NUMBER: "commodityDetails.exportLicenseNumber",
    LICENSE_VALUE: "commodityDetails.licenseValue",
    ITAR_EXEMPTION_CODE: "commodityDetails.itarExemptionCode",
    REGISTRATION_NUMBER: "commodityDetails.registrationNumber",
    ELIGIBLE_PARTY_CERTIFICATION_IND: "commodityDetails.eligiblePartyCertificationInd",
    USML_CATEGORY_CODE: "commodityDetails.usmlCategoryCode",
    DDTC_QUANTITY: "commodityDetails.ddtcQuantity",
    DDTC_UOM: "commodityDetails.ddtcUom",
    SIGNIFICANT_MILITARY_EQUIPMENT_IND: "commodityDetails.significantMilitaryEquipmentInd",
    ACTION_CODE: "commodityDetails.actionCode",
    STATUS: "commodityDetails.status",
    ADDITIONAL_PGA_DATA_REQUIRED_IND: "commodityDetails.additionalPgaDataRequiredInd",
    DELIVERY_DETAILS: "commodityDetails.deliveryDetails",
    SAVE_PRODUCT_FLAG: "commodityDetails.saveProductFlag",
    PRODUCT_TEMPLATE: "commodityDetails.productTemplate",
    PRODUCT_DROPDOWN: "commodityDetails.productDropdown",
    ADD_COMMODITY_BUTTON: "commodityDetails.addCommodityButton",
  },
  UsedVehicleDetails: {
    TITLE: "usedVehicleDetails.title",
    COMMODITY_DETAILS: "usedVehicleDetails._commodityDetailsId",
    COMMODITY_ID: "usedVehicleDetails.commodityId",
    VEHICLE_ID_TYPE: "usedVehicleDetails.vehicleIdType",
    VEHICLE_ID_NUMBER: "usedVehicleDetails.vehicleIdNumber",
    VEHICLE_TITLE_NUMBER: "usedVehicleDetails.vehicleTitleNumber",
    VEHICLE_TITLE_STATE: "usedVehicleDetails.vehicleTitleState"
  },
  PgaDetails: {
    TITLE: "pgaDetails.title",
    COMMODITY_DETAILS: "pgaDetails._commodityDetailsId",
    COMMODITY_ID: "pgaDetails.commodityId",
    PGA_ID: "pgaDetails.pgaId",
    AMS_CERTIFICATE_NUMBER: "pgaDetails.amsCertificateNumber",
    EPA_LICENSE_REQUIRED: "pgaDetails.epaLicenseRequired",
    EPA_CONSENT_NUMBER: "pgaDetails.epaConsentNumber",
    RCRA_HAZARDOUS_WASTE_TRACKING_NUMBER: "pgaDetails.rcraHazardousWasteTrackingNumber",
    EPA_NET_QUANTITY: "pgaDetails.epaNetQuantity",
    EPA_UOM: "pgaDetails.epaUom"
  },
  StatusNotificationDetails:{
    TITLE: "statusNotificationDetails.title",
    SN_NAME: "statusNotificationDetails.snName",
    SN_EMAIL: "statusNotificationDetails.snEmail",
    SN_PHONE_NUMBER: "statusNotificationDetails.snPhoneNumber",
    SN_TYPE: "statusNotificationDetails.snType",
    ADD_STATUS_NOTIFICATION: "statusNotificationDetails.addStatusNotification",
    SET_SN_DETAILS: "statusNotificationDetails.setSnDetails"
  },
  Responses:{
    VIEW_RESPONSE: "responses.viewResponse",
    RESPONSE_ID: "responses.responsedId",
    PROCESSING_DATE: "responses.processingDate",
    AES_INTERNAL_TRANSACTION_NUMBER: "responses.aesInternalTransactionNumber",
    RESPONSE_CODE: "responses.responseCode",
    RESPONSE_TEXT: "responses.responseText",
    RESPONSE_DATE: "responses.responseDate",
    REASON_CODE: "responses.reasonCode",
    FINAL_DESTINATION_IND: "responses.finalDestinationInd",
    SEVERITY_INDICATOR: "responses.severityIndicator",
    SHIPMENT_RESPONSE: "responses.shipmentResponse",
    ACTION: "responses.action",
    REMARKS: "responses.remarks",
    ACCOUNT: "responses.account",
    SUBMISSION_MODE: "responses.submissionMode",
    ACTIVITY_LOG: "responses.activityLog",
  },
  ValidationMessage:{
    ERROR_MSG_ALPHA_NUMERIC_SPACE: "validationMessage.errMsgAlphaNumericSpace",
    ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR: "validationMessage.errMsgAlphaNumericWithSpecialChar",
    ERROR_MSG_NUMERIC: "validationMessage.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validationMessage.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validationMessage.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validationMessage.errMsgMinLength",
    ERROR_MSG_ALPHA:"validationMessage.errMsgAlpha",
    ERROR_MSG_ALPHA_SPACE:"validationMessage.errMsgAlphaSpace"
  },
}

export default AesShipmentKeys
