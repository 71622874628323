import {
  useNAFetchCodeMaintenanceLookup
} from './tables/hooks'

const nacomponents = {
  table: {
    useNAFetchCodeMaintenanceLookup
  }
} 
export { nacomponents }


export {
  NaDangerousGoodsAutocompleteField,
  NaAirPortAutocompleteField,
  NaAircraftTypeAutocompleteField,
  NaCarrierAutocompleteField,
  NaCodeMaintenanceAutocompleteField,
  NaCodeDescCodeMaintainceAutoCompleteField,
  NaWareHouseCodeDescAutoCompleteField,
  NaWareHouseCodeWithoutCodeDescAutoCompleteField,
  NaCustomsOfficeAutocompleteField,
  NaHSCodeAutocompleteField,
  NaHSCodeCodeMaintenanceAutoCompleteField,
  NaSublocationAutocompleteField,
  NaSublocationWithoutCodeAutocompleteField,
  NaTerminalAutocompleteField,
  NaPartyMasterAutoCompleteField,
  NaProductMasterAutoCompleteField,
  NaDocPortAutocompleteField,
  NaStateCodeAutoCompleteField,
  FirmCodesAutocompleteField,
  NaAceStateCodeAutoCompleteField,
  NaAceHwyPOEPortAutocompleteField,
  NaAceHwyPOLPortAutocompleteField,
  NaUsbStateCodeAutoCompleteField,
  TprPartyAutoCompleteField,
  NaUSForeignPortKAutocompleteField,
  NaUSPortAutocompleteField,
  NaPortUnladingAutocompleteField,
  NaUSForeignPortAutocompleteField,
  NaAesProductMasterAutoCompleteField,
  FDAPNArrivalPortAutocompleteField,
  NaAesStateCodeAutoCompleteField
} from './autocomplete'


