const DbAdminStatusConfigKeys = {
  TITLE: "title",
  MODULE: "module",
  TYPE: "type",
  SEARCH_CRITERIA: "searchCriteria",
  TRIP_NUMBER: "tripNumber",
  SHIPMENT_NUMBER: "shipmentNumber",
  INBOND_7512_NO: "inbond7512No",
  MSG_FUNCTION: "msgFunction",
  CCN: "ccn",
  CURRENT_STATUS: "currentStatus",
  NEW_STATUS: "newStatus",
  UPDATE_BUTTON : "updateButton",
  SEARCH_BUTTON : "searchButton",
  CLEAR_BUTTON : "clearButton",
  USER_ID: "userId",
  RESULT : "result",
  SUCCESS_MSG : "successMsg",
  ERROR_MSG : "errorMsg",
  CREW_ID : "crewId",
  EQUIPMENT_ID : "equipmentId",
  CONVEYANCE_ID : "conveyanceId",
  CARRIER_CODE : "carrierCode",
  CONVEYANCE_NUMBER : "conveyanceNumber",
  EQUIPMENT_NUMBER : "equipmentNumber",
  CREW_TYPE : "crewType",
  FIRST_NAME : "firstName",
  LAST_NAME : "lastName",
  REFERENCE_NUMBER: "referenceNumber",
  PREV_CCN: "prevCcn",
  PRIMARY_CCN: "primaryCcn",
  CONVEYANCE_REFERENCE_NUMBER: "conveyanceReferenceNumber",
  SHIPMENT_REFERENCE_NUMBER: "shipmentReferenceNumber",
  Validations:{ 
    ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR : "validations.errMsgAlphaNumericNoSpecialChar",
    ERROR_MSG_MAX_LENGTH : "validations.errMsgMaxLength",
    NUMERIC: "validations.numericErrorMessage"
  }
}

export default DbAdminStatusConfigKeys
