import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import PropTypes from 'prop-types'
import NaPartyMasterAutoCompleteMasterField from './NaPartyMasterAutoCompleteMasterField'

function NaPartyMasterAutoCompleteField(props) {
    return NaPartyMasterAutoCompleteMasterField(
      props,
      CodeMaintenanceType.PARTY_MASTER
    )
  }
  
  export default NaPartyMasterAutoCompleteField