import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-quill/dist/quill.snow.css'
import 'nprogress/nprogress.css'
import './i18n.js'

import React, { Suspense } from 'react'
import { context, serviceWorker, store } from 'cng-web-lib'

import App from 'src/App'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import ThemeLibrary from 'src/theme/ThemeLibrary'
import WebFont from 'webfontloader'
import { enableES5 } from 'immer'
import ZohoLiveChat from 'src/views/userprofile/ZohoLiveChat'

enableES5()

const SettingsProvider = context.SettingsProvider
const configureStore = store.configureStore

//Initialize the custom font
WebFont.load({
  google: {
    families: ['Lato:100,300,400,700,900', 'sans-serif']
  }
})

const configuredStore = configureStore()
const themeLibrary = new ThemeLibrary()
const { register, serviceWorkerContextRef } = serviceWorker

ReactDOM.render(
  <Suspense fallback='Loading'>
    <Provider store={configuredStore}>
      <SettingsProvider themes={themeLibrary.getNames()}>
        <App />
      </SettingsProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)

register({
  onUpdate: (registration) => {
    if (serviceWorkerContextRef.current.showUpdateAvailable) {
      serviceWorkerContextRef.current.showUpdateAvailable(registration.waiting)
    } else {
      serviceWorkerContextRef.current.newServiceWorker = registration.waiting
    }
  }
})

export { nacomponents } from 'src/components/na'

export {
   NaDangerousGoodsAutocompleteField,
   NaAirPortAutocompleteField,
   NaAircraftTypeAutocompleteField,
   NaCarrierAutocompleteField,
   NaCodeMaintenanceAutocompleteField,
   NaCodeDescCodeMaintainceAutoCompleteField,
   NaWareHouseCodeDescAutoCompleteField,
   NaCustomsOfficeAutocompleteField,
   NaHSCodeAutocompleteField,
   NaHSCodeCodeMaintenanceAutoCompleteField,
   NaSublocationAutocompleteField,
   NaTerminalAutocompleteField,
   NaPartyMasterAutoCompleteField,
   NaProductMasterAutoCompleteField,
   NaStateCodeAutoCompleteField  
  } from 'src/components/na'