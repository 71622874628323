import { useCallback } from 'react'
import { prepareDataForValidation, yupToFormErrors } from 'formik'
import _ from 'lodash'

const useCustomYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      if (!validationSchema) {
        return {
          values: data,
          errors: {}
        }
      }
      let clonedData = _.cloneDeep(data)
      try {
        await validationSchema.validate(prepareDataForValidation(clonedData), {
          abortEarly: false
        })

        return {
          values: data,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: yupToFormErrors(errors)
        }
      }
    },
    [validationSchema]
  )

export default useCustomYupValidationResolver
