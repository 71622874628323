import React, { useEffect, useMemo, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import {
  Box,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Popover,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Badge,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import DoubleScroll from '../DoubleScroll'

const DEFAULT_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}

const {
  button: { CngButton, CngIconButton },
  form: {
    field: { CngCheckboxField, CngTextField }
  },
  CngGridItem
} = components

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    gap: theme.spacing(1),
    '& .MuiToggleButton-root': {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      fontSize: 14,
      padding: '8px 16px',
      textTransform: 'none',
      '&.Mui-selected': {
        border: `1px solid ${theme.palette.divider}`
      }
    }
  },
  table: {
    '& .MuiTableCell-head': {
      fontSize: 12,
      fontWeight: 700,
      padding: '8px 16px',
      textTransform: 'uppercase'
    },
    '& .MuiTableCell-body': {
      fontSize: 14,
      padding: '12px 16px'
    }
  },
  checkbox: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 8,
      '& .MuiCheckbox-root': {
        padding: 8
      }
    }
  }
}))

function sanitizeString(string) {
  string = string.replace(/[^a-z0-9]/gi, '')
  return string.trim()
}

function Table(props) {
  const { columns, endpoint, onClickActionButton, title, clickedChartStatus } = props
  const [data, setData] = useState([])
  const [tableFilters, setTableFilters] = useState({})
  const [filtersPopoverAnchorEl, setFiltersPopoverAnchorEl] = useState(null)
  const [tableColsPopoverAnchorEl, setTableColsPopoverAnchorEl] = useState(null)
  const [tableColumns, setTableColumns] = useState(
    columns.map((column) => sanitizeString(column.title))
  )
  const { fetchRecords } = useServices()

  const filters = columns
    .filter((column) => !!column.filterType)
    .map((column) => ({
      field: column.field,
      label: column.title,
      type: column.filterType,
      value:
        column.filterType === 'string'
          ? ''
          : column.filterType === 'checkbox' && [],
      ...(column.filterType === 'checkbox' && {
        filterOptions: column.filterOptions
      })
    }))

  const filteredData = useMemo(
    () => (_.isEmpty(tableFilters) ? data : getFilteredData()),
    [data, tableFilters]
  )

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    let partyId = (fileForUserDetails != null && fileForUserDetails != undefined) ? fileForUserDetails.fileForUserPartyId : null;

    fetchRecords.execute(
      endpoint,
      partyId != null ? { dataToBeSent: { partyId } } : undefined,
      (data) => {
        setData(data)
      },
      (error) => {
        console.error(error)
      }
    )
  }, [])

  useEffect(() => {
    if (clickedChartStatus && clickedChartStatus.module === title) {
      setTableFilters({ status: [clickedChartStatus.status] })
    }
  }, [clickedChartStatus])

  function getFilteredData() {
    let result = [...data]

    for (const key in tableFilters) {
      result = result.filter((datum) => {
        switch (typeof tableFilters[key]) {
          case 'string': {
            return datum[key]
              .toLowerCase()
              .includes(tableFilters[key].toLowerCase())
          }

          case 'object': {
            if (Array.isArray(tableFilters[key])) {
              return tableFilters[key].includes(datum[key])
            }
          }

          default:
            return true
        }
      })
    }

    return result
  }

  return (
    <>
      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant='h5' style={{ fontWeight: 700 }}>
                {title}
              </Typography>
            </Grid>
            <Box padding={1}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item xs='auto'>
                  <Tooltip title="Filter data" placement="top">
                    <Box><CngIconButton
                      type='outlined'
                      icon={['fal', 'filter']}
                      onClick={(event) =>
                        setFiltersPopoverAnchorEl(event.currentTarget)
                      }
                      size='small'
                    /></Box>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip title="Show/hide columns" placement="top">
                    <Badge badgeContent={'+' + (columns.length - tableColumns.length)}
                      invisible={tableColumns.length === columns.length}
                      color='primary'
                    >
                      <CngIconButton
                        type='outlined'
                        icon={['fal', 'columns']}
                        onClick={(event) =>
                          setTableColsPopoverAnchorEl(event.currentTarget)
                        }
                        size='small'
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box marginTop={1}>
            <DoubleScroll overflow='auto' height={330}>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    {columns.map(
                      (column) =>
                        tableColumns.includes(sanitizeString(column.title)) && (
                          <TableCell key={column.field}>
                            {column.title}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((datum, index) => (
                    <TableRow key={index}>
                      {columns.map(
                        (column) =>
                          tableColumns.includes(
                            sanitizeString(column.title)
                          ) && (
                            <TableCell key={column.field}>
                              {column.render
                                ? column.render(datum)
                                : _.get(datum, column.field)}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </MuiTable>
            </DoubleScroll>
            {onClickActionButton && (
              <Box marginTop={2}>
                <Grid container justify='center'>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={onClickActionButton}
                      endIcon={
                        <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                      }
                      size='small'
                    >
                      See all manifests
                    </CngButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
      <TableFiltersPopover
        anchorEl={filtersPopoverAnchorEl}
        open={filtersPopoverAnchorEl ? true : false}
        onClose={() => setFiltersPopoverAnchorEl(null)}
        filters={filters}
        tableFilters={tableFilters}
        onChangeFilter={(filterData) => setTableFilters(filterData)}
        onResetFilter={() => {
          setTableFilters([])
          setFiltersPopoverAnchorEl(null)
        }}
      />
      <TableColumnsConfigPopover
        anchorEl={tableColsPopoverAnchorEl}
        columns={columns}
        onChangeTableCols={(columns) => setTableColumns(columns)}
        open={tableColsPopoverAnchorEl ? true : false}
        onClose={() => setTableColsPopoverAnchorEl(null)}
      />
    </>
  )
}

export default Table

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: 4,
    width: (props) => props.width || theme.breakpoints.values.sm
  }
}))(Paper)

const StyledPopoverHeader = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
    padding: '8px 16px',
    '&::before': {
      display: 'none'
    },
    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 700,
      '&.MuiTypography-root.count': {
        alignItems: 'center',
        backgroundColor: `${theme.palette.primary.main}33`,
        borderRadius: '50%',
        color: theme.palette.primary.main,
        display: 'inline-flex',
        height: 32,
        justifyContent: 'center',
        width: 32
      }
    }
  }
}))(Box)

function TableFiltersPopover(props) {
  const {
    anchorEl,
    filters,
    tableFilters,
    onChangeFilter,
    onClose,
    onResetFilter,
    open
  } = props

  const methods = useForm({
    defaultValues: filters.reduce((acc, curr) => {
      acc[curr.field] = tableFilters[curr.field]
        ? tableFilters[curr.field]
        : curr.value
      return acc
    }, {})
  })

  function onSubmit(data) {
    onChangeFilter(transformFilterData(data))
    onClose()
  }

  function transformFilterData(data) {
    const result = {}

    for (const key in data) {
      if (!_.isEmpty(data[key])) {
        result[key] = data[key]
      }
    }

    return result
  }

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper>
        <StyledPopoverHeader>
          <Typography variant='body2'>Filters</Typography>
        </StyledPopoverHeader>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box marginTop={1} maxHeight='50vh' overflow='hidden auto'>
              {filters.map((filter, index) => (
                <TableFilterField
                  filter={filter}
                  value={methods.getValues(filter.field)}
                  key={index}
                  onFilter={(name, filter) => methods.setValue(name, filter)}
                />
              ))}
            </Box>
          </form>
        </FormProvider>
        <Box px={2} py={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <CngButton color='secondary' fullWidth onClick={onResetFilter}>
                Clear all
              </CngButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngButton
                color='primary'
                fullWidth
                onClick={methods.handleSubmit(onSubmit)}
              >
                Apply
              </CngButton>
            </Grid>
          </Grid>
        </Box>
      </StyledPopoverWrapper>
    </Popover>
  )
}

function TableFilterField(props) {
  const { filter, onFilter, value } = props
  const [expanded, setExpanded] = useState(false)

  function renderFilterField(filter) {
    let content

    switch (filter.type) {
      case 'string': {
        content = (
          <CngTextField
            name={filter.field}
            onChange={(event) => onFilter(filter.field, event.target.value)}
          />
        )
        break
      }

      case 'checkbox': {
        content = (
          <CheckboxField
            name={filter.field}
            onChange={(data) => onFilter(filter.field, data)}
            options={filter.filterOptions}
            value={value}
          />
        )
        break
      }

      default:
        throw new Error('Invalid field type.')
    }

    return content
  }

  return (
    <Box px={2}>
      <Box py={1}>
        <Grid container alignItems='center' justify='space-between' spacing={2}>
          <Grid item xs>
            <Typography variant='overline' style={{ fontWeight: 700 }}>
              {filter.label}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', expanded ? 'chevron-up' : 'chevron-down']}
              onClick={() => setExpanded((prev) => !prev)}
              size='small'
              type='outlined'
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Collapse in={expanded}>
        <Box>{renderFilterField(filter)}</Box>
      </Collapse>
    </Box>
  )
}

function CheckboxField(props) {
  const { name, onChange, options, value } = props

  const [values, setValues] = useState(value || [])

  function handleCheckboxGroupChange(event) {
    event.persist()

    const result = _.xor(values, [event.target.value])
    onChange(result)
    setValues(result)
  }

  return (
    <Grid container style={{ padding: '8px 16px' }}>
      {options.map((option) => (
        <CngGridItem key={option.value} xs={12} sm={option.fullWidth ? 12 : 6}>
          <CngCheckboxField
            checked={values.includes(option.value)}
            disabled={option.disabled}
            label={option.label}
            name={name}
            onChange={handleCheckboxGroupChange}
            value={option.value}
            style={{ padding: 8 }}
          />
        </CngGridItem>
      ))}
    </Grid>
  )
}

function TableColumnsConfigPopover(props) {
  const { anchorEl, columns, onChangeTableCols, onClose, open } = props

  const classes = useStyles()

  const methods = useForm({
    defaultValues: columns.reduce(
      // changed to use field instead to prevent accidental addition of columns
      (acc, curr) => ({ ...acc, [curr.field]: true }),
      {}
    )
  })

  function onSubmit(data) {
    const result = columns.filter(c => data[c.field])
      .map(c => sanitizeString(c.title))

    onChangeTableCols(result)
  }

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width={300}>
        <StyledPopoverHeader>
          <Typography variant='body2'>View columns</Typography>
        </StyledPopoverHeader>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box marginTop={1} maxHeight='50vh' overflow='hidden auto'>
              <Grid container>
                {columns.map((column) => (
                  <Grid
                    key={column.field}
                    className={classes.checkbox}
                    item
                    xs={12}
                  >
                    <CngCheckboxField
                      name={column.field}
                      label={column.title}
                      onChange={(event) => {
                        methods.handleSubmit(onSubmit)()
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </StyledPopoverWrapper>
    </Popover>
  )
}
