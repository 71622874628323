import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: (props) =>
      props.variant === 'outlined'
        ? 'transparent'
        : theme.palette.mode === 'dark'
          ? '#FFFFFFA1'
        : 'rgba(0,0,0,0.075)',
    color: theme.palette.text.primary,
    fontSize: 10,
    fontWeight: 600,
    height: 20,
    marginLeft: 8,
    '&.MuiChip-outlined': {
      border: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8
    }
  }
}))

function TinyChip(props) {
  const { variant } = props
  const classes = useStyles({ variant })

  return (
    <Chip
      component='span'
      className={classes.chip}
      {...props}
    />
  )
}

export default TinyChip
