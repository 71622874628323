import  AceOcnVesselMasterApiUrls  from '../../../../apiUrls/AceOcnVesselMasterApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'


const {
  form: {
    field: {
      CngServerSideAutocompleteField
    },
  },
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaAceOcnVesselMasterAutoCompleteField(props, codeMaintenanceType) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={2}
      debounceInterval={1000}
      openOnFocus={false}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['vesselName']}
      lookupPageableProps={{
        customData: { "filters":filters},
        url: AceOcnVesselMasterApiUrls.GET,
        label: 'vesselName',
        value: 'vesselName',
        filters: [
          {
            field: 'active',
            operator: 'equal',
            value: true
          },
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

NaAceOcnVesselMasterAutoCompleteField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaAceOcnVesselMasterAutoCompleteField
