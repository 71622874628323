import React from 'react'
import { components } from 'cng-web-lib'
import { Box, makeStyles, Typography } from '@material-ui/core'

const {
  button: { CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.sectionOddBg,
    borderRadius: theme.shape.borderRadius,
    cursor: 'default',
    display: 'flex',
    padding: '8px 16px',
    gap: 16,
    '&:hover': {
      '& .MuiTypography-body2.label': {
        transform: 'translateY(0px)'
      },
      '& .MuiTypography-caption.description': {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)'
      }
    }
  },
  description: {
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
      lineHeight: 1,
      '&.MuiTypography-body2': {
        fontWeight: 700,
        transform: 'translateY(6px)',
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shortest
        })
      },
      '&.MuiTypography-caption': {
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(-8px)',
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shortest
        })
      }
    }
  }
}))

function IconDescButton(props) {
  const { description, label, iconButtonProps } = props
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CngIconButton size='small' type='outlined' {...iconButtonProps} />
      <Box className={classes.description}>
        <Typography className='label' variant='body2'>
          {label}
        </Typography>
        {description && (
          <Typography component='div' className='description' variant='caption'>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default IconDescButton
